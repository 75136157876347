(function() {
    'use strict';

    var module = angular.module('app');

    module.service('announcementsService', [
        '$q', '_', 'constants', 'dataStoreService', 'ENVIRONMENTAL', function($q, _, constants, dataStoreService, ENVIRONMENTAL) {
            var apiSection = "/announcements/";

            function get(searchTerm, pageNumber, itemsPerPage, sortOptions) {
                var search = '';
                var pre = '?';
                if (!_.isUndefined(searchTerm) && searchTerm != '') {
                    search = pre + 'searchTerm=' + encodeURIComponent(searchTerm);
                    pre = '&';
                }
                if (!_.isUndefined(pageNumber) && !_.isUndefined(itemsPerPage) && _.isNumber(pageNumber) && _.isNumber(itemsPerPage)) {
                    search += pre + 'pageNumber=' + pageNumber + '&itemsPerPage=' + itemsPerPage;
                    pre = '&';
                }

                if (!_.isUndefined(sortOptions) && !_.isNull(sortOptions)) {
                    var sortDir = null;
                    var sortField = null;
                    if (_.isArray(sortOptions) && sortOptions.length > 0) {
                        sortDir = sortOptions[0].dir;
                        sortField = sortOptions[0].field;
                        search += pre + 'sortDirection=' + sortDir + '&sortField=' + sortField;
                        pre = '&';
                    }
                }

                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + search);
            }

            function add(payload) {
                var deferred = $q.defer();
                if (!_.isUndefined(payload)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection, payload);
                }
                deferred.reject();
                return deferred.promise;
            }

            function update(payload) {
                var deferred = $q.defer();
                if (!_.isUndefined(payload)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'update/', payload);
                }
                deferred.reject();
                return deferred.promise;
            }

            function toggleAnnouncementVisibility(payload) {
                var deferred = $q.defer();
                if (!_.isUndefined(payload)) {
                    if (payload.IsVisible) {
                        return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'hide/', payload);
                    } else if (!payload.IsVisible) {
                        return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'publish/', payload);
                    }
                }
                deferred.reject();
                return deferred.promise;
            }


            return {
                get: get,
                add: add,
                update: update,
                toggleAnnouncementVisibility: toggleAnnouncementVisibility
            }

        }
    ]);
})();
