(function() {
    "use strict";

    var module = angular.module("bf.services.accounts", []);

    module.service("accountsService", [
        "$q", "_", "constants", "dataStoreService", "ENVIRONMENTAL", "$http", function($q, _, constants, dataStoreService, ENVIRONMENTAL, $http) {
            var apiSection = "/accounts/";

            function localGet(searchTerm, pageNumber, itemsPerPage, sortOptions) {
                var search = "";
                var pre = "?";
                if (!_.isUndefined(searchTerm) && searchTerm != "") {
                    search = pre + "searchTerm=" + encodeURIComponent(searchTerm);
                    pre = "&";
                }
                if (!_.isUndefined(pageNumber) && !_.isUndefined(itemsPerPage) && _.isNumber(pageNumber) && _.isNumber(itemsPerPage)) {
                    search += pre + "pageNumber=" + pageNumber + "&itemsPerPage=" + itemsPerPage;
                    pre = "&";
                }

                if (!_.isUndefined(sortOptions) && !_.isNull(sortOptions)) {
                    var sortDir = null;
                    var sortField = null;
                    if (_.isArray(sortOptions) && sortOptions.length > 0) {
                        sortDir = sortOptions[0].dir;
                        sortField = sortOptions[0].field;
                        search += pre + "sortDirection=" + sortDir + "&sortField=" + sortField;
                        pre = "&";
                    }
                }

                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + search);
            }

            function localGetWithClients() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "includeClients");
            }

            function localAdd(newUser, assignedEntities) {
                var deffered = $q.defer();
                if (!_.isUndefined(newUser)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection, { newUser: newUser, assignedEntities: assignedEntities });
                }
                deffered.reject();
                return deferred.promise;
            }

            function localRemove(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return $http.delete(ENVIRONMENTAL.BASEURLS.API + apiSection + id)
                        .then(applyRequestFailureFix);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localGetById(id) {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
            }

            function localChangeStatus(id, inactivate) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id + "/changestatus", { inactivate: inactivate });
                }
                deffered.reject();
                return deffered.promise;
            }

            function localInvite(invite) {
                var deffered = $q.defer();
                if (!_.isUndefined(invite)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "invite", invite);
                }
                deffered.reject();
                return deferred.promise;
            }

            function localAssignClients(assign) {
                var deffered = $q.defer();
                if (!_.isUndefined(assign)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "assign", assign);
                }
                deffered.reject();
                return deferred.promise;
            }

            function localUnAssignClients(unAssign) {
                var deffered = $q.defer();
                if (!_.isUndefined(unAssign)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "unAssign", unAssign);
                }
                deffered.reject();
                return deferred.promise;
            }

            function localGetListOfApplicableSignatories(clientId) {
                var deferred = $q.defer();
                if (!_.isUndefined(clientId)) {
                    return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "Signatories/" + clientId);
                }
                return deferred.promise;
            }

            function localResendInvite(id) {
                var deferred = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "resendInvite/", { Id: id });
                }
                deferred.reject();
                return deferred.promise;
            }

            function localResendInviteToAllUnaccepted() {
                return $http.get(ENVIRONMENTAL.BASEURLS.API +
                        apiSection +
                        "resendInviteToAllUnaccepted/")
                    .then(applyRequestFailureFix);
            }
            
            function applyRequestFailureFix(resp) {
                // Workaround to turn failed calls back into failed promises
                return !resp || !resp.status || resp.status >= 400 ? $q.reject(resp) : resp;
            }

            return {
                get: localGet,
                getWithClients: localGetWithClients,
                getById: localGetById,
                add: localAdd,
                remove: localRemove,
                changeStatus: localChangeStatus,
                invite: localInvite,
                assignClients: localAssignClients,
                unAssignClients: localUnAssignClients,
                getSignatories: localGetListOfApplicableSignatories,
                resendInvite: localResendInvite,
                resendInviteToAllUnaccepted: localResendInviteToAllUnaccepted
            }
        }
    ]);
})();