(function () {
    "use strict";

    angular.module("bf.components.clients", []);

    angular.module("bf.components.clients")
        .directive("clientActions", [
            "_", function(_) {

                return {
                    restrict: "E",
                    scope: {
                        actionsFor: "@",
                        createClientFn: "&",
                        editClientFn: "&",
                        deleteClientFn: "&",
                        viewClientDocumentsFn: "&",
                        inviteClientsFn: "&",
                        inviteExistingClientsFn: "&",
                        viewSubscriptionsFn: "&",
                        isDisabledBtn: "=",
                        isClientChangeDisabled: "=",
                        isAccountInviteDisabled: "="
                    },
                    template: require('Site\\Components\\Views\\clientActions.html'),
                    replace: true,
                    link: function($scope, element, attrs) {


                        if (!$scope.hasOwnProperty("actionsFor") || _.isUndefined($scope.actionsFor)) {
                            $scope.actionsFor = "client";
                        }

                        if (!$scope.hasOwnProperty("createClientFn") || _.isUndefined($scope.createClientFn)) {
                            $scope.createClientFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("editClientFn") || _.isUndefined($scope.editClientFn)) {
                            $scope.editClientFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("deleteClientFn") || _.isUndefined($scope.deleteClientFn)) {
                            $scope.editClientFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("viewClientDocumentsFn") || _.isUndefined($scope.viewClientDocumentsFn)) {
                            $scope.viewClientDocumentsFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("inviteClientsFn") || _.isUndefined($scope.inviteClientsFn)) {
                            $scope.inviteClientsFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("inviteExistingClientsFn") || _.isUndefined($scope.inviteExistingClientsFn)) {
                            $scope.inviteExistingClientsFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("viewSubscriptionsFn") || _.isUndefined($scope.viewSubscriptionsFn)) {
                            $scope.viewSubscriptionsFn = angular.noop();
                        }


                        $scope.$on("$destroy", function() {
                            unsubscribeAllEvents();
                        });
                    }
                };

                function unsubscribeAllEvents() {
                }

            }
        ]);
})();