angular.module('app')
    .controller('UpdatePasswordCtrl', [
        '$scope', '$location', '$window', 'passwordService', '_', '$state', function ($scope, $location, $window, passwordService, _, $state) {
            $scope.$root.title = 'HowNow Portal';

            $scope.$on('$viewContentLoaded', function(event) {
                $scope.isLoading = false;
                $scope.progress.complete();

                $scope.password = "";
                $scope.passwordConfirm = "";
                
                $scope.passwordChanged = false;
                $scope.passwordNotChanged = false;
                $scope.passwordNotChangedMessage = "";
                $scope.btnWorkingOnIt = false;
                $scope.satisfied = false;
                $scope.showPassword = false;
                $scope.showConfirmPassword = false;

                var setSubmitButtonToWorkingOnIt = function(workingOnIt) {
                    $scope.btnWorkingOnIt = workingOnIt;
                };
                // Function for initial user signup
                $scope.register = function(form) {
                    if (_.isUndefined($state.params.token)) {
                        $scope.passwordNotChanged = true;
                        $scope.passwordNotChangedMessage = "You have not been authorized to reset any passwords.";
                    } else {
                        if ($scope.password === $scope.passwordConfirm) {
                            if (!$scope.satisfied) {
                                $scope.$parent.showNotification("Invalid", "please provide a password that satisfies the requirements", "error");
                                return;
                            }
                            setSubmitButtonToWorkingOnIt(true);
                            passwordService.performReset($scope.password, $state.params.token).then(function(response) {
                                if (response === true) {
                                    $scope.passwordChanged = true;
                                } else if (response.Message === "invite") {
                                    $scope.passwordChanged = false;
                                    $scope.passwordNotChanged = true;
                                    $scope.passwordNotChangedMessage = "You need to complete the sign up process and accept your invite before you can reset your password.";
                                } else if (response === "false") {
                                    $scope.passwordChanged = false;
                                    $scope.passwordNotChanged = true;
                                    $scope.passwordNotChangedMessage = "The password reset token you have been issued is expired (24hrs), Or does not exist. Please start the process again from the forgot password page.";
                                } else {
                                    $scope.passwordChanged = false;
                                    $scope.passwordNotChanged = true;
                                    $scope.passwordNotChangedMessage = "An error occurred on the server while trying to process your request, please try again. If the error persists please contact support.";
                                }

                            });
                        } else {
                            alert("The passwords you have supplied do not match.");
                        }
                    }
                }
            });
        }
    ]);