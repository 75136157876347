(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('NotificationsDetailsAccountCtrl', [
        '$scope', '$modalInstance', 'actionMode', 'passedData', 'notificationMergeFieldsService', function ($scope, $modalInstance, actionMode, passedData, notificationMergeFieldsService) {
            $scope.confirmBtnText = 'Save';
            $scope.cancelBtnText = 'Cancel';
            $scope.canEdit = true;
            $scope.title = "Edit Notification";
            $scope.message = "Customise this notification to meet your needs";

            $scope.notification = {};
            $scope.mergefields = [];

            // Find match for click notification and load into page
            $scope.mergeClick = function(value, type) {
                if (type === "sms") {
                    $("#smsEditor").data("kendoEditor").paste(value);
                } else {
                    $("#emailEditor").data("kendoEditor").paste(value);
                }
            }

            if (!_.isUndefined(passedData)) {
                $scope.notification = angular.copy(passedData);

                if (!_.isUndefined($scope.notification.Type)) {
                    notificationMergeFieldsService.get($scope.notification.Type).then(function (mergeFields) {
                        $scope.mergefields = mergeFields;
                    });
                }
            }

            $scope.confirm = function() {
                var returnVal = { success: false };
                switch (actionMode) {
                case "edit":
                    returnVal.success = true;
                    returnVal.id = passedData.Id;
                    returnVal.notification = $scope.notification;
                    break;
                }
                $modalInstance.close(returnVal);
            }

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            }
        }
    ]);
})();