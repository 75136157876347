(function() {
    "use strict";

    var appModule = angular.module("app");

    appModule.controller("NotificationsListCtrl", [
        "$scope", "$location", "$window", "notificationsService", "$q", "$modal", "constants", "$state", function ($scope, $location, $window, notificationsService, $q, $modal, constants, $state) {

            $scope.selectedItem = null;

            $scope.edit = function() {
                createEditNotificationModal("edit", $scope.selectedItem).result
                    .then(function(result) {
                        return result.success ? $q.when(result) : $q.reject();
                    })
                    .then(function(result) {
                        return notificationsService.update(result.id, result.notification);
                    })
                    .then(refreshGrid);
            };

            function createEditNotificationModal(mode, data) {
                return $modal.open({
                    template: require('Settings\\NotificationsDetails.html'),
                    controller: "NotificationsDetailsAccountCtrl",
                    resolve: {
                        actionMode: function () {
                            return mode;
                        },
                        passedData: function () {
                            return data;
                        }
                    },
                    size: "lg"
                });
            }

            $scope.setNotificationReplyAddress = function() {
                $modal.open({
                        template: require('Settings\\SetNotificationsReplyAddress.html'),
                        controller: "SetNotificationsReplyAddressCtrl"
                    }).result
                    .then(function(result) {
                        if (result) {
                            $scope.showNotification("Success",
                                "Successfully set your reply to address for notifications.",
                                "success");
                        } else {
                            $scope.showNotification("Unable to save changes",
                                "Failed to set your reply to address for notifications, please try again",
                                "error");
                        }
                    });
            };

            function refreshGrid() {
                $scope.selectedItem = null;
                $scope.progress.start();
                return $scope.bfGridTriggers.triggerRead();
            }

            $scope.bfGridTriggers = {};

            $scope.bfGridOptions = {
                dataSource: function() { return notificationsService.get(); },
                dataBound: function() {
                    $scope.progress.complete();
                    $scope.isLoading =
                        false; // This seemingly unused scope value is to tell the responsive-loading directive to go away. Future refactoring hopefully?
                },
                multiple: false,
                fullScreen: true,
                sortable: false,
                columns: [
                    {
                        field: "Title",
                        title: "Templates"
                    },
                    {
                        field: "Enabled",
                        title: "Enabled",
                        width: "150px",
                        headerAttributes: { "class": "grid-center" },
                        attributes: { "class": "grid-center" },
                        template: '# if(Enabled && DisableAllowed){# <span class="fa fa-check"></span> #} else if(Enabled && !DisableAllowed){# <span class="fa fa-lock"></span> #} else if(!Enabled && !DisableAllowed){# <span class="fa fa-exclamation-triangle"></span> #}#'
                    }
                ]
            };

            $scope.onSelectedRowChange = function (rowItem) {
                $scope.selectedItem = rowItem;
            };

            $scope.enable = function() {
                notificationsService.enable($scope.selectedItem.Id)
                    .then(refreshGrid);
            };

            $scope.disable = function() {
                notificationsService.disable($scope.selectedItem.Id)
                    .then(refreshGrid);
            };
        }
    ]);
})();
