(function() {
    'use strict';
    var module = angular.module('bf.services.signature', []);

    module.service('signatureService', [
        '_', '$q', '$timeout', 'momentDateTime', 'events', 'constants', 'dataStoreService', '$http', 'ENVIRONMENTAL',
        function (_, $q, $timeout, momentDateTime, events, constants, dataStoreService, $http, ENVIRONMENTAL) {

            var clickToSignApiSection = '/sign/';
            var multipleSignaturesApiSection = '/securesign/';

            return {
                verifyDocumentForSigning: localVerifyDocumentForSigning,
                requestClickToSign: localRequestClickToSign,
                getDownloadForSigning: localGetDownloadForSigning,
                verifyMultipleSignaturePin: localVerifyDocumentMultipleSignaturePin,
                updateMultipleSignatureDocument: localUpdateMultipleSignatureDocument,
                verifyClickToSignPin: localVerifyClickToSignPin,
                updateClickToSignDocument: localUpdateClickToSignDocument,
                renewLockForSigning: localRenewLockForSigning,
                getSigningPreferences:localGetSigningPreferences,
                updateSigningPreferences: localUpdateSigningPreferences,
                identifyUser: identifyUser
            }

            function localVerifyDocumentForSigning(documentId) {
                var deferred = $q.defer();
                if (!_.isUndefined(documentId)) {
                    dataStoreService.getResourceWithErrorData(ENVIRONMENTAL.BASEURLS.API + clickToSignApiSection + 'verifyDocumentForSigning/' + documentId).then(function (data) {
                        if (data === true) {
                            deferred.resolve(data);
                        } else if (!_.isUndefined(data.Message)) {
                            deferred.reject(data.Message);
                        }
                        deferred.reject('Unknown reply from service');
                    }, function (error) {
                            if (error.Message !== undefined) {
                                deferred.reject(error.Message);
                            }
                            deferred.reject(error);
                    });
                } else {
                    deferred.reject("Document Id can not be empty");
                }
                return deferred.promise;
            }

            function localRequestClickToSign(documentId, theDataToSend) {
                var deffered = $q.defer();
                if (!_.isUndefined(theDataToSend)) {
                    dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + clickToSignApiSection + 'clickToSign/' + documentId, theDataToSend).then(function (data) {
                        if (data != null && _.isUndefined(data.ExceptionMessage)) {
                            deffered.resolve(data);
                            return deffered.promise;
                        } else {
                            deffered.resolve(false);
                            return deffered.promise;
                        }
                    });
                } else {
                    deffered.reject();
                    return deffered.promise;
                }
                return deffered.promise;
            }

            function localGetDownloadForSigning(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.FILEAPI.BASEURL + "/api/download/getSignatureDocument", { Id: id });
                }
                deffered.reject();
                return deffered.promise;
            }

            function localVerifyDocumentMultipleSignaturePin(theDataToSend) {
                var deffered = $q.defer();
                if (!_.isUndefined(theDataToSend)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + multipleSignaturesApiSection + 'verifyEmployee/' + theDataToSend.DocumentId, { Pin: theDataToSend.Pin }).then(function (data) {
                        if (data != null && _.isUndefined(data.ExceptionMessage)) {
                            deffered.resolve(data);
                            return deffered.promise;
                        } else {
                            deffered.resolve(false);
                            return deffered.promise;
                        }
                    });
                } else {
                    deffered.reject();
                    return deffered.promise;
                }
                return deffered.promise;
            }

            function localUpdateMultipleSignatureDocument(documentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + multipleSignaturesApiSection + 'employeeSigned/' + documentId).then(function(data) {
                        deffered.resolve(data);
                    });
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            }

            function localVerifyClickToSignPin(theDataToSend) {
                var deffered = $q.defer();
                if (!_.isUndefined(theDataToSend)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + clickToSignApiSection + '/clickToSign/verifyEmployee/' + theDataToSend.DocumentId, { Pin: theDataToSend.Pin }).then(function (data) {
                        if (data != null && _.isUndefined(data.ExceptionMessage)) {
                            deffered.resolve(data);
                            return deffered.promise;
                        } else {
                            deffered.resolve(false);
                            return deffered.promise;
                        }
                    });
                } else {
                    deffered.reject();
                    return deffered.promise;
                }
                return deffered.promise;
            }

            function localUpdateClickToSignDocument(documentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + clickToSignApiSection + '/clickToSign/employeeSigned/' + documentId).then(function(data) {
                        deffered.resolve(data);
                    });
                } else {
                    deffered.reject();
                }

                return deffered.promise;
            }

            function localRenewLockForSigning(documentId, signatoryId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + clickToSignApiSection + 'renewLock/' + documentId).then(function (data) {
                        deffered.resolve(data);
                    });
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            }

            function localGetSigningPreferences() {
                var deferred = $q.defer();
                dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + clickToSignApiSection + 'settings/').then(function(data) {
                    deferred.resolve(data);
                });
                return deferred.promise;
            }

            function localUpdateSigningPreferences(payload) {
                var deffered = $q.defer();
                if (!_.isUndefined(payload)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + clickToSignApiSection + 'settings/', payload).then(function (data) {
                        deffered.resolve(data);
                    });
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            }

            function identifyUser() {
                return $http.get(ENVIRONMENTAL.BASEURLS.SIGN + 'api/users/identify', { withCredentials: true })
                    .catch(function() { }); // Ignore any errors as identifyUser is just used for diagnostics
            }

        }


    ]);
})();
