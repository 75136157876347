(function () {
    'use strict';

    const $humanize = require('humanize-plus');

    angular.module('bf.components.announcementsUpload', ['bf.constants']);

    angular.module('bf.components.announcementsUpload')
        .factory('announcementsUploadService', [
            'constants', '_', 'events', '$timeout', '$q', '$http', 'ENVIRONMENTAL', 'dataStoreService', "logService", function (constants, _, events, $timeout, $q, $http, ENVIRONMENTAL, dataStoreService, logService) {

                var filesToUpload = [];

                var initNewFileWithDefaults = function(file) {
                    file.uploadComplete = false;
                    file.humanizedData = {
                        'name': $humanize.truncate(file.name, 46, '...'),
                        'size': $humanize.fileSize(file.size)
                    }
                    file.retrying = false;
                    file.uploadProgress = 0;
                    file.uploadComplete = false;
                    file.errored = false;
                    file.aborted = false;
                    return file;
                }

                var localUpdateFileProgress = function(fileId, newProgress) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.uploadProgress = newProgress;
                        file.retrying = false;
                        events.trigger(constants.ANNOUNCEMENTS.EVENTS.PROGRESSCHANGE, file);
                    }
                }

                var localFileErrored = function(fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        if (_.isUndefined(file.failureReason)) {
                            file.failureReason = "An error occurred, please try again.";
                        }
                        file = setFileStateToErrored(file);
                        events.trigger(constants.ANNOUNCEMENTS.EVENTS.ERRORED, file);
                        logService.error({ File: { Title: file.name, Identifier: file.Identifier, Size: file.size } }, "Upload failed");
                    }
                }
                var setFileStateToErrored = function(file) {
                    file.errored = true;
                    file.retrying = false;
                    file.uploadComplete = false;
                    return file;
                }

                var localFileUploadComplete = function(fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.uploadComplete = true;
                        events.trigger(constants.ANNOUNCEMENTS.EVENTS.COMPLETE, file);
                    }
                }

                var localFileUploadRetring = function(fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.retrying = true;
                        events.trigger(constants.ANNOUNCEMENTS.EVENTS.RETRYING, file);
                    }
                }

                var localFileUploadAborted = function (fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.aborted = true;
                        events.trigger(constants.ANNOUNCEMENTS.EVENTS.ABORTED, file);
                    }
                }

                var localAddFile = function(file, index) {
                    var deffered = $q.defer();

                    var addedFileId = 0;

                    if (filesToUpload && filesToUpload.length > 0) {
                        addedFileId = filesToUpload.length;
                    }
                    if (!_.isUndefined(index)) {
                        addedFileId += index;
                    }

                    file.Id = addedFileId;

                    file = initNewFileWithDefaults(file);

                    events.trigger(constants.ANNOUNCEMENTS.EVENTS.FILEADDED, file);

                    getFileIdentifier().then(function(fileIdentifier) {
                        if (!_.isUndefined(fileIdentifier) && !_.isNull(fileIdentifier) && !_.isEmpty(fileIdentifier)) {
                            file.Identifier = fileIdentifier;
                            file.uniqueIdentifier = file.Identifier;
                            filesToUpload[addedFileId] = file;
                            deffered.resolve(addedFileId);
                        } else {
                            file = setFileStateToErrored(file);
                            deffered.reject("Issue getting file identifier from server");
                        }
                    });
                    return deffered.promise;
                }

                var getFileIdentifier = function() {
                    var deffered = $q.defer();
                    $http({
                        url: ENVIRONMENTAL.FILEAPI.BASEURL + '/api/upload/GetNewIdentifier',
                        method: "GET"
                    }).success(function(data, status, headers, config) {
                        deffered.resolve(data.replace(/"/g, ''));
                    }).error(function(data, status, headers, config) {
                        deffered.reject(status);
                    });
                    return deffered.promise;
                }

                var localAddFiles = function (files) {
                    var localAddFilePromises = [];
                    var deffered = $q.defer();

                    _.each(files, function(file, index) {
                       localAddFilePromises.push(localAddFile(file, index));
                    });

                    $q.all(localAddFilePromises).then(function(allFilesIds) {
                        deffered.resolve(allFilesIds);
                    });
                    return deffered.promise;
                }

                var localGetFile = function(fileId) {
                    var file = filesToUpload[fileId];
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        return file;
                    }
                    return undefined;
                }

                var localGetFiles = function() {
                    if (!_.isUndefined(filesToUpload) && !_.isNull(filesToUpload)) {
                        return filesToUpload;
                    }
                    return undefined;
                }

                var localStartFileUpload = function(fileId, dataToSendWithFile) {
                    var file = filesToUpload[fileId];
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        if (file.size === 0) {
                            file.failureReason = "Empty files are not valid, please upload a file with content.";
                            localFileErrored(file.Id);
                            return;
                        }
                        if (!_.isUndefined(file.uploadComplete) && !_.isNull(file.uploadComplete) && file.uploadComplete !== true) {
                            if (_.isUndefined(dataToSendWithFile) || _.isNull(dataToSendWithFile)) {
                                dataToSendWithFile = {};
                            }

                            dataToSendWithFile.fileId = file.Identifier;
                            file.uniqueIdentifier = file.Identifier;
                            file.uploadProgress = 0;
                            file.resume();
                        }
                    }
                }

                var localStartAllUploads = function(dataToSendWithFile) {
                    _.each(filesToUpload, function (file) {
                        if (file.uploadProgress === 0) {
                            localStartFileUpload(file.Id, dataToSendWithFile);
                        }
                    });
                }

                var localAbort = function(fileId) {
                    var file = filesToUpload[fileId];
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.cancel();
                        localFileUploadAborted(fileId);
                    }
                }

                var localAbortAll = function() {
                    if (filesToUpload && filesToUpload.length > 0) {
                        _.each(filesToUpload, function(file) {
                            file.cancel();
                            localFileUploadAborted(fileId);
                        });
                    }
                }

                var localRemoveAll = function() {
                    if (filesToUpload && filesToUpload.length > 0) {
                        filesToUpload = [];
                    }
                }

                function commitFile(fileId, originalFileName) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.FILEAPI.BASEURL + "/api/upload/uploadchunks/commit", { fileId: fileId, originalFileName: originalFileName });
                }

                return {
                    addFile: localAddFile,
                    addFiles: localAddFiles,
                    getFile: localGetFile,
                    getFiles: localGetFiles,
                    startFileUpload: localStartFileUpload,
                    startAllUploads: localStartAllUploads,
                    abort: localAbort,
                    abortAll: localAbortAll,
                    retrying: localFileUploadRetring,
                    completed: localFileUploadComplete,
                    updateProgress: localUpdateFileProgress,
                    errored: localFileErrored,
                    removeAll: localRemoveAll,
                    commitFile: commitFile
                }
            }
        ])
        .directive('announcementsUploadProgress', [
            'constants', '_', 'events', '$timeout', 'announcementsUploadService', function (constants, _, events, $timeout, announcementsUploadService) {

                var evtUploadStart = angular.noop;
                var evtFileAdded = angular.noop;
                var evtFileComplete = angular.noop;
                var evtGettingFileIdentifier = angular.noop;
                var evtFileStatusChanged = angular.noop;
                var evtLogout = angular.noop;

                return {
                    restrict: 'E',
                    template: require('Announcements\\Upload\\announcementsUploadProgress.html'),
                    replace: true,
                    link: function($scope, element, attrs) {

                        $scope.filesBeingUploaded = [];

                        $scope.cancelFileUpload = function(fileId) {
                            if (!_.isUndefined(fileId) && !_.isNull(fileId)) {
                                announcementsUploadService.abort(fileId);
                            }
                        }
                        
                        $scope.uploadListProgressColumnWidth = function(file) {
                            return file.uploadProgress < 100 ? '25%' : '75%';
                        }
                        
                        evtFileAdded = events.on(constants.ANNOUNCEMENTS.EVENTS.FILEADDED, function(evt, file) {
                            if (!_.isUndefined(file)) {
                                $scope.filesBeingUploaded.push(file);
                            }
                        });
                        
                        $scope.$on('$destroy', function() {
                            unsubscribeAllEvents();
                        });
                    }
                };

                function unsubscribeAllEvents() {
                    evtUploadStart();
                    evtFileAdded();
                    evtFileComplete();
                    evtGettingFileIdentifier();
                    evtFileStatusChanged();
                    evtLogout();
                }

            }
        ]);

})();