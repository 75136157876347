(function() {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('NotificationsCtrl', [
        '$scope', '$location', '$window', 'systemnotificationsService', '$q', '$modal', 'constants', '$timeout', 'events', '$state', function($scope, $location, $window, systemnotificationsService, $q, $modal, constants, $timeout, events, $state) {
            $scope.canEdit = false;

            var dataBoundEvtHandler = function () {
                $timeout(function () {
                    events.trigger(constants.SYSTEM.EVENTS.GRIDRESIZE);
                }, 1);
            };

            $scope.notificationsGridOptions = {
                dataSource: {
                    transport: {
                        read: function(options) {
                            var promise = systemnotificationsService.get()
                                .then(function(data) {
                                    options.success(data);
                                    $scope.isLoading = false;
                                    $scope.progress.complete();
                                });
                            return $q.when(promise);
                        }
                    },
                    pageSize: 1,
                    serverPaging: true,
                    serverSorting: true,
                    serverFiltering: true
                },
                sortable: false,
                selectable: "row",
                dataBound: dataBoundEvtHandler,
                columns: [{ field: 'Title', title: 'Name' }]
            };


            $scope.handleChange = function (dataItem) {
                $state.params = dataItem;
                $scope.canEdit = true;
            };

            $scope.addNotification = function () {
                var modalInstance = createEditNotificationModal("create");

                modalInstance.result.then(function(result) {
                    if (result.success) {
                        $scope.progress.start();
                        systemnotificationsService.add(result.notification).then(function() {
                            refreshGrid();
                        });
                    }
                });
            };
            $scope.editNotification = function() {
                var modalInstance = createEditNotificationModal("edit", $state.params);

                modalInstance.result.then(function(result) {
                    if (result.success) {
                        $scope.progress.start();
                        systemnotificationsService.update(result.id, result.notification).then(function() {
                            refreshGrid();
                        });
                    }
                });
            };
            var createEditNotificationModal = function(mode, data) {
                return $modal.open({
                    template: require('Notifications\\NotificationsDetails.html'),
                    controller: 'NotificationsDetailsCtrl',
                    resolve: {
                        actionMode: function() {
                            return mode;
                        },
                        passedData: function() {
                            return data;
                        }
                    },
                    size: 'lg'
                });
            };

            var refreshGrid = function() {
                $scope.grid.dataSource.read();
                $state.params = null;
                $scope.canEdit = false;
            };
        }
    ]);


})();
