(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('FiltersDetailsCtrl', [
        '$scope', '$modalInstance', 'actionMode', 'passedData', function($scope, $modalInstance, actionMode, passedData) {

            $scope.confirmBtnText = 'Confirm';
            $scope.cancelBtnText = 'Cancel';
            $scope.canEdit = false;
            $scope.title = "";
            $scope.message = "";
            $scope.text = "";
            $scope.id = "";
            $scope.parentId = "";
            $scope.validationWarn = false;
            $scope.validationWarnMsg = "Required: Please supply a name for the filter.";

            $scope.deleteWarn = false;
            $scope.deleteWarning = "Deleting this filter will remove the associated filter from all clients. Documents assigned to this filter will be moved to the default location for each client respectively.";

            $scope.updateWarn = false;
            $scope.updateWarning = "Updating this filter will apply the update to the associated filter for all clients.";

            var validateFilterName = function() {
                if (_.isUndefined($scope.text) || $scope.text == "") {
                    return false;
                }
                return true;
            }

            switch (actionMode) {
            case "create":
                $scope.title = 'Create New Filter';
                $scope.message = 'Please enter the name of the new filter';
                $scope.confirmBtnText = 'Create';
                $scope.canEdit = true;
                if (passedData) {
                    $scope.parentId = passedData.Id;
                }
                break;
            case "delete":
                $scope.deleteWarn = true;
                $scope.title = 'Delete Filter';
                $scope.message = 'Please confirm you wish to delete this filter';
                $scope.confirmBtnText = 'Delete';
                $scope.canEdit = false;
                if (passedData) {
                    $scope.text = passedData.Text;
                    $scope.id = passedData.Id;
                }
                break;
            case "edit":
                $scope.updateWarn = true;
                $scope.title = 'Update Filter';
                $scope.message = 'Please change the settings below';
                $scope.confirmBtnText = 'Update';
                $scope.canEdit = true;
                if (passedData) {
                    $scope.text = passedData.Text;
                    $scope.id = passedData.Id;
                }
                break;
            default:
                $scope.title = 'Opps';
                $scope.message = 'Something didn\'t work out';
                break;
            }

            $scope.confirm = function() {
                var returnVal = false;
                switch (actionMode) {
                case "create":
                    if (!validateFilterName()) {
                        $scope.validationWarn = true;
                        return;
                    }
                    returnVal = { success: true, text: $scope.text, parentId: $scope.parentId };
                    break;
                case "delete":
                    returnVal = { success: true, id: $scope.id };
                    break;
                case "edit":
                    if (!validateFilterName()) {
                        $scope.validationWarn = true;
                        return;
                    }
                    returnVal = { success: true, id: $scope.id, text: $scope.text };
                    break;
                default:

                    break;
                }
                $modalInstance.close(returnVal);
            }

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            }

        }
    ]);
})();
