(function (angular) {
    "use strict";

    angular
        .module("app")
        .controller("employeeManagementCtrl", [
            "employeesService", "$modalInstance", "selectedFirm", function (employeesService, $modalInstance, selectedFirm) {

                var vm = this;
                vm.employees = [];
                vm.firmName = selectedFirm.Name;
                vm.error = false;

                vm.close = function () {
                    $modalInstance.close(true);
                }

                employeesService.getAllForFirm(selectedFirm.Id)
                    .then(function(employees) {
                        vm.employees = employees;
                    });
            }
        ]);

})(window.angular);