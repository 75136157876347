(function(angular) {
    'use strict';

    angular
        .module('app')
        .directive('accountInvitesAcceptedByClient', ['reportUtilities', 'clientsService', function(rptUtils, clientsService) {

            function link($scope, element, attrs) {

                var reportEndpointName = 'accountinvitesacceptedbyclient';
                var endpoint = '/reports/';

                $scope.clientTags = [];
                $scope.dateFrom = null;
                $scope.dateTo = null;
                $scope.isValid = true;

                $scope.onSearchClients = function(q) {
                    return clientsService.get(q);
                };

                $scope.buildUrl = function() {
                    var url = endpoint + reportEndpointName
                            + rptUtils.getUtcOffsetQueryParam('?')
                            + rptUtils.buildListAsQueryParams($scope.clientTags, '&', 'clientId', function(c) { return c.Id; })
                            + rptUtils.getMomentQueryParam('&', 'dateFrom', $scope.dateFrom)
                            + rptUtils.getMomentQueryParam('&', 'dateTo', $scope.dateTo);
                    return url;
                };

                $scope.buildExcelDownloadUrl = function () {
                    var url = endpoint + 'download/asexcel/' + reportEndpointName
                            + rptUtils.getUtcOffsetQueryParam('?')
                            + rptUtils.buildListAsQueryParams($scope.clientTags, '&', 'clientId', function(c) { return c.Id; })
                            + rptUtils.getMomentQueryParam('&', 'dateFrom', $scope.dateFrom)
                            + rptUtils.getMomentQueryParam('&', 'dateTo', $scope.dateTo);
                    return url;
                };
            }

            return {
                restrict: 'E',
                template: require('Reports\\ReportForms\\AccountInvitesAcceptedByClient.html'),
                link: link,
            };
        }]);

})(window.angular);
