(function() {
    "use strict";

    var module = angular.module("bf.services.firms", []);

    module.service("firmsService", [
        "$q", "_", "dataStoreService", "$filter", "ENVIRONMENTAL", function($q, _, dataStoreService, $filter, ENVIRONMENTAL) {

            var apiSection = "/firms/";

            function localGet(searchTerm, pageNumber, itemsPerPage, sortOptions) {
                var search = "";
                var pre = "?";
                if (!_.isUndefined(searchTerm) && searchTerm != "") {
                    search = pre + "searchTerm=" + encodeURIComponent(searchTerm);
                    pre = "&";
                }
                if (!_.isUndefined(pageNumber) && !_.isUndefined(itemsPerPage) && _.isNumber(pageNumber) && _.isNumber(itemsPerPage)) {
                    search += pre + "pageNumber=" + encodeURIComponent(pageNumber) + "&itemsPerPage=" + encodeURIComponent(itemsPerPage);
                    pre = "&";
                }

                if (!_.isUndefined(sortOptions) && !_.isNull(sortOptions)) {
                    var sortDir = null;
                    var sortField = null;
                    if (_.isArray(sortOptions) && sortOptions.length > 0) {
                        sortDir = sortOptions[0].dir;
                        sortField = sortOptions[0].field;
                        search += pre + "sortDirection=" + sortDir + "&sortField=" + sortField;
                        pre = "&";
                    }
                }

                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + search);
            }

            function localgetById(id) {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
            }

            function localUpdate(id, newFirmDetails) {
                if (!_.isUndefined(newFirmDetails)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id, newFirmDetails);
                }
                var deffered = $q.defer();
                deffered.reject();
                return deffered.promise;
            }

            function localDeactivate(id) {
                return dataStoreService.deleteResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
            }

            function localRegister(firmSignupModel) {
                if (!_.isUndefined(firmSignupModel)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "register", firmSignupModel);
                }

                return $q.reject("Invalid firm signup");
            }
            
            return {
                get: localGet,
                getById: localgetById,
                update: localUpdate,
                deactivate: localDeactivate,
                register: localRegister
            }
        }
    ]);
})();