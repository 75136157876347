(function(angular) {
    "use strict";

    angular.module("app")
        .controller("FirmsCtrl", [
            "$scope", "$location", "$window", "$state", "$q", "events", "$timeout", "firmsService", "constants", "$modal",
            function($scope, $location, $window, $state, $q, events, $timeout, firmsService, constants, $modal) {

                var vm = $scope;
                vm.firmsToDisplay = true;
                vm.isLoading = true;
                vm.bfGridTriggers = {};
                vm.isDisabled = true;
                vm.searchTerm = "";
                vm.searchForm = {};
                var selectedFirm = {};
                vm.selectedFirmIsInactive = true;

                vm.manageFeatures = function() {
                    $modal.open({
                            template: require('Firms\\FeatureAccessManagement.html'),
                            controller: "featureAccessManagementCtrl as vm",
                            resolve: {
                                selectedFirm: function() {
                                    return selectedFirm;
                                }
                            }
                        })
                        .result.then(function(result) {
                            if (result)
                                $scope.showNotification("Success!", "Firms features updated", "success");
                        });
                };

                vm.manageEmployees = function() {
                    $modal.open({
                        template: require('Firms\\EmployeesManagement.html'),
                        controller: "employeeManagementCtrl as vm",
                        size: "lg",
                        resolve: {
                            selectedFirm: function() {
                                return selectedFirm;
                            }
                        }
                    });
                };

                vm.registerFirm = function() {
                    $modal.open({
                            template: require('Firms\\RegisterFirm.html'),
                            controller: "registerFirmCtrl as vm",
                            resolve: {}
                        })
                        .result.then(function (result) {
                            if (result) {
                                $scope.showNotification("Success", "Firm registered", "success");
                                refreshGrid();
                            }
                        });
                };

                vm.editFirm = function() {
                    $modal.open({
                            template: require('Firms\\EditFirm.html'),
                            controller: "editFirmCtrl as vm",
                            resolve: {
                                selectedFirm: function() {
                                    return selectedFirm;
                                }
                            }
                        })
                        .result.then(function(result) {
                            if (result) {
                                $scope.showNotification("Success", "Firm updated", "success");
                                refreshGrid();
                            }
                        });
                };

                vm.deactivateFirm = function() {
                    $modal.open({
                            template: require('Firms\\DeactivateFirm.html'),
                            controller: "deactivateFirmCtrl as vm",
                            resolve: {
                                selectedFirm: function() {
                                    return selectedFirm;
                                }
                            }
                        })
                        .result.then(function(result) {
                            if (result) {
                                $scope.showNotification("Success", "Firm deactivated", "success");
                                refreshGrid();
                            }
                        });
                };

                vm.searchForm.handleSearch = function() {
                    vm.searchTerm = vm.searchForm.term;
                    refreshGrid();
                };

                vm.clearSearch = function() {
                    vm.searchForm.term = "";
                    vm.searchTerm = "";
                    refreshGrid();
                };

                function refreshGrid() {
                    selectedFirm = {};
                    vm.selectedFirmIsInactive = true;
                    vm.isDisabled = true;
                    return vm.bfGridTriggers.triggerRead();
                }

                vm.onSelectedRowChange = function(rowItems) {
                    vm.isDisabled = false;
                    selectedFirm = rowItems;
                    vm.selectedFirmIsInactive = !selectedFirm.IsActive;
                };

                vm.bfGridOptions = {
                    dataSource: function(options) {
                        vm.isLoading = true;
                        return firmsService.get(vm.searchTerm,
                                options.data.page,
                                options.data.pageSize,
                                options.data.sort)
                            .then(function(dataItem) {
                                vm.firmsToDisplay = vm.searchTerm || dataItem.Data && dataItem.Data.length > 0;
                                return dataItem;
                            })
                            .finally(function() {
                                vm.progress.complete();
                                vm.isLoading = false;
                            });
                    },
                    multiple: false,
                    fullScreen: true,
                    columns: [
                        { field: "Name", title: "Name" },
                        {
                            headerAttributes: { "class": "grid-center" },
                            attributes: { "class": "grid-center" },
                            field: "IsActive",
                            title: "Active",
                            width: "100px",
                            template: kendo.template($("#isActiveTemplate").html())
                        },
                        {
                            field: "Id",
                            title: "Id",
                            width: "300px",
                            headerAttributes: { "class": "grid-center" },
                            attributes: { "class": "grid-center" }
                        }
                    ],
                    schema: {
                        data: "Data",
                        total: "Total"
                    }
                };
            }
        ]);
})(window.angular);