(function (angular) {
    "use strict";

    angular
        .module("app")
        .controller("AdobeSignPrechecksCtrl", [
            "$scope", "$q", "_", "selectedDoc", "$modal", "$modalInstance", "documentsService", "adobeSignService", "$timeout",
            function($scope, $q, _, selectedDoc, $modal, $modalInstance, documentsService, adobeSignService, $timeout) {

                $scope.title = "Adobe Signing Requirements";
                $scope.loadingMessage = "Running some checks on your file for minimum signing requirements";
                $scope.failedChecksReason = "";
                $scope.error = false;
                $scope.errorMsg = "";
                $scope.cancelBtnText = "Close";
                $scope.showConfirmDeleteBtn = false;
                $scope.fileDeleteBtnText = "Delete file";
                $scope.confirmFileDeleteBtnText = "Yes I am sure";
                $scope.document = selectedDoc;
                $scope.isLoading = true;

                var displayErrorMessage = function(message) {
                    $scope.errorMsg = message;
                    $scope.error = true;
                    $scope.isLoading = false;
                }

                var initiliseModal = function() {

                    if (_.isUndefined(selectedDoc) || _.isNull(selectedDoc)) {
                        displayErrorMessage("There was an issue getting information about the document you wish to use. Please close this message and try again");
                    }

                    adobeSignService.verifyDocumentForSigning(selectedDoc.Id)
                        .then(function() {

                            $modal.open({
                                    template: require('Documents\\AdobeSign\\RequestAdobeSign.html'),
                                    controller: "RequestAdobeSignCtrl",
                                    backdrop: "static",
                                    resolve: {
                                        selectedDoc: function() {
                                            return $scope.document;
                                        }
                                    }
                                }).result
                                .then(function () {
                                    // This timeout is just to help the flow of the UI feel better.
                                    $timeout(function() {
                                            $modalInstance.close({
                                                reason: "finished",
                                                message: "Adobe Sign agreement created"
                                            });
                                        },
                                        500);
                                })
                                .catch(function(err) {
                                    var requestDismissReason = "";
                                    if (err != null && err.reason !== "cancel") {
                                        requestDismissReason =
                                            "The created agreement could not be opened for sending. Please contact your system administrator.";
                                    }
                                    $modalInstance.dismiss({ reason: "cancel", message: requestDismissReason });
                                });

                        })
                        .catch(function(error) {
                            $timeout(function () {
                                displayErrorMessage("Unfortunately the selected file didn't pass our checks.");
                                $scope.failedChecksReason = error;
                                },
                                500);
                        });
                }

                $scope.confirmFileDelete = function() {
                    $scope.showConfirmDeleteBtn = true;
                }

                $scope.deleteFile = function() {
                    $scope.loadingMessage = "Deleting document";
                    $scope.isLoading = true;
                    documentsService.remove(selectedDoc.Id).then(function() {
                            $modalInstance.close({ reason: "finished", message: "User deleted document" });
                        },
                        function() {
                            displayErrorMessage("Failed to delete document");
                        });
                }

                $scope.cancel = function() {
                    $modalInstance.dismiss({ reason: "cancel", message: "User canceled action" });
                };

                initiliseModal();
            }
        ]);
})(window.angular);
