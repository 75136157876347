(function(angular, moment) {
    'use strict';

    angular
        .module('bf.components.controls', ['app'])
        .directive('bfDatePicker', ['reportUtilities', 'clientsService', function(rptUtils, clientsService) {

            function link($scope, element, attrs) {
                $scope.dateFormat = 'DD/MMM/YYYY';
                syncDateFormat();

                // When the format changes, update the text value of the field
                $scope.$watch('dateFormat', function() {
                    if ($scope.ctrlValue) {
                        $scope.ctrlValue = moment($scope.ctrlValue, $scope.dateFormatPrev).format($scope.dateFormat);
                        syncDateFormat();
                    }
                });

                // When the bound value changes, update the text value
                $scope.$watch('value', function() {
                    sanitiseValue();
                    $scope.ctrlValue = getCurrentCtrlValue();
                });

                // When the text value changes, update the externally bound value with a moment object
                $scope.$watch('ctrlValue', function() {
                    $scope.value = getCurrentMomentValue();
                });

                $scope.open = function($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    $scope.opened = !$scope.opened;
                };

                function sanitiseValue() {
                    // We could have anything passed in, so sanitise it to a moment object
                    if (!$scope.value || $scope.value._isAMomentObject) return;

                    if ($scope.value instanceof Date) {
                        $scope.value = moment($scope.value);
                        return;
                    }

                    if (typeof $scope.value === 'string' || $scope.value instanceof String) {
                        $scope.value = moment($scope.value, $scope.dateFormat);
                    }

                    // If it's not a date or a string, ignore
                    $scope.value = null;
                }

                function getCurrentMomentValue() {
                    return $scope.ctrlValue ? moment($scope.ctrlValue, $scope.dateFormat) : null;
                }

                function getCurrentCtrlValue() {
                    return $scope.value ? $scope.value.format($scope.dateFormat) : null;
                }

                function syncDateFormat() {
                    $scope.dateFormatPrev = $scope.dateFormat;
                }

            }

            return {
                restrict: 'E',
                replace: true,
                template: require('Site\\Components\\Views\\bfDatePicker.html'),
                link: link,
                scope: {
                    labelText: '@',
                    dateFormat: '@',
                    value: '='
                }
            };
        }]);

})(window.angular, window.moment);
