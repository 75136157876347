(function(angular) {
    'use strict';
    angular
        .module('bf.components.bfFeatureToggle', ['bf.constants'])
        .directive('bfFeatureToggle', ['featuresService', function(featuresService) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs, ctrl, transclude) {
                    var featureName = attrs.bfFeatureToggle;
                    var removeWhenEnabled = false;
                    if (featureName[0] === '!') {
                        removeWhenEnabled = true;
                        featureName = featureName.substr(1);
                    }
                    featuresService.isEnabled(featureName).then(function (enabled) {
                            if (enabled === removeWhenEnabled) {
                                element.remove();
                            }
                        })
                        .catch(function(er) {
                            element.remove();
                        });
                }
            };
        }]);
})(window.angular);