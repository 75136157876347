(function () {
    "use strict";

    angular.module("bf.components.firms", []);

    angular.module("bf.components.firms")
        .directive("firmActions", ["_", function (_) {
            return {
                    restrict: "E",
                    scope: {
                        actionsFor: "@",
                        featuresFn: "&",
                        employeesFn: "&",
                        editFn: "&",
                        deactivateFn: "&",
                        isDisabledBtn: "=",
                        deactivateDisabled: "=",
                        registerFn: "&"
                    },
                    template: require('Site\\Components\\Views\\firmActions.html'),
                    replace: true,
                    link: function ($scope, element, attrs) {
                        
                        if (!$scope.hasOwnProperty("actionsFor") || _.isUndefined($scope.actionsFor)) {
                            $scope.actionsFor = "firm";
                        }

                        if (!$scope.hasOwnProperty("featuresFn") || _.isUndefined($scope.featuresFn)) {
                            $scope.featuresFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("employeesFn") || _.isUndefined($scope.employeesFn)) {
                            $scope.employeesFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("editFn") || _.isUndefined($scope.editFn)) {
                            $scope.editFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("deactivateFn") || _.isUndefined($scope.deactivateFn)) {
                            $scope.deactivateFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty("registerFn") || _.isUndefined($scope.registerFn)) {
                            $scope.registerFn = angular.noop();
                        }
                        
                        $scope.$on("$destroy", function () {
                            unsubscribeAllEvents();
                        });
                    }
                };

                function unsubscribeAllEvents() {
                }

            }
        ]);
})();