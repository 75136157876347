(function(angular) {
    "use strict";

    angular
        .module("app")
        .directive("unreadDocumentsByAccount",
            [
                "reportUtilities", "accountsService", function(rptUtils, accountsService) {
                    function link($scope, element, attrs) {

                        var reportEndpointName = "unreaddocumentsbyaccount";
                        var endpoint = "/reports/";

                        $scope.accountTags = [];
                        $scope.dateFrom = null;
                        $scope.dateTo = null;
                        $scope.maxDays = 7;

                        $scope.onSearchAccounts = function(q) {
                            return accountsService.get(q).then(function(accounts) {
                                return accounts.map(function(acct) {
                                    return { Id: acct.Id, Name: acct.GivenName + " " + acct.Surname };
                                });
                            });
                        };

                        $scope.buildUrl = function() {
                            var url = endpoint
                                    + reportEndpointName
                                    + rptUtils.getUtcOffsetQueryParam("?")
                                    + rptUtils.buildListAsQueryParams($scope.accountTags, "&", "accountId", function (c) { return c.Id; })
                                    + rptUtils.getMomentQueryParam("&", "dateFrom", $scope.dateFrom)
                                    + rptUtils.getMomentQueryParam("&", "dateTo", $scope.dateTo);
                            return url;
                        };

                        $scope.buildExcelDownloadUrl = function() {
                            var url = endpoint
                                    + "download/asexcel/"
                                    + reportEndpointName
                                    + rptUtils.getUtcOffsetQueryParam("?")
                                    + rptUtils.buildListAsQueryParams($scope.accountTags, "&", "accountId", function (c) { return c.Id; })
                                    + rptUtils.getMomentQueryParam("&", "dateFrom", $scope.dateFrom)
                                    + rptUtils.getMomentQueryParam("&", "dateTo", $scope.dateTo);
                            return url;
                        };
                    };

                    return {
                        restrict: "E",
                        template: require('Reports\\ReportForms\\UnreadDocumentsByAccount.html'),
                        link: link,
                    };
                }
            ]);
})(window.angular);