(function(angular) {
    'use strict';

    var utcOffset = (new Date()).getTimezoneOffset() / 60 * -1;

    angular
        .module('app')
        .factory('reportUtilities', [function() {

            function getUtcOffsetQueryParam(firstChar, paramNameOverride) {
                var pName = paramNameOverride || 'utcOffset';
                return firstChar + pName + '=' + utcOffset;
            }

            function getMomentQueryParam(firstChar, paramName, momentValue) {
                return momentValue ? firstChar + paramName + '=' + momentValue.toISOString() : '';
            }

            // Converts a list of items into a combined string of url query parameters
            function buildListAsQueryParams(list, firstChar, paramName, getParamValue) {
                var result = list
                        && list.length
                        && list.map(function(i) {
                            return paramName + '=' + (getParamValue ? getParamValue(i) : i);
                        }).join('&');
                return result ? firstChar + result : '';
            }

            return {
                getUtcOffsetQueryParam: getUtcOffsetQueryParam,
                getMomentQueryParam: getMomentQueryParam,
                buildListAsQueryParams: buildListAsQueryParams
            };
        }]);

})(window.angular);
