(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('RequestAdobeSignCtrl', [
            '$scope', '$q', 'selectedDoc', '$modal', '$modalInstance', 'accountsService', 'employeesService', 'adobeSignService', 'documentsService', 'auth',
            function ($scope, $q, selectedDoc, $modal, $modalInstance, accountsService, employeesService, adobeSignService, documentsService, auth) {

                var steps = [
                    {
                        id: 0, // Initial Step with spinner
                        nextStep: function() { return $scope.adobeSignUserStatus === 'active' ? 2 : 1; }
                    },
                    {
                        id: 1, // Adobe Sign User check step
                        confirmText: function() { return $scope.adobeSignUserStatus === 'notFound' ? 'Create Adobe Sign Account' : 'Check Account Status Again'; },
                        nextStep: function() { return $scope.adobeSignUserStatus === 'active' ? 2 : 1; }
                    },
                    {
                        id: 2, // Choose users step
                        confirmText: function() { return $scope.advancedSetup ? 'Next' : 'Confirm'; },
                        confirmDisabled: function() { return !$scope.selectedAccounts.length && !$scope.selectedEmployees.length; }
                    }
                ];

                init();
                var allAccounts = [];
                var allEmployees = [];

                $scope.errorMsg = '';
                $scope.currentStep = 0;
                $scope.confirmText = null;
                $scope.confirmInProgress = false;
                $scope.document = selectedDoc;
                $scope.title = selectedDoc.Title;
                $scope.adobeSignUserStatus = null;
                $scope.selectedAccounts = [];
                $scope.selectedEmployees = [];
                $scope.advancedSetup = false;
                $scope.agreementId = null;

                $scope.onSearchAccounts = function(q) {
                    return onSearch(allAccounts, q);
                };

                $scope.onSearchEmployees = function(q) {
                    return onSearch(allEmployees, q);
                };

                $scope.confirm = function() {
                    $scope.confirmInProgress = true;
                    confirmStep()
                        .finally(function() {
                            $scope.confirmInProgress = false;
                        });
                };

                function confirmStep() {
                    clearError();
                    switch ($scope.currentStep) {
                    case 1:
                        if ($scope.adobeSignUserStatus === 'notFound') {
                            return adobeSignService.createUser()
                                .then(updateAdobeSignUserStatus)
                                .then(function() {
                                    goToNextStep();
                                })
                                .catch(function(err) {
                                    var errMessage = 'An Adobe Sign Account could not be created. Please contact your system administrator.';
                                    if (err && err.data && err.data.Message) {
                                        errMessage = err.data.Message;
                                        if (errMessage.indexOf('Please contact') < 0) {
                                            errMessage += ' Please contact your system administrator.';
                                        }
                                    }
                                    showError(errMessage);
                                    return $q.reject(err);
                                });
                        }

                        return updateAdobeSignUserStatus()
                            .then(function() {
                                goToNextStep();
                            });
                    case 2:
                        if (!$scope.selectedAccounts.length && !$scope.selectedEmployees.length) {
                            showError('You must select at least one Account or Employee');
                            return $q.reject();
                        }

                        if (!$scope.selectedAccounts.length && $scope.selectedAccounts.length > 15) {
                            showError('You can not select more than 15 Account signatories');
                            return $q.reject();
                        }

                        if (!$scope.selectedEmployees.length && $scope.selectedEmployees.length > 10) {
                            showError('You can not select more than 10 Employee signatories');
                            return $q.reject();
                        }

                        var accountIds = $scope.selectedAccounts.map(function(a) { return a.Id; });
                        var employeeIds = $scope.selectedEmployees.map(function (e) { return e.Id; });

                        return adobeSignService.createAgreement(selectedDoc.Id, accountIds, employeeIds, $scope.advancedSetup)
                            .then(function(data) {
                                if (data != null && data.NextUrl != null && data.NextUrl.length > 0) {

                                    $modal.open({
                                        template: require('Documents\\AdobeSign\\CompleteAdobeSecureSign.html'),
                                        controller: 'CompleteAdobeSecureSignCtrl',
                                        resolve: {
                                            selectedDoc: function () {
                                                return $scope.document;
                                            },
                                            url: function () {
                                                return data.NextUrl;
                                            }
                                        },
                                        size: 'lg',
                                        backdrop: 'static'
                                    }).result
                                    .then(function (result) {
                                        if (result != null && result.reason === 'finished' && result.agreementId != null && result.agreementId.length > 0) {
                                            $scope.agreementId = result.agreementId;
                                        }
                                        $modalInstance.close({ reason: 'finished', agreementId: $scope.agreementId });
                                    })
                                    .catch(function (err) {
                                        if (err != null && err.reason === 'cancel') {
                                            documentsService.removeTask($scope.document.Id)
                                                .then(function (result) {
                                                    if (result.Message === "An error has occurred.") {
                                                        showError('The task was cancelled by the user but could not be deleted. Please contact your system administrator.');
                                                        return $q.reject(err);
                                                    } else {
                                                        $modalInstance.dismiss({ reason: 'cancel', agreementId: null });
                                                    }
                                                });
                                        } else {
                                            $modalInstance.dismiss({ reason: 'cancel', agreementId: null });
                                        }
                                    });
                                }
                                else
                                {
                                    if (data != null && data.AgreementId != null && data.AgreementId.length > 0) {
                                        $scope.agreementId = data.AgreementId;
                                    }
                                    $modalInstance.close({ reason: 'finished', agreementId: $scope.agreementId });
                                }
                            })
                            .catch(function(err) {
                                showError('The document could not be setup for signing. Please contact your system administrator.');
                                return $q.reject(err);
                            });
                    }

                    return $q.reject();
                }

                $scope.cancel = function () {
                    $modalInstance.dismiss({ reason: 'cancel', agreementId: null });
                };

                function init() {

                    updateAdobeSignUserStatus()
                        .then(function() {
                            goToNextStep();
                        });

                    accountsService.getSignatories(selectedDoc.Client.Id)
                        .then(function (result) {
                            allAccounts = result.map(toIdDescrItem);
                        });

                    employeesService.getSignatories(selectedDoc.Client.Id)
                        .then(function (result) {
                            allEmployees = result.map(toIdDescrItem);
                        });
                }

                function updateAdobeSignUserStatus() {
                    return adobeSignService.getCurrentUser()
                        .then(function(data) {
                            $scope.adobeSignUserStatus = data.Status;
                        })
                        .catch(function (err) {
                            var errMessage = 'The status of your Adobe Sign Account could not be checked. Please contact your system administrator.';
                            if (err && err.data && err.data.Message) {
                                errMessage = err.data.Message;
                                if (errMessage.indexOf('Please contact') < 0) {
                                    errMessage += ' Please contact your system administrator.';
                                }
                            }
                            showError(errMessage);
                        });
                }

                function goToNextStep() {
                    var currConfig = steps.filter(function(s) { return s.id === $scope.currentStep; })[0];

                    $scope.currentStep = currConfig.nextStep ? currConfig.nextStep() : $scope.currentStep + 1;

                    var newConfig = steps.filter(function(s) { return s.id === $scope.currentStep; })[0];
                    $scope.confirmText = newConfig.confirmText;
                    $scope.confirmDisabled = newConfig.confirmDisabled;
                }

                function showError(message) {
                    $scope.errorMsg = message;
                };

                function clearError() {
                    $scope.errorMsg = null;
                };

                function onSearch(users, q) {
                    return users.filter(function(acct) {
                        return acct.Description.toUpperCase().indexOf(q.toUpperCase()) !== -1;
                    });
                }

                function toIdDescrItem(acct) {
                    acct.Description = acct.FullName + ' (' + acct.Email + ')';
                    return acct;
                }
            }
        ]);
})(window.angular);
