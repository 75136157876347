(function() {
    'use strict';

    var module = angular.module('bf.services.filters', []);

    module.service('filtersService', [
        '$q', '_', 'dataStoreService', 'ENVIRONMENTAL', function ($q, _, dataStoreService, ENVIRONMENTAL) {
            var apiSection = "/filters/";

            function localGet() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection);                
            }

            function addFilter(text, parentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(text)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection, { text: text, parentId: parentId });
                }
                deffered.reject();
                return deffered.promise;
            }

            function removeFilter(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.deleteResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
                }
                deffered.reject();
                return deffered.promise;
            }

            function updateFilter(id, text) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id, { text: text });
                }
                deffered.reject();
                return deffered.promise;
            }

            //function addFileLocation(newFileLocation, selection) {
            //    var itemToStore = {};
            //    var deffered = $q.defer();
            //    localGet().then(function (data) {
            //        fileLocations = data;
            //    if (!_.isUndefined(newFileLocation)) {
            //        if (validateFileLocationModel(newFileLocation)) {
            //            if (!_.isUndefined(selection)) {
            //                itemToStore = { text: newFileLocation, parentId: selection };
            //                //iterate(fileLocations, newFileLocation, selection);
            //                dataStoreService.addFiltersInStore(itemToStore).then (function() {
            //                    deffered.resolve(true);
            //                });
            //            } else {
            //                itemToStore = { text: newFileLocation };
            //                dataStoreService.addFiltersInStore(itemToStore).then(function () {
            //                    deffered.resolve(true);
            //                });
            //                //fileLocations.push({ text: newFileLocation });
            //            }
            //            //console.log(fileLocations);
            //        }
            //        //deffered.resolve(true);
            //    } else {
            //        deffered.reject();
            //    }
            //    });
            //    return deffered.promise;
            //}

            //function removeFileLocation(selection, id) {
            //    var deffered = $q.defer();
            //    if (!_.isUndefined(selection)) {
            //        //deliterate(fileLocations, selection);
            //        dataStoreService.removeFiltersFromStore(id).then(function () {
            //            deffered.resolve(true);
            //        });
            //    } else {
            //        deferred.reject();
            //    }
            //    return deffered.promise;
            //}

            //function updateFileLocation(result, id) {
            //    var deffered = $q.defer();
            //    if (!_.isUndefined(result)) {
            //        if (validateFileLocationModel(result)) {
            //            //edititerate(fileLocations, result, newFileLocationDetails);
            //            dataStoreService.updateFiltersInStore(result, id).then(function (data) {
            //                deffered.resolve(data);
            //            });
            //        } else {
            //            deffered.reject();
            //        }
            //    } else {
            //        deffered.reject();
            //    }
            //    return deffered.promise;
            //}

            function expandFileLocation(resultToExpand) {
                var deffered = $q.defer();
                if (!_.isUndefined(resultToExpand)) {
                    toExpand(fileLocations, resultToExpand);
                    console.log(locationsToExpand);
                    deffered.resolve(locationsToExpand);
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            }

            function listToExpand() {
                var deffered = $q.defer();
                deffered.resolve(locationsToExpand);

                return deffered.promise;
            }

            //// For adding - called from click listener to find the appropriate section of the array to push entries
            //var iterate = function doRecusive(array, result, selection) {
            //    var deffered = $q.defer();
            //    if (typeof (array) == "object") {
            //        for (var i = 0; i < array.length; i++) {
            //            if (array[i].text == selection) {
            //                var x = { text: result };

            //                if (typeof (array[i].items) == "object") {
            //                    array[i].items.push(x);
            //                    //return array[i];
            //                    deffered.resolve(array[i]);
            //                } else {
            //                    var spliceObj = { text: array[i].text, items: [x] };
            //                    array.splice(i, 1, spliceObj);
            //                    //return array[i];
            //                    deffered.resolve(array[i]);
            //                }
            //            } else {
            //                if (typeof (array[i].items) == "object") {
            //                    iterate(array[i].items, result, selection);
            //                }
            //            }
            //        }
            //    }
            //    return deffered.promise;
            //}

            //// For deleting - called from click listener to find the appropriate section of the array to delete entries
            //var deliterate = function delRecusive(array, selection) {
            //    if (typeof (array) == "object") {
            //        for (var i = 0; i < array.length; i++) {
            //            if (array[i].text == selection) {
            //                if (typeof (array[i].items) == "object") {
            //                    array.splice(i, 1);
            //                    return array[i];
            //                } else {
            //                    array.splice(i, 1);
            //                    return array[i];
            //                }
            //            } else {
            //                if (typeof (array[i].items) == "object") {
            //                    deliterate(array[i].items, selection);
            //                }
            //            }
            //        }
            //    }
            //}

            //// For editing - called from click listener to find the appropriate section of the array to edit entries
            //var edititerate = function editRecusive(array, result, selection) {
            //    if (typeof (array) == "object") {
            //        for (var i = 0; i < array.length; i++) {
            //            if (array[i].text == selection) {
            //                if (typeof (array[i].items) == "object") {

            //                    array[i].text = result;

            //                    return array[i];
            //                } else {
            //                    array[i].text = result;
            //                    return array[i];
            //                }
            //            } else {
            //                if (typeof (array[i].items) == "object") {
            //                    edititerate(array[i].items, result, selection);
            //                }
            //            }
            //        }
            //    }
            //}



            //// For expanding
            //var toExpand = function toLoop(array, selection) {
            //        if (typeof (array) == "object") {
            //            for (var i = 0; i < array.length; i++) {
            //                if (array[i].text == selection) {
            //                    locationsToExpand.push(array[i].text);
            //                    toExpandParents(array[i].text);
            //                } else {
            //                    if (typeof (array[i].items) == "object") {
            //                        toExpand(array[i].items, selection);
            //                    }
            //                }
            //            }
            //        }
            //}

            //// Recursively go back up the array for parent nodes
            //var toExpandParents = function toLoopParent(array, selection) {
            //    if (typeof (array) == "object") {
            //        for (var i = 0; i < array.length; i++) {
            //            if (array[i].text == selection) {
            //                locationsToExpand.unshift(array[i].text);
            //                return true;
            //            } else {

            //                for (var j = 0; j < array.items.length; i++) {
            //                    if (array[i].items[j].text == selection) {
            //                        locationsToExpand.unshift(array[i].items[j].text);
            //                        toExpandParents(locationsToExpand, array[i].items[j].text);
            //                    } else {
            //                        if (typeof (array[i].items[j]) == "object") {
            //                            toExpandParents(array[i].items, selection);
            //                        }
            //                    }
            //                }
            //            }
            //        }
            //    }
            //}        

            


            //function getFileLocationById(id) {
            //    return _.find(getDummyFileLocations(), function (fileLocation) { return fileLocation.id === id; });
            //}


            //function validateFileLocationModel(fileLocationToValidate) {
            //    return true;
            //}

            
            return {
                get: localGet,
                remove: removeFilter, //FileLocation,
                add: addFilter, //FileLocation,
                update: updateFilter//,FileLocation,
                //expand: expandFileLocation,
                //getListToExpand : listToExpand
            }
        }
    ]);

})();

