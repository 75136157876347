angular.module("app")
    .controller("AcceptInviteCtrl",
        [
            "$scope", "$location", "$window", "employeesService", "_", "$state", "$q",
            function($scope, $location, $window, employeesService, _, $state, $q) {
                $scope.showPassword = false;
                $scope.showConfirmPassword = false;
                $scope.satisfied = false;
                $scope.expired = false;
                $scope.expiredWarning =
                    "The invitation you are trying to accept has expired, click here to have a new one sent to your corresponding email address.";
                $scope.inviteSent = false;
                $scope.initialLoading = true;
                $scope.notFound = false;
                $scope.notFoundWarning = "Sorry, the invitation you are trying to accept was not found on the system.";
                $scope.submitInProgress = false;
                $scope.userAlreadyExists = false;
                $scope.existingUserEmail = '';

                $scope.register = function (form) {
                    $scope.submitInProgress = true;
                    if (_.isUndefined($state.params.inviteToken)) {
                        alert("You have not been authorized to create an account.");
                        $state.go("login");
                        $scope.submitInProgress = false;
                    } else {
                        if ($scope.password == $scope.confirmPassword) {
                            $scope.progress.start();
                            employeesService.acceptInvite($state.params.inviteToken, $scope.password).then(
                                function(data) {
                                    if (data.Message == "false" || data.Message == "An error has occurred.") {
                                        alert("An error occurred on the server while trying to create your account, please try again. If the error persists please contact support.");
                                        $scope.submitInProgress = false;
                                    } else if (data.Message == "expired") {
                                        alert("The invite token you are using has expired, please contact your firm administrator to get a new one.");
                                        $scope.submitInProgress = false;
                                    } else {
                                        $scope.submitInProgress = false;
                                        $scope.$parent.showNotification("Success",
                                            "A new invite has been sent to your email, please check your inbox",
                                            "success");
                                        $scope.progress.complete();
                                    }
                                })
                                .catch(function(err) {
                                    if(err === 400) {
                                        alert("The invite token you are using has expired, please contact your firm administrator to get a new one.");
                                        $scope.submitInProgress = false;
                                    }
                                });
                        } else {
                            alert("Passwords do not match.");
                            $scope.submitInProgress = false;
                        }
                    }
                }

                $scope.resend = function() {
                    employeesService.resendInviteByToken($state.params.inviteToken).then(function(data) {
                            $scope.inviteSent = true;
                        },
                        function(err) {
                            $scope.inviteSend = false;
                        });
                }

                var activate = function() {
                    if (!_.isUndefined($state.params.inviteToken)) {
                        employeesService.loadInvite($state.params.inviteToken).then(function(data) {
                            $scope.initialLoading = false;
                            $scope.isLoading = false;
                            if(data.userAlreadyExists)
                            {
                                $scope.userAlreadyExists = data.userAlreadyExists;
                                $scope.existingUserEmail = data.existingUserEmail;
                            }
                            $scope.progress.complete();
                        })
                        .catch(function(error) {
                            if(error === 404) {
                                $scope.notFound = true;
                            } else if(error === 400) {
                                $scope.expired = true;
                            }
                            $scope.initialLoading = false;
                            $scope.isLoading = false;
                            $scope.progress.complete();
                        });
                    }
                }

                activate();

            }
        ]);