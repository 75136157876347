const $humanize = require('humanize-plus');

(function () {
    "use strict";

    angular.module("bf.components.upload", ["bf.constants"]);

    angular.module("bf.components.upload")
        .service("uploadService", [
            "constants", "_", "events", "$timeout", "$q", "$http", "ENVIRONMENTAL", "dataStoreService", "logService", function (constants, _, events, $timeout, $q, $http, ENVIRONMENTAL, dataStoreService, logService) {

                var filesToUpload = [];

                var initNewFileWithDefaults = function(file) {
                    file.uploadComplete = false;
                    file.humanizedData = {
                        'name': $humanize.truncate(file.name, 46, "..."),
                        'size': $humanize.fileSize(file.size)
                    }
                    file.retrying = false;
                    file.uploadProgress = 0;
                    file.uploadComplete = false;
                    file.errored = false;
                    file.aborted = false;
                    return file;
                }

                var localUpdateFileProgress = function(fileId, newProgress) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file) && !file.errored) {
                        file.uploadProgress = newProgress;
                        file.retrying = false;
                        events.trigger(constants.UPLOADING.EVENTS.PROGRESSCHANGE, file);
                    }
                }

                var localFileErrored = function(fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        if (_.isUndefined(file.failureReason)) {
                            file.failureReason = "An error occurred, please try again.";
                        }
                        file = setFileStateToErrored(file);
                        events.trigger(constants.UPLOADING.EVENTS.ERRORED, file);
                        logService.error({ File: { Title: file.name, Identifier: file.Identifier, Size: file.size } }, "Upload failed");
                    }
                }

                var setFileStateToErrored = function(file) {
                    file.errored = true;
                    file.retrying = false;
                    file.uploadComplete = false;
                    return file;
                }

                var localFileUploadComplete = function(fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.uploadComplete = true;
                        events.trigger(constants.UPLOADING.EVENTS.COMPLETE, file);
                    }
                }

                var localFileUploadRetring = function(fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.retrying = true;
                        events.trigger(constants.UPLOADING.EVENTS.RETRYING, file);
                    }
                }

                var localFileUploadAborted = function (fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.aborted = true;
                        events.trigger(constants.UPLOADING.EVENTS.ABORTED, file);
                    }
                }

                var localAddFile = function(file, index) {
                    var deffered = $q.defer();

                    var addedFileId = 0;

                    if (filesToUpload && filesToUpload.length > 0) {
                        addedFileId = filesToUpload.length;
                    }
                    if (!_.isUndefined(index)) {
                        addedFileId += index;
                    }

                    file.Id = addedFileId;

                    file = initNewFileWithDefaults(file);

                    events.trigger(constants.UPLOADING.EVENTS.FILEADDED, file);

                    getFileIdentifier().then(function(fileIdentifier) {
                        if (!_.isUndefined(fileIdentifier) && !_.isNull(fileIdentifier) && !_.isEmpty(fileIdentifier)) {
                            file.Identifier = fileIdentifier;
                            file.uniqueIdentifier = file.Identifier;
                            filesToUpload[addedFileId] = file;
                            deffered.resolve(addedFileId);
                        } else {
                            file = setFileStateToErrored(file);
                            deffered.reject("Issue getting file identifier from server");
                        }
                    });
                    return deffered.promise;
                }

                var getFileIdentifier = function() {
                    var deffered = $q.defer();
                    $http({
                        url: ENVIRONMENTAL.FILEAPI.BASEURL + "/api/upload/GetNewIdentifier",
                        method: "GET"
                    }).success(function(data, status, headers, config) {
                        deffered.resolve(data.replace(/"/g, ""));
                    }).error(function(data, status, headers, config) {
                        deffered.reject(status);
                    });
                    return deffered.promise;
                }

                var localAddFiles = function (files) {
                    var localAddFilePromises = [];
                    var deffered = $q.defer();

                    _.each(files, function(file, index) {
                       localAddFilePromises.push(localAddFile(file, index));
                    });

                    $q.all(localAddFilePromises).then(function(allFilesIds) {
                        deffered.resolve(allFilesIds);
                    });
                    return deffered.promise;
                }

                var localGetFile = function(fileId) {
                    var file = filesToUpload[fileId];
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        return file;
                    }
                    return undefined;
                }

                var localGetFiles = function() {
                    if (!_.isUndefined(filesToUpload) && !_.isNull(filesToUpload)) {
                        return filesToUpload;
                    }
                    return undefined;
                }

                var localStartFileUpload = function(fileId, dataToSendWithFile) {
                    var file = filesToUpload[fileId];
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        if (file.size === 0) {
                            file.failureReason = "Empty files are not valid, please upload a file with content.";
                            localFileErrored(file.Id);
                            return;
                        }

                        if (file.name.indexOf(".") === -1) {
                            file.failureReason = "Files without an extension are not valid, please upload a file with an extension.";
                            localFileErrored(file.Id);
                            return;
                        }

                        if (!_.isUndefined(file.uploadComplete) && !_.isNull(file.uploadComplete) && file.uploadComplete !== true) {
                            if (_.isUndefined(dataToSendWithFile) || _.isNull(dataToSendWithFile)) {
                                dataToSendWithFile = {};
                            }

                            dataToSendWithFile.fileId = file.Identifier;
                            file.uniqueIdentifier = file.Identifier;
                            file.uploadProgress = 0;
                            file.resume();
                        }
                    }
                }

                var localStartAllUploads = function(dataToSendWithFile) {
                    _.each(filesToUpload, function(file) {
                        localStartFileUpload(file.Id, dataToSendWithFile);
                    });
                }

                var localAbort = function(fileId) {
                    var file = filesToUpload[fileId];
                    if (!_.isUndefined(file) && !_.isNull(file)) {
                        file.cancel();
                        localFileUploadAborted(fileId);
                    }
                }

                var localAbortAll = function() {
                    if (filesToUpload && filesToUpload.length > 0) {
                        _.each(filesToUpload, function(file) {
                            file.cancel();
                            localFileUploadAborted(fileId);
                        });
                    }
                }

                var localCommitFile = function(fileId) {
                    var file = localGetFile(fileId);
                    if (!_.isUndefined(file) && !_.isNull(file) && !file.errored) {
                        return dataStoreService.updateResource(ENVIRONMENTAL.FILEAPI.BASEURL + "/api/upload/uploadchunks/commit", { fileId: file.Identifier, originalFileName: file.name });
                    }
                }

                return {
                    addFile: localAddFile,
                    addFiles: localAddFiles,
                    getFile: localGetFile,
                    getFiles: localGetFiles,
                    startFileUpload: localStartFileUpload,
                    startAllUploads: localStartAllUploads,
                    abort: localAbort,
                    abortAll: localAbortAll,
                    retrying: localFileUploadRetring,
                    completed: localFileUploadComplete,
                    updateProgress: localUpdateFileProgress,
                    errored: localFileErrored,
                    commitFile: localCommitFile
                }
            }
        ])
        .directive("uploadList", [
            "constants", "_", "events", "$timeout", "uploadService", function(constants, _, events, $timeout, uploadService) {

                var evtUploadStart = angular.noop;
                var evtFileAdded = angular.noop;
                var evtFileComplete = angular.noop;
                var evtGettingFileIdentifier = angular.noop;
                var evtFileStatusChanged = angular.noop;
                var evtLogout = angular.noop;
                var uploadListVisibilityHidden = "none";
                var uploadListVisibilityShown = "block";

                return {
                    restrict: "E",
                    template: require('Site\\Components\\Views\\uploadList.html'),
                    replace: true,
                    link: function($scope, element, attrs) {

                        var uploadListPosition = -296;
                        var uploadListHiddenPosition = -296;
                        var uploadListMinimisedPosition = -274;
                        var uploadListVisiblePosition = 1;
                        $scope.filesBeingUploaded = [];

                        $scope.cancelFileUpload = function(fileId) {
                            if (!_.isUndefined(fileId) && !_.isNull(fileId)) {
                                uploadService.abort(fileId);
                            }
                        }

                        $scope.minimiseUploadList = function() {
                            hideUploadList();
                        }

                        $scope.closeUploadList = function() {
                            setUploadListPosition(uploadListHiddenPosition);
                            setUploadListVisibility(uploadListVisibilityHidden);
                        }

                        $scope.expandUploadList = function() {
                            showUploadList();
                        }

                        $scope.uploadListProgressColumnWidth = function(file) {
                            return file.uploadProgress < 100 ? "25%" : "75%";
                        }

                        var hideUploadList = function() {
                            var currentPosition = getUploadListPosition();
                            if (currentPosition >= uploadListMinimisedPosition) {
                                setUploadListPosition(currentPosition - 20);
                                $timeout(hideUploadList, 100);
                            }
                            $scope.uploadListVisible = false;
                            $scope.uploadListMinimised = true;
                            return;
                        }

                        var showUploadList = function () {
                            var currentPosition = getUploadListPosition();
                            $scope.uploadListVisible = true;
                            $scope.uploadListMinimised = false;
                            if (currentPosition <= uploadListVisiblePosition) {
                                setUploadListPosition(currentPosition + 20);
                                $timeout(showUploadList, 100);
                            }
                            return;
                        }

                        var getUploadListPosition = function() {
                            return uploadListPosition;
                        }

                        var setUploadListPosition = function(newPosition) {
                            uploadListPosition = newPosition;
                            $scope.uploadListPositionInPx = newPosition + "px";
                        }

                        var setUploadListVisibility = function(visibility) {
                            $scope.uploadListVisibility = visibility;
                        }

                        evtUploadStart = events.on(constants.UPLOADING.EVENTS.START, function(evt, data) {
                            setUploadListVisibility(uploadListVisibilityShown);
                            showUploadList();
                        });

                        evtGettingFileIdentifier = events.on(constants.UPLOADING.EVENTS.GETTINGFILEIDENTIFIER, function(evt, file) {
                            setUploadListVisibility(uploadListVisibilityShown);
                            showUploadList();
                        });

                        evtFileAdded = events.on(constants.UPLOADING.EVENTS.FILEADDED, function(evt, file) {
                            if (!_.isUndefined(file)) {
                                $scope.filesBeingUploaded.push(file);
                            }
                            setUploadListVisibility(uploadListVisibilityShown);
                            showUploadList();
                        });

                        evtFileStatusChanged = events.on(constants.UPLOADING.EVENTS.FILESTATECHANGED, function(evt, file) {
                            // Update the upload list
                            setUploadListVisibility(uploadListVisibilityShown);
                            showUploadList();
                        });

                        evtLogout = events.on(constants.SECURITY.EVENTS.LOGGEDOUT, function(evt, data) {
                            $scope.closeUploadList();
                        });

                        evtFileComplete = events.on(constants.UPLOADING.EVENTS.COMPLETE, function(evt, file) {
                            var filesBeingUploaded = uploadService.getFiles();
                            var canMinimiseUploadList = true;
                            if (!_.isUndefined(filesBeingUploaded)) {
                                _.each(filesBeingUploaded, function(file) {
                                    if (file.uploadComplete === false) {
                                        canMinimiseUploadList = false;
                                    }
                                });
                            }
                            if (canMinimiseUploadList) {
                                $scope.minimiseUploadList();
                            }
                        });

                        $scope.$on("$destroy", function() {
                            unsubscribeAllEvents();
                        });
                        // Set the list hidden a start up
                        setUploadListVisibility(uploadListVisibilityHidden);

                    }
                };

                function unsubscribeAllEvents() {
                    evtUploadStart();
                    evtFileAdded();
                    evtFileComplete();
                    evtGettingFileIdentifier();
                    evtFileStatusChanged();
                    evtLogout();
                }

            }
        ]);

})();