// This is loosely based on this as an adaptation to AngularJS:
// https://github.com/lgarron/clipboard-polyfill/blob/master/clipboard-polyfill.ts
// https://github.com/lgarron/clipboard-polyfill

(function (angular) {
    "use strict";
    var module = angular.module("bf.services.clipboard", []);

    module.service("clipboardService",
        [
            "$q", "logService", function ($q, logService) {
                var plainTextType = "text/plain";
                var htmlTextType = "text/html";

                function setClipboardData(plainText, htmlText) {
                    return $q(function (resolve, reject) {
                        if (!plainText) {
                            logService.error(error, "clipboardService.setClipboardData called with blank or invalid plain text");
                            reject();
                            return;
                        }

                        if (seemToBeInIe()) {
                            if (writeIe(plainText)) {
                                resolve();
                            } else {
                                logService.error(error, "clipboardService.setClipboardData failed");
                                reject();
                            }
                            return;
                        }

                        var tracker = execCopy(plainText, htmlText);
                        if (tracker.success) {
                            resolve();
                            return;
                        }

                        // Success detection on Edge is not possible, due to bugs in all 4
                        // detection mechanisms we could try to use. Assume success.
                        if (navigator.userAgent.indexOf("Edge") > -1) {
                            resolve();
                            return;
                        }

                        // Fallback 1 for desktop Safari.
                        tracker = copyUsingTempSelection(document.body, plainText, htmlText);
                        if (tracker.success) {
                            resolve();
                            return;
                        }

                        // Fallback 2 for desktop Safari.
                        tracker = copyUsingTempElem(plainText, htmlText);
                        if (tracker.success) {
                            resolve();
                            return;
                        }

                        // Fallback for iOS Safari.
                        if (copyTextUsingDom(plainText)) {
                            resolve();
                            return;
                        }

                        logService.error(error, "clipboardService.setClipboardData failed after all attempts");
                        reject();
                    });
                }

                function seemToBeInIe() {
                    return window.clipboardData && window.clipboardData.setData;
                }

                function writeIe(plainText) {
                    if (plainText) {
                        return window.clipboardData.setData("Text", plainText);
                    }

                    return false;
                }

                function copyListener(tracker, plainText, htmlText, e) {
                    tracker.success = true;
                    e.clipboardData.setData(plainTextType, plainText);

                    if (e.clipboardData.getData(plainTextType) !== plainText) {
                        tracker.success = false;
                    }

                    if (htmlText) {
                        e.clipboardData.setData(htmlTextType, htmlText);
                    }

                    e.preventDefault();
                }

                function execCopy(plainText, htmlText) {
                    var tracker = {
                        success: false
                    };

                    var listener = copyListener.bind(this, tracker, plainText, htmlText);

                    document.addEventListener("copy", listener);
                    try {
                        document.execCommand("copy");
                    } finally {
                        document.removeEventListener("copy", listener);
                    }

                    return tracker;
                }

                function copyUsingTempSelection(element, plainText, htmlText) {
                    selectionSet(element);
                    var tracker = execCopy(plainText, htmlText);
                    selectionClear();
                    return tracker;
                }

                function selectionSet(element) {
                    var sel = document.getSelection();
                    var range = document.createRange();
                    range.selectNodeContents(element);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }

                function selectionClear() {
                    var sel = document.getSelection();
                    sel.removeAllRanges();
                }

                function copyUsingTempElem(plainText, htmlText) {
                    var tempElement = document.createElement("div");
                    // Place some text in the elem so that Safari has something to select.
                    tempElement.textContent = "temporary element";
                    document.body.appendChild(tempElement);

                    var tracker = copyUsingTempSelection(tempElement, plainText, htmlText);

                    document.body.removeChild(tempElement);
                    return tracker;
                }

                function copyTextUsingDom(plainText) {
                    var tempElement = document.createElement("div");
                    var shadowRoot = tempElement.attachShadow({ mode: "open" });
                    document.body.appendChild(tempElement);

                    var span = document.createElement("span");
                    span.innerText = plainText;
                    shadowRoot.appendChild(span);
                    selectionSet(span);

                    var result = document.execCommand("copy");

                    selectionClear();
                    document.body.removeChild(tempElement);

                    return result;
                }

                return {
                    setClipboardData: setClipboardData
                };
            }
        ]);
})(window.angular);