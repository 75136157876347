(function () {
    'use strict';

    var module = angular.module('bf.services.data', []);

    module.service('dataStoreService', [
        '$q', '_', '$http', 'constants', 'ENVIRONMENTAL', function ($q, _, $http, constants, ENVIRONMENTAL) {

            function getResourceData(uri) {
                var deffered = $q.defer();
                $http.get(uri)
                  .success(function (data, status, headers, config) {
                      deffered.resolve(data);
                  }).error(function (data, status, headers, config) {
                    deffered.reject(status);
                });
                return deffered.promise;
            }

            function getResourceDataWithErrorData(uri) {
                var deffered = $q.defer();
                $http.get(uri)
                    .success(function (data, status, headers, config) {
                        deffered.resolve(data);
                    }).error(function (data, status, headers, config) {
                        if (data.Message === undefined) {
                            deffered.reject(status);
                        }
                        deffered.reject(data);
                    });
                return deffered.promise;
            }

            function putResourceData(uri, theDataToStore) {
                var deffered = $q.defer();
                
                var dataToSend = JSON.stringify(theDataToStore);

                $http.put(uri, dataToSend, {
                    headers: { 'Content-Type': 'application/json', 'dataType': 'json' }
                }).success(function(data, status, headers, config) {
                    deffered.resolve(data);
                }).error(function (data, status, headers, config) {
                    deffered.reject(status);
                });
                return deffered.promise;
            }

            function postResourceData(uri, theDataToStore) {
                var deffered = $q.defer();

                var dataToSend = JSON.stringify(theDataToStore);

                $http.post(uri, dataToSend, {
                    headers: { 'Content-Type': 'application/json', 'dataType': 'json' }
                }).success(function (data, status, headers, config) {
                    deffered.resolve(data);
                }).error(function (data, status, headers, config) {
                    deffered.reject(status);
                });
                return deffered.promise;
            }

            function post(uri, theDataToStore) {
                var dataToSend = JSON.stringify(theDataToStore);

                return $http.post(uri, dataToSend, {
                    headers: { 'Content-Type': 'application/json', 'dataType': 'json' }
                });
            }

            function deleteResourceData(uri, theDataToRemove) {
                var deffered = $q.defer();

                var dataToSend = JSON.stringify(theDataToRemove);

                $http.delete(uri, dataToSend, {
                    headers: { 'Content-Type': 'application/json', 'dataType': 'json' }                    
                }).success(function (data, status, headers, config) {
                    deffered.resolve(data);
                }).error(function (data, status, headers, config) {
                    deffered.reject(status);
                });
                return deffered.promise;
            }

            return {
                getResource: getResourceData,
                getResourceWithErrorData: getResourceDataWithErrorData,
                addResource: putResourceData,
                deleteResource: deleteResourceData,
                updateResource: postResourceData,
                post: post
            };
        }
    ]);
})();