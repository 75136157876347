(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('SigningPrechecksCtrl', [
        '$scope', '$state', '$stateParams', '_', '$q', 'selectedDoc', 'documentsService', '$modal', '$modalInstance', 'signatureService', 'signingProcessType', '$timeout',
        function ($scope, $state, $stateParams, _, $q, selectedDoc, documentsService, $modal, $modalInstance, signatureService, signingProcessType, $timeout) {

            $scope.title = "Signing Requirements";
            $scope.loadingMessage = "Running some checks on your file for minimum signing requirements";
            $scope.failedChecksReason = "";
            $scope.error = false;
            $scope.errorMsg = "";
            $scope.cancelBtnText = "Close";
            $scope.deleteFileBtnText = "Delete file";
            $scope.confirmFileDeleteBtnText = "Yes I am sure";
            $scope.fileDeleteBtnText = "Delete file";
            $scope.showConfirmDeleteBtn = false;
            $scope.isLoading = true;
            var currentDocument = selectedDoc;
            var currentSigningProcessType = signingProcessType.toLowerCase();
            var signingProcessTypeNames = {clickToSign: 'clicktosign', secureSign: 'securesign'};
            
            var initiliseModal = function () {

                if (_.isUndefined(currentDocument) || _.isNull(currentDocument)) {
                    displayDocumentMissingError();
                }

                if (!isValidSigningProcessType(currentSigningProcessType)) {
                    displayUnknownSigningProcessError();
                }

                signatureService.verifyDocumentForSigning(currentDocument.Id)
                    .then(function (result) {
                        var promise;
                        if (currentSigningProcessType === signingProcessTypeNames.clickToSign) {
                            promise = openClickToSignInitialStep().result;
                        } else if (currentSigningProcessType === signingProcessTypeNames.secureSign) {
                            promise = openSecureSignInitialStep().result;
                        } else {
                            throw new Error("Unrecognised signing type");
                        }
                        return promise
                            .then(function () {
                                finishModal();
                            })
                            .catch(function () {
                                $scope.cancel();
                            });
                    })
                    .catch(function (error) {
                        $timeout(function () { displayChecksFailedErrorMessage(error); }, 500);
                    });
            }

            var isValidSigningProcessType = function(typeToCheck) {
                if (_.isUndefined(typeToCheck) || _.isNull(typeToCheck)) {
                    return false;
                }

                if (typeToCheck !== signingProcessTypeNames.clickToSign && typeToCheck !== signingProcessTypeNames.secureSign){
                    return false;
                }
                return true;
            }

            var openClickToSignInitialStep = function () {
                return $modal.open({
                    template: require('Documents\\ClickToSign\\RequestClickToSign.html'),
                    controller: 'RequestClickToSignCtrl',
                    resolve: {
                        selectedDoc: function () {
                            return currentDocument;
                        }
                    }
                });
            }

            var openSecureSignInitialStep = function() {
                return $modal.open({
                    template: require('Documents\\MultipleSignatures\\RequestMultipleSignaturesModal.html'),
                    controller: 'RequestMultipleSignaturesCtrl',
                    resolve: {
                        selectedDoc: function () {
                            return currentDocument;
                        }
                    }
                });
            }

            var displayChecksFailedErrorMessage = function (reason) {
                displayErrorMessage('Unfortunately the selected file didn\'t pass our checks.');
                displayChecksFailedReasonMessage(reason);
            }

            var displayChecksFailedReasonMessage = function(reason) {
                $scope.failedChecksReason = reason;
            }

            var displayUnknownSigningProcessError = function() {
                displayErrorMessage('There was an issue determining the signing process selected. Please close this messagte and try again');
            }

            var displayDocumentMissingError = function() {
                displayErrorMessage('There was an issue getting information about the document you wish to use. Please close this message and try again');
            }

            var displayErrorMessage = function(message) {
                $scope.errorMsg = message;
                $scope.error = true;
                $scope.isLoading = false;
            }
            
            var displayFailedToDeleteDocumentErrorMessage = function() {
                displayErrorMessage("Failed to delete document");
            }

            $scope.confirmFileDelete = function() {
                $scope.showConfirmDeleteBtn = true;
            }

            $scope.deleteFile = function () {
                $scope.loadingMessage = "Deleting document";
                $scope.isLoading = true;
                documentsService.remove(currentDocument.Id).then(function (result) {
                    finishModal();
                }, function (error) {
                    displayFailedToDeleteDocumentErrorMessage();
                });
            }

            function finishModal() {
                $modalInstance.close('finished');
            }

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            }

            initiliseModal();
        }
    ]);
})();
