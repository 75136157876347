(function () {
    'use strict';

    var module = angular.module('bf.services.audit', []);

    module.service('auditService', ['ENVIRONMENTAL', 'dataStoreService', function (ENVIRONMENTAL, dataStoreService) {

            var apiSection = '/audit/';

            function localGetDocumentAuditTrail(id) {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'document/' + id);
            }

            return {
                getDocumentAuditTrail: localGetDocumentAuditTrail
            }
        }
    ]);
})();