angular.module("app")

// Path: /Settings
    .controller("EmployeesCtrl", ["$scope", "employeesService", "featuresService", "adobeSignService", "constants", "$modal", "$q", "store", "_",
        function ($scope, employeesService, featuresService, adobeSignService, constants, $modal, $q, store, _) {

            var inactivateBtnLabel = "Inactivate";
            var activateBtnLabel = "Activate";

            var adminBtnLabel = "Demote to Employee";
            var employeeBtnLabel = "Promote to Admin";

            var setNotificationLabel = "Set as Default Recipient";
            var removeNotificationLabel = "Remove as Default Recipient";

            var selectedEmployee = null;

            $scope.defaultRecipientLabel = setNotificationLabel;
            $scope.roleBtnText = employeeBtnLabel;
            $scope.isDisabled = true;
            $scope.statusBtnText = inactivateBtnLabel;
            $scope.inviteDisabled = true;
            $scope.addAdobeDisabled = true;
            $scope.hasAdobeSignFeature = true;
            $scope.addButtonDisabled = true;

            featuresService.get().then(function(features) {
                    $scope.hasAdobeSignFeature = false;
                    var length = features.length >>> 0;
                    for (var i = 0; i < length; i++) {
                        if (features[i].Name === "AdobeSign" && features[i].IsEnabled) {
                            $scope.hasAdobeSignFeature = true;
                        }
                    }
                })
                .catch(function(err) {
                    $scope.hasAdobeSignFeature = false;
                })
                .finally(function() {
                    if (!$scope.hasAdobeSignFeature) {
                        var length = $scope.bfGridOptions.columns.length >>> 0;
                        for (var i = 0; i < length; i++) {
                            if ($scope.bfGridOptions.columns[i].field === "AdobeStatus") {
                                $scope.bfGridTriggers.removeColumn(i);
                            }
                        }
                    }
                });

            var shouldWePromptModal = function() {
                var shouldWePromptSubscriptionsModal = false;
                _.each(selectedEmployee.SubscribedClients,
                    function(object) {
                        _.each(object.SubscribedEmployees,
                            function(employee) {
                                if (employee.Id === selectedEmployee.Id && object.SubscribedEmployees.length == 1) {
                                    shouldWePromptSubscriptionsModal = true;
                                }
                            });
                    });
                return shouldWePromptSubscriptionsModal;
            };

            $scope.activateEmployee = function() {
                if (selectedEmployee.IsActive) {
                    var shouldWePromptSubscriptionsModal = shouldWePromptModal();
                    if (shouldWePromptSubscriptionsModal) {
                        selectedEmployee.shouldWePromptSubscriptionsModal = true;
                        var modalInstance =
                            createEmployeeUpdateModal(constants.EMPLOYEES.MODALMODES.ACTIVATE, selectedEmployee);
                        modalInstance.result.then(function(result) {
                            if (result.success) {
                                inActivate(result);
                            }
                        });
                    } else {
                        var obj = {};
                        obj.userId = selectedEmployee.Id;
                        obj.updatedSubscriptionsList = [];
                        inActivate(obj);
                    }
                } else {
                    employeesService.activate(selectedEmployee.Id).then(function(result) {
                        $scope.showNotification("Success", "Successfully activated employee.", "success");
                        refreshGrid();
                    });
                }
            };

            var inActivate = function(result) {
                if (!isActionAllowed()) {
                    return;
                }

                employeesService.inactivate(result.userId, result.updatedSubscriptionsList).then(function(result) {
                        if (result === "success") {
                            $scope.showNotification("Success", "Successfully inactivated employee.", "success");
                            refreshGrid();
                        } else {
                            $scope.showNotification("Unable to inactivate employee",
                                "Failed to inactivate employee, please try again",
                                "error");
                        }
                    },
                    function(error) {
                        $scope.showNotification("Unable to activate employee",
                            "Failed to activate employee, please try again",
                            "error");
                    });
            };

            var isActionAllowed = function() {
                if (_.isNull(store.get("profile")) || _.isUndefined(store.get("profile"))) {
                    $scope.showNotification("Error",
                        "Unable to read session details, please log out and back in.",
                        "error");
                    return false;
                }
                if (selectedEmployee.Id === store.get("profile").portalUserId) {
                    $scope.showNotification("Invalid Selection",
                        "You may not perform this action on your account",
                        "error");
                    return false;
                }
                return true;
            };

            $scope.deleteEmployee = function() {
                if (!isActionAllowed()) {
                    return;
                }
                var shouldWePromptSubscriptionsModal = shouldWePromptModal();
                if (shouldWePromptSubscriptionsModal) {
                    selectedEmployee.shouldWePromptSubscriptionsModal = true;
                }
                var modalInstance = createEmployeeUpdateModal(constants.EMPLOYEES.MODALMODES.DELETE, selectedEmployee);

                modalInstance.result.then(function(result) {
                    if (result.success) {
                        employeesService.remove(result.userId, result.updatedSubscriptionsList).then(function(result) {
                                if (result === "Success") {
                                    refreshGrid();
                                } else {
                                    var errorMessage = "Failed to delete employee, please try again";

                                    if (result && result.Message) {
                                        errorMessage = result.Message;
                                    }

                                    $scope.showNotification("Unable to delete employee", errorMessage, "error");
                                }
                            },
                            function(error) {
                                var errorMessage = "Failed to delete employee, please try again";

                                if (error && error.data && error.data.Message) {
                                    errorMessage = error.data.Message;
                                }

                                $scope.showNotification("Unable to delete employee", errorMessage, "error");
                            });
                    }
                });
            };

            $scope.updateRole = function() {
                if (!isActionAllowed()) {
                    return;
                }
                if (!selectedEmployee.IsAdmin) {
                    employeesService.promote(selectedEmployee.Id).then(function(result) {
                        if (result === "success") {
                            $scope.showNotification("Success", "Successfully updated employee roles", "success");
                            refreshGrid();
                        } else {
                            $scope.showNotification("Unable to update employee",
                                "Failed to update employee, please try again",
                                "error");
                        }
                    }), function(error) {
                        $scope.showNotification("Unable to update employee",
                            "Failed to update employee, please try again",
                            "error");
                    };
                } else {
                    employeesService.demote(selectedEmployee.Id).then(function(result) {
                        if (result === "success") {
                            $scope.showNotification("Success", "Successfully updated employee roles", "success");
                            refreshGrid();
                        } else {
                            $scope.showNotification("Unable to update employee",
                                "Failed to update employee, please try again",
                                "error");
                        }
                    }), function(error) {
                        $scope.showNotification("Unable to update employee",
                            "Failed to update employee, please try again",
                            "error");
                    };
                }
            };

            var createEmployeeUpdateModal = function(mode, dataToPass) {
                return $modal.open({
                    template: require('settings\\employeeUpdate.html'),
                    controller: "employeeUpdateCtrl",
                    resolve: {
                        actionMode: function() {
                            return mode;
                        },
                        passedData: function() {
                            return dataToPass;
                        }
                    },
                    backdrop: true
                });
            };

            $scope.addEmployee = function() {
                var modalInstance = createEmployeeUpdateModal(constants.EMPLOYEES.MODALMODES.CREATE, null);

                modalInstance.result.then(function(modalResult) {
                    if (modalResult.success) {
                        employeesService.addNew(modalResult.newUser).then(function(result) {
                            if (result.Message === "emailactive") {
                                $scope.showNotification("Unable to Add Employee",
                                    "The email already exists on the portal!",
                                    "error");
                            }
                            if (result === "success") {
                                $scope.showNotification("Employee Added!", "", "success");
                            }
                            refreshGrid();
                        }), function(err) {
                            if (result.Message === "emailactive") {
                                $scope.showNotification("Unable to Add Employee",
                                    "The email already exists on the portal!",
                                    "error");
                            } else {
                                $scope.showNotification("Unable to Add Employee",
                                    "Something went wrong on our server",
                                    "error");
                            }
                        };
                    }
                });
            };

            $scope.editEmployee = function() {
                var modalInstance = createEmployeeUpdateModal(constants.EMPLOYEES.MODALMODES.EDIT, selectedEmployee);

                modalInstance.result.then(function(result) {
                    if (result.success) {
                        employeesService.update(result.userId, result.usersNewDetails).then(function(result) {
                            refreshGrid();
                        });
                    } else {
                        $scope.showNotification("Unable to Edit Employee",
                            "Failed to save employee, please try again.",
                            "error");
                    }
                }), function(error) {
                        $scope.showNotification("Unable to Edit Employee",
                            "Failed to save employee, please try again.",
                            "error");
                };
            };

            $scope.updateDefaultRecipient = function() {
                if (!selectedEmployee.DefaultNotificationRecipient) {
                    employeesService.setDefaultRecipient(selectedEmployee.Id).then(function(result) {
                        if (result === "success") {
                            $scope.showNotification("Success",
                                "Successfully set employee as default notifications recipient.",
                                "success");
                            refreshGrid();
                        } else if (result.Message === "inactive") {
                            $scope.showNotification("Error",
                                "Employee must be active to be assigned as a default notification recipient.",
                                "error");
                        } else {
                            $scope.showNotification("Error", "Something went wrong, please try again.", "error");
                        }
                    });
                } else {
                    employeesService.removeDefaultRecipient(selectedEmployee.Id).then(function(result) {
                        if (result === "success") {
                            $scope.showNotification("Success",
                                "Successfully removed employee as default notifications recipient.",
                                "success");
                            refreshGrid();
                        } else {
                            $scope.showNotification("Error", "Something went wrong, please try again.", "error");
                        }
                    });
                }
            };

            $scope.resendInvite = function() {
                employeesService.resendInvite(selectedEmployee.Id).then(function(result) {
                        $scope.showNotification("Success", "Invitation has been sent", "success");
                        refreshGrid();
                    },
                    function(error) {
                        $scope.showNotification("Unable to send invitation",
                            "Failed to resend notification, please try again",
                            "error");
                    });
            };

            $scope.addAdobeSignEmployee = function() {
                $scope.progress.start();
                $scope.addAdobeDisabled = true;
                adobeSignService.createUser(selectedEmployee.Id)
                    .then(function(result) {
                        return refreshGrid();
                    })
                    .catch(function(err) {
                        $scope.showNotification("Unable to create Adobe Sign account",
                            "An Adobe Sign Account could not be created. Please contact your system administrator.",
                            "error");
                    })
                    .finally(function() {
                        $scope.progress.complete();
                    });
            };

            function refreshGrid() {
                selectedEmployee = null;
                $scope.isDisabled = true;
                $scope.progress.start();
                return $scope.bfGridTriggers.triggerRead();
            }

            $scope.bfGridTriggers = {};

            $scope.bfGridOptions = {
                dataSource: function(options) {
                    return employeesService.get(null, options.data.page, options.data.pageSize, options.data.sort)
                        .then(function(dataItem) {
                            // HACK: Failed calls can still arrive in a success promise (see CP-848) so reject them
                            return dataItem.Data ? dataItem : $q.reject(dataItem);
                        })
                        .finally(function() {
                            $scope.addButtonDisabled = false;
                            $scope.progress.complete();
                            $scope.isLoading = false;
                        });
                },
                multiple: false,
                fullScreen: true,
                columns: [
                    { field: "GivenName", title: "Given Name", width: "150px" },
                    { field: "Surname", title: "Surname", width: "150px" },
                    {
                        field: "Email",
                        title: "Email/Username",
                        template: '<a href="mailto:#: data.Email #">#: data.Email #</a>'
                    },
                    {
                        field: "IsActive",
                        title: "Active",
                        width: "75px",
                        headerAttributes: { "class": "grid-center" },
                        attributes: { "class": "grid-center" },
                        template: '# if(IsActive){# <span class="glyphicon glyphicon-ok"></span> #}#'
                    },
                    {
                        field: "IsAdmin",
                        title: "Administrator",
                        width: "120px",
                        headerAttributes: { "class": "grid-center" },
                        attributes: { "class": "grid-center" },
                        template: '# if(IsAdmin){# <span class="glyphicon glyphicon-ok"></span> #}#'
                    },
                    {
                        field: "DefaultNotificationRecipient",
                        title: "Email Recipient",
                        width: "120px",
                        headerAttributes: { "class": "grid-center" },
                        attributes: { "class": "grid-center" },
                        template:
                            '# if(DefaultNotificationRecipient){# <span class="glyphicon glyphicon-ok"></span> #}#'
                    },
                    {
                        field: "Signatory",
                        title: "Signatory",
                        width: "120px",
                        headerAttributes: { "class": "grid-center" },
                        attributes: { "class": "grid-center" },
                        template: '# if(Signatory){# <span class="glyphicon glyphicon-ok"></span> #}#'
                    },
                    {
                        field: "InviteStatusDescription",
                        title: "Invitation",
                        width: "100px",
                        headerAttributes: { "class": "grid-center" },
                        attributes: { "class": "grid-center" },
                        template: '#= InviteToken != null ? "" : "Accepted" #'
                    },
                    {
                        field: "AdobeStatus",
                        title: "Adobe Account Status",
                        width: "100px",
                        headerAttributes: { "class": "grid-center" },
                        attributes: { "class": "grid-center text-capitalize" },
                        template: '#= AdobeStatus == null ? "" : AdobeStatus #'
                    }
                ],
                schema: {
                    data: "Data",
                    total: "Total"
                }
            };

            $scope.onSelectedRowChange = function (rowItem) {
                selectedEmployee = rowItem;
                $scope.isDisabled = false;
                $scope.statusBtnText = rowItem.IsActive ? inactivateBtnLabel : activateBtnLabel;
                $scope.roleBtnText = rowItem.IsAdmin ? adminBtnLabel : employeeBtnLabel;
                $scope.defaultRecipientLabel = !rowItem.DefaultNotificationRecipient ? setNotificationLabel : removeNotificationLabel;
                $scope.inviteDisabled = !rowItem.InviteToken;
                $scope.addAdobeDisabled = rowItem.AdobeStatus === "active" || rowItem.AdobeStatus === "inactive" || rowItem.AdobeStatus === "new";
            };
        }
]);
