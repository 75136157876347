(function() {
    'use strict';

    angular.module('app')
        .controller('AccountClientDetailsCtrl', [
            '_', '$scope', '$modalInstance', 'actionMode', 'selection', 'constants', function(_, $scope, $modalInstance, actionMode, selection, constants) {

                $scope.cancelBtnText = 'Cancel';
                $scope.canEdit = true;
                $scope.toDisable = false;
                $scope.account = selection;
                $scope.clients = _.sortBy($scope.account.Clients, function (i) { return i.Name.toLowerCase(); });
                $scope.displayCancelBtn = true;

                var init = function() {
                    if ($scope.clients.length == 0) {
                        $scope.noClients = true;
                    }

                    switch (actionMode) {
                        case constants.ACCOUNTS.MODALMODES.VIEWCLIENTS:
                            $scope.title = "View Clients for: ";
                            $scope.confirmBtnText = 'Done';
                            $scope.displayCancelBtn = false;
                            break;
                        case constants.ACCOUNTS.MODALMODES.EDITCLIENTS:
                            $scope.title = "Edit Clients for: ";
                            $scope.confirmBtnText = 'Confirm';
                            $scope.remove = function (itemToRemove) {
                                var index = 0;
                                $scope.clients.forEach(function (data) {
                                    if (data.Id == itemToRemove.Id) {
                                        $scope.clients.splice(index, 1);
                                        return;
                                    }
                                    index++;
                                });
                                refreshGrid();
                            }

                            break;
                        default:
                            $scope.title = 'Opps';
                            $scope.message = 'Something didn\'t work out';
                            break;
                    }
                }
                init();
                var refreshGrid = function() {
                    $scope.modalGrid.dataSource.read();
                }

                $scope.modalGridOptions = {
                    dataSource: {
                        transport: {
                            read: function(options) {
                                options.success($scope.clients);
                            }
                        }
                    },
                    sortable: true,
                    selectable: false,
                    dataBound: function(e) {
                        switch (actionMode) {
                        case constants.ACCOUNTS.MODALMODES.VIEWCLIENTS:
                            var grid = e.sender;
                            if (!$scope.noClients) {
                                grid.columns.forEach(function(item) {
                                    if (item.title == "Remove") {
                                        grid.hideColumn(item);
                                    }
                                });
                            }
                        }
                    },
                    columns: [
                        { field: 'Name', title: 'Client' },
                        { field: 'ContactName', title: 'Contact' },
                        { title: 'Remove', width: '100px', headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, template: '<button class="btn btn-default btn-sm" ng-click="remove(this.dataItem)" value="X"><span class="glyphicon glyphicon-remove"></span></button>' }
                    ]
                };

                $scope.confirm = function() {
                    switch (actionMode) {
                    case constants.ACCOUNTS.MODALMODES.VIEWCLIENTS:
                        var returnVal = {};
                        returnVal.success = true;
                        break;
                    case constants.ACCOUNTS.MODALMODES.EDITCLIENTS:
                        var returnVal = {};
                        returnVal.success = true;
                        returnVal.accountId = $scope.account.Id;
                        returnVal.clients = _.map($scope.clients, function(x) { return x.Id; });
                        break;
                    }
                    $modalInstance.close(returnVal);
                }

                $scope.cancel = function() {
                    $modalInstance.dismiss('cancel');
                }

            }
        ]);
})();