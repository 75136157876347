(function () {
    "use strict";

    angular.module("app")
        .controller("ClientsCtrl", [
            "$scope", "$state", "$timeout", "constants", "$modal", "clientsService", "$q", "events", "accountsService", "featuresService", 'ENVIRONMENTAL', 'store', 'auth',
            function ($scope, $state, $timeout, constants, $modal, clientsService, $q, events, accountsService, featuresService, ENVIRONMENTAL, store, auth) {
                $scope.clientsToDisplay = true;
                $scope.emptySearch = false;
                $scope.isDisabled = true;
                $scope.searchTerm = "";
                $scope.searchForm = {};
                $scope.isClientChangeDisabled = true;
                $scope.isAccountInviteDisabled = true;

                if (store.get('profile').mfa_enabled === undefined) {
                    if (store.get('tokenAPI')) {
                        store.remove('tokenAPI');
                    }
                    if (store.get('tokenFiles')) {
                        store.remove('tokenFiles');
                    }
                    if (store.get('token')) {
                        store.remove('token');
                    }
                    if (store.get('profile')) {
                        store.remove('profile');
                    }
                    auth.isAuthenticated = false;
                    window.location.replace("https://" + ENVIRONMENTAL.AUTH0.DOMAIN + "/Manage/EnableAuthenticator?logoutOnComplete=true");
                }

            featuresService.isEnabled("ClientChangeEnabled").then(function(enabled) {
                    $scope.isClientChangeDisabled = !enabled;
                    
                    if (!enabled) {
                        featuresService.isEnabled("HowNowOnline").then(function(enabled) {
                            $scope.isAccountInviteDisabled = !enabled;
                        }).catch(function() {
                            $scope.isAccountInviteDisabled = true;
                        });
                    } else {
                        $scope.isAccountInviteDisabled = false;
                    }
                })
                .catch(function() {
                    $scope.isClientChangeDisabled = true;
                    $scope.isAccountInviteDisabled = true;
                });

            var selectedClients = [];

            $scope.viewClientDocuments = function () {
                $state.go("documents", { workingClientId: selectedClients[0].Id });
            }

            $scope.viewSubscriptions = function () {
                var modalInstance = createSubscriptionsModal();
                modalInstance.result.then(function (result) {
                    if (result) {
                        if (result == "cancel") {
                            return;
                        }
                        $scope.$parent.showNotification("Success", "The client's notification settings have been successfully updated", "success");
                    } else {
                        $scope.$parent.showNotification("Error", "Something went wrong please try again", "error");
                    }
                });
            }

            var createSubscriptionsModal = function () {
                return $modal.open({
                    template: require('Clients\\ClientSubscriptions.html'),
                    controller: "ClientSubscriptionsCtrl",
                    resolve: {
                        selectedClient: function () {
                            return selectedClients[0];
                        }
                    }
                });
            }

            $scope.deleteClient = function (sender) {
                var modalInstance = createEditClientsModal("delete", selectedClients[0]);
                modalInstance.result.then(function (result) {
                    if (result.success) {
                        $scope.progress.start();
                        clientsService.remove(result.clientId).then(function (result) {
                            refreshGrid();
                        });
                    }
                });
            }

            $scope.addClient = function () {
                var modalInstance = createEditClientsModal("create", "");
                modalInstance.result.then(function (result) {
                    if (result.success) {
                        $scope.progress.start();
                        clientsService.add(result.newClient).then(function (result) {
                            $scope.clientsToDisplay = false;
                            refreshGrid();
                        });
                    }
                });
            }

            $scope.editClient = function (sender) {
                var modalInstance = createEditClientsModal("edit", selectedClients[0]);
                modalInstance.result.then(function (result) {
                    if (result.success) {
                        $scope.progress.start();
                        clientsService.update(result.clientId, result.updatedClient).then(function (result) {
                            refreshGrid();
                        });
                    }
                });
            }

            $scope.inviteClients = function () {
                var modalInstance = createinviteClientsModal("Invite", selectedClients);
                modalInstance.result.then(function (result) {
                    if (result.success) {
                        $scope.progress.start();
                        accountsService.invite(result).then(function (result) {
                            if (result.Message == "emailactive") {
                                $scope.$parent.showNotification("Account already exists", "An account with the email provided already exists", "error");
                            }
                            refreshGrid();
                        });
                    }
                });
            }

            $scope.inviteExistingClients = function () {
                var modalInstance = createinviteClientsModal("InviteExisting", selectedClients);
                modalInstance.result.then(function (result) {
                    if (result.success) {
                        $scope.progress.start();
                        accountsService.assignClients(result).then(function (result) {
                            if (result.Message != "An error has occurred.") {
                                $scope.$parent.showNotification("Success!", "Operation Successful", "success");
                                refreshGrid();
                            } else {
                                $scope.$parent.showNotification("", "Failed to complete the operation, please try again.", "error");
                            }
                        });
                    }
                });
            }

            var createEditClientsModal = function (mode, data) {
                return $modal.open({
                    template: require('Clients\\ClientsDetailsModal.html'),
                    controller: "ClientsDetailsCtrl",
                    resolve: {
                        actionMode: function () {
                            return mode;
                        },
                        passedData: function () {
                            return data;
                        }
                    }
                });
            }

            var createinviteClientsModal = function (mode, selection) {
                return $modal.open({
                    template: require('Clients\\ClientsInviteModal.html'),
                    controller: "ClientsInviteCtrl",
                    resolve: {
                        actionMode: function () {
                            return mode;
                        },
                        selection: function () {
                            return selection;
                        }
                    }
                });
            }

            $scope.clearSearch = function () {
                $scope.searchForm.term = "";
                $scope.searchTerm = "";
                refreshGrid();
            }

            $scope.searchForm.handleSearch = function () {
                $scope.searchTerm = $scope.searchForm.term;
                refreshGrid();
            };

            function refreshGrid() {
                selectedClients = [];
                $scope.isDisabled = true;
                return $scope.bfGridTriggers.triggerRead();
            }

            $scope.bfGridTriggers = {};

            $scope.bfGridOptions = {
                dataSource: function (options) {
                    $scope.isLoading = true;
                    return clientsService.get($scope.searchTerm, options.data.page, options.data.pageSize, options.data.sort)
                        .then(function (dataItem) {
                            $scope.clientsToDisplay = $scope.searchTerm || (dataItem.Data && dataItem.Data.length > 0);
                            return dataItem;
                        })
                        .finally(function () {
                            $scope.progress.complete();
                            $scope.isLoading = false;
                        });
                },
                multiple: true,
                fullScreen: true,
                columns: [
                    { field: "Name", title: "Name" },
                    { field: "ContactName", title: "Contact" }
                ],
                schema: {
                    data: "Data",
                    total: "Total"
                }
            }

            $scope.onSelectedRowChange = function (rowItems) {
                $scope.isDisabled = false;
                selectedClients = rowItems;
            }
        }
        ]);
})();