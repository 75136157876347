(function(angular) {
    'use strict';

    angular
        .module('app')
        .controller('oAuthCtrl', [
            '$scope', '$http', '$window', '$q', 'ENVIRONMENTAL', function($scope, $http, $window, $q, env) {

                $scope.authenticate = function() {
                    $http.get(env.BASEURLS.API + '/api/adobesign/oauth')
                        .then(applyRequestFailureFix)
                        .then(function(resp) {
                            if(!resp.data.Location) {
                                return $q.reject({ statusText: 'Redirect url not found' });
                            }
                            return $window.location.href = resp.data.Location;
                        })
                        .catch(function(e) {
                            $scope.errorText = e.statusText || 'Unrecognised error';
                        });
                };

                function applyRequestFailureFix(resp) {
                    // Workaround to turn failed calls back into failed promises
                    return !resp || !resp.status || resp.status >= 400 ? $q.reject(resp) : resp;
                }
            }
        ]);
})(window.angular);
