(function() {
    'use strict';

    angular.module('app')
        .controller('ClientSubscriptionsCtrl', [
            '_', '$scope', '$modalInstance', 'constants', 'accountsService', 'clientsService', 'selectedClient', 'employeesService', '$q', function(_, $scope, $modalInstance, constants, accountsService, clientsService, selectedClient, employeesService, $q) {

                $scope.cancelBtnText = 'Cancel';
                $scope.canEdit = true;
                $scope.toDisable = false;
                $scope.title = "Notifications";
                $scope.confirmBtnText = 'Confirm';
                $scope.isLoading = true;
                $scope.message = "Selected Client: " + selectedClient.Name;
                $scope.warn = false;
                $scope.employees = [];
                $scope.selectedEmployee = {};
                $scope.selectedClient = selectedClient;

                var loadAllData = function() {
                    var sortingPromises = [];

                    clientsService.getSubscriptions(selectedClient.Id).then(function(data) {
                        $scope.subscriptions = data;
                        $scope.subscribedEmployees = $scope.subscriptions.Employees;

                        //if ($scope.subscriptions.Accounts.length == 0) {
                        //    var empty = $q.defer();
                        //    empty.resolve();
                        //    sortingPromises.push(empty);
                        //    return;
                        //}

                        clientsService.getById($scope.selectedClient.Id).then(function(data) {
                            $scope.accounts = data.Accounts;

                            $scope.accounts.forEach(function(aObject, aIndex) {
                                $scope.subscriptions.Accounts.forEach(function(sObject, sIndex) {
                                    var deferred = $q.defer();
                                    if (aObject.Id == sObject.Id) {
                                            aObject.IsSubscribed = true;
                                    }
                                    deferred.resolve();
                                    sortingPromises.push(deferred);
                                });
                                
                            });
                            $q.all(sortingPromises).then(function () {
                                $scope.isLoading = false;
                            });
                        });
                    });

                    employeesService.getActive().then(function (data) {

                        $scope.employees = data;
                        $scope.selectedEmployee = $scope.employees[0];
                    });
                }
                loadAllData();

                $scope.subscribeEmployee = function(selection) {
                    $scope.warn = false;
                    var test = $scope.$$ChildScope;
                    $scope.subscribedEmployees.forEach(function(object, index) {
                        if (object.Id == selection.Id) {
                            $scope.warn = true;
                            $scope.warning = "The selected employee is already subscribed to this client.";
                        }
                    });
                    if (!$scope.warn) {
                        $scope.subscribedEmployees.push(selection);
                    }
                }

                $scope.removeEmployee = function(selection) {
                    $scope.subscribedEmployees.forEach(function(object, index) {
                        if (object.Id == selection.Id) {
                            $scope.subscribedEmployees.splice(index, 1);
                        }
                    });
                }

                $scope.confirm = function() {
                    var accountsToSubscribe = [];
                    $scope.accounts.forEach(function(object, index) {
                        if (object.IsSubscribed) {
                            accountsToSubscribe.push(object);
                        }
                    });

                    var dataToSend = {}

                    var usersToSubscribe = [];
                    usersToSubscribe = usersToSubscribe.concat(_.map(accountsToSubscribe, function(x) { return x.Id; }), _.map($scope.subscribedEmployees, function(x) { return x.Id; }));

                    dataToSend.ClientId = selectedClient.Id;
                    dataToSend.UserIds = usersToSubscribe;

                    clientsService.updateSubscriptions(dataToSend).then(function(result) {
                        if (!_.isUndefined(result.ErrorMessage)) {
                            $modalInstance.close(false);
                        }
                        $modalInstance.close(true);
                    });

                }

                $scope.cancel = function() {
                    $modalInstance.close("cancel");
                }

            }
        ]);
})();