(function (angular, kendo, $, setTimeout) {
    'use strict';

    angular
        .module('bf.components.bfGrid', [])
        .directive('bfGrid', ['_', '$timeout', '$q', function (_, $timeout, $q) {

            var triggerReadFuncs = [];

            function link(scope, element, attrs) {
                var opts = scope.options;

                scope.rowCount = 0;
                scope.removeColumn = -1;
                scope.kendoOptions = {
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: function(options) { readHandler(options, scope, opts); }
                        },
                        schema: opts.schema,
                        serverPaging: true,
                        serverSorting: true,
                        pageSize: calcPageSize(element, opts.fullScreen),
                        error: function(e) { errorHandler(e, scope); }
                    }),
                    scrollable: {
                        virtual: true
                    },
                    dataBound: function(e) { dataBoundHandler(e, scope, opts); },
                    selectable: (opts.multiple ? "multiple, " : "") + "row",
                    sortable: opts.sortable === undefined || opts.sortable === null ? true : !!opts.sortable,
                    columns: opts.columns || [],
                    change: function(e) { selectedRowChangeHandler(e, scope, opts); }
                };

                if (scope.triggers) {
                    // Add a function to the triggers object so that the containing controller can
                    // trigger a read and receive a promise for when it's complete.
                    scope.triggers.triggerRead = function() {
                        return $q(function(resolve, reject) {
                            triggerReadFuncs.push(function(success, data) {
                                success ? resolve(data) : reject(data);
                            });
                            scope.grid.dataSource.pageSize(calcPageSize(element, opts.fullScreen));
                        });
                    };
                    scope.triggers.removeColumn = function (idx) {
                        if (idx > -1) {
                            scope.removeColumn = idx;
                            scope.kendoOptions.columns.splice(idx, 1);
                        }
                    };
                }
            }

            function dataBoundHandler(e, scope, opts) {
                var gridRows = getAllGridRows(scope.grid);
                // If rows were previously selected, reselect them if they are still visible
                if (scope.selectedDataItems && scope.selectedDataItems.length) {
                    // Default to using an "Id" field for item comparison
                    var getKey = opts.keySelector || function(x) { return x.Id; };
                    var prevSelectedRows = gridRows
                            .toArray()
                            .map(function(row) {
                                return { Row: row, Item: scope.grid.dataItem(row) };
                            })
                            .filter(function(item) {
                                return scope.selectedDataItems.some(function(i) {
                                    return getKey(item.Item) === getKey(i);
                                });
                            })
                            .map(function (item) {
                                return item.Row;
                            });
                    prevSelectedRows.length && scope.grid.select(prevSelectedRows);
                } else {
                    // Select the first row if no rows are currently selected
                    scope.grid.select(gridRows.eq(0));
                }

                // Call the passed in handler if set
                opts.dataBound && opts.dataBound(e);

                // HACK: when there are no rows, Kendo grid can show a horizontal scrollbar when resizing the screen
                // As we need no scrollbar when there are no rows, use CSS to ensure it doesn't show
                var horizontalScrollStyle = gridRows.length ? '' : 'hidden';
                scope.grid.virtualScrollable.element.find('.k-virtual-scrollable-wrap').css({ overflowX: horizontalScrollStyle });
                if (scope.removeColumn > -1) {
                    // Complete hack trying to remove field, not able to dynamically do it through kendo grid
                    scope.grid.element.find('div.k-grid-header col:eq(' + scope.removeColumn + ')').detach();
                    scope.grid.element.find('div.k-grid-header th:eq(' + scope.removeColumn + ')').detach();
                    scope.grid.element.find('div.k-grid-content col:eq(' + scope.removeColumn + ')').detach();
                    scope.grid.element.find('div.k-grid-content tr').find('td:eq(' + scope.removeColumn + ')').detach();
                }

                triggerAllReadCompletions(true);
            }

            function triggerAllReadCompletions(success, data) {
                triggerReadFuncs.forEach(function(f) { f(success, data); });
                triggerReadFuncs = []; // triggered, so we don't need the funcs any more
            }

            function readHandler(options, scope, opts) {
                scope.isReadError = false;
                scope.isReading = true;
                try {
                    opts.dataSource(options)
                        .then(function(data) {
                            options.success(data || []);
                            scope.rowCount = scope.grid.dataSource.data().length;
                        })
                        .catch(options.error)
                        .finally(function() {
                            scope.isReading = false;
                            if (scope.removeColumn > -1) {
                                // Complete hack trying to remove field, not able to dynamically do it through kendo grid
                                scope.grid.element.find('div.k-grid-header col:eq(' + scope.removeColumn + ')').detach();
                                scope.grid.element.find('div.k-grid-header th:eq(' + scope.removeColumn + ')').detach();
                                scope.grid.element.find('div.k-grid-content col:eq(' + scope.removeColumn + ')').detach();
                                scope.grid.element.find('div.k-grid-content tr').find('td:eq(' + scope.removeColumn + ')').detach();
                            }
                        });
                } catch (ex) {
                    options.error(ex);
                    scope.isReading = false;
                }
            }

            function errorHandler(e, scope) {
                scope.isReadError = true;
                scope.grid.dataSource.data([]);
                if (scope.removeColumn > -1) {
                    // Complete hack trying to remove field, not able to dynamically do it through kendo grid
                    scope.grid.element.find('div.k-grid-header col:eq(' + scope.removeColumn + ')').detach();
                    scope.grid.element.find('div.k-grid-header th:eq(' + scope.removeColumn + ')').detach();
                    scope.grid.element.find('div.k-grid-content col:eq(' + scope.removeColumn + ')').detach();
                    scope.grid.element.find('div.k-grid-content tr').find('td:eq(' + scope.removeColumn + ')').detach();
                }
                triggerAllReadCompletions(false, e.xhr);
                scope.$parent && scope.$parent.showNotification && scope.$parent.showNotification('Data retrieval failed', e.xhr.Message || 'An unknown error has occurred', 'error');
            }

            function selectedRowChangeHandler(e, scope, opts) {
                var selectedRows = scope.grid.select();
                var selectedDataItems = [];
                for (var i = 0; i < selectedRows.length; i++) {
                    selectedDataItems.push(scope.grid.dataItem(selectedRows[i]));
                }
                // Save the currently selected items
                scope.selectedDataItems = selectedDataItems;
                // We want to raise an event with an array of the selected rows when multiple
                // is enabled, and only a single row otherwise
                var arg = opts.multiple ? selectedDataItems : selectedDataItems[0];

                // Fire the external handler, but when the grid first loads, we're already in
                // an Angular digest cycle, but when the user is just clicking on a row, we're not.
                // If we're not, and the external handler is setting $scope values, the screen
                // will not update as expected. So use $apply in those cases.
                if (!scope.$root.$$phase) {
                    scope.$apply(function () {
                        scope.onSelectedRowChange && scope.onSelectedRowChange({rowItem: arg});
                    });
                } else {
                    scope.onSelectedRowChange && scope.onSelectedRowChange({rowItem: arg});
                }
            }

            // Ideally the Kendo Grid would just take as much space as is allocated to it, regardless
            // of page size, but it doesn't. So we need to work out what the page size should be to
            // set the height of the grid. We want the grid to take up the full height of the
            // containing div that is this directive OR take the remaining space on screen.
            // The magic "33" below taken from grid._rowHeight but the grid does not exist until
            // the first read is complete, so it's just hardcoded.
            function calcPageSize(element, fullScreen) {
                var controlHeight = fullScreen
                        ? $('footer').offset().top - element.offset().top // remaining screen space
                        : element[0].offsetHeight; // directive height
                var rowCount = Math.floor(controlHeight / 33) - 1; // -1 for header row
                if (rowCount < 1) {
                    rowCount = 1;
                }
                return rowCount;
            }

            function getAllGridRows(grid) {
                // Based on: http://demos.telerik.com/kendo-ui/grid/api
                return grid.tbody.children('tr:not(.k-grouping-row)');
            }

            return {
                restrict: 'EA',
                replace: true,
                template: require('Site\\Components\\Views\\bfGrid.html'),
                link: link,
                scope: {
                    options: '=',
                    triggers: '=',
                    columns: '=',
                    onSelectedRowChange: '&'
                }
            };
        }]);
})(window.angular, window.kendo, window.$, window.setTimeout);
