(function() {
    'use strict';

    var module = angular.module('bf.services.systemnotifications', []);

    module.service('systemnotificationsService', [
        '$q', '_', 'dataStoreService', 'ENVIRONMENTAL', function($q, _, dataStoreService, ENVIRONMENTAL) {
        var apiSection = '/systemnotifications/';

            function localGet() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection);
            }

            function localGetById(id) {
                var deferred = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
                }
                deferred.reject();
                return deferred.promise;
            }

            function addNotification(newNotification) {
                var deffered = $q.defer();
                if (!_.isUndefined(newNotification)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection, newNotification);
                }
                deffered.reject();
                return deffered.promise;
            }

            function updateNotification(id, updatedNotification) {
                var deffered = $q.defer();
                if (!_.isUndefined(updatedNotification)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id, updatedNotification);
                }
                deffered.reject();
                return deffered.promise;
            }

            return {
                get: localGet,
                getById: localGetById,
                add: addNotification,
                update: updateNotification
            }



        }
    ]);
})();
    