angular.module('app')

// Path: /Settings
    .controller('SettingsCtrl',
        ['$scope', '$location', '$window', '$state', 'employeesService', 'ENVIRONMENTAL', 'store',
            function ($scope, $location, $window, $state, employeesService, ENVIRONMENTAL, store) {

                if (store.get('profile').mfa_enabled === undefined) {
                    if (store.get('tokenAPI')) {
                        store.remove('tokenAPI');
                    }
                    if (store.get('tokenFiles')) {
                        store.remove('tokenFiles');
                    }
                    if (store.get('token')) {
                        store.remove('token');
                    }
                    if (store.get('profile')) {
                        store.remove('profile');
                    }
                    window.location.replace("https://" + ENVIRONMENTAL.AUTH0.DOMAIN + "/Manage/EnableAuthenticator?logoutOnComplete=true");
                }

                employeesService.myDetails().then(function (userDetails) {
                    $scope.firmName = userDetails.Firm.Name || '';
                });
                
                $scope.$on('$viewContentLoaded',
                    function(event) {
                        $scope.isLoading = false;
                        $scope.progress.complete();

                    });

                $scope.$on('$stateChangeSuccess',
                    function(event) {
                        if ($state.current.name.indexOf("settings.") === 0) {
                            // we are already inside settings, just moving to another tab
                        } else {
                            // we have clicked the settings nav button - reload
                            $state.go("settings.employees", {}, { reload: true });
                        }
                    });

                //$state.go("settings.employees");
            }
        ]);