(function() {
    'use strict';

    angular.module('app')
        .controller('AuthenticationCtrl', [
            '$scope', '$location', '$window', 'ENVIRONMENTAL', 'firmsService', 'store',
            function ($scope, $location, $window, ENVIRONMENTAL, firmsService, store) {
                $scope.isLoading = true;
                $scope.firm;

                $scope.$on('$viewContentLoaded', function (event) {
                    var profile = store.get('profile');
     
                    firmsService.getById(profile.firmId).then(function (data) {
                        if (!_.isUndefined(data)) {
                            $scope.firm = data;
                        }
                        $scope.isLoading = false;
                    });
                });

                $scope.save = function () {
                    var firmUpdateModel = {
                        name: $scope.firm.Name,
                        enforceAccountMFA: $scope.firm.EnforceAccountMFA,
                        enforceSignatureLogin: $scope.firm.EnforceSignatureLogin
                    };

                    firmsService.update($scope.firm.Id, firmUpdateModel)
                        .then(function (result) {
                            if (result.Message) {
                                vm.errorMessage = result.Message;
                                vm.error = true;
                            }
                        })
                        .catch(function (error) {
                            vm.errorMessage = "There has been an issue saving your changes, please contact support.";
                            vm.error = true;
                        });

                    $scope.$parent.showNotification("Updated", "Authentication details has been updated.", "success");
                }
            }
        ]);
})();