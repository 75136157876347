(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('DocumentsPreviewCtrl', [
        '$scope', '$modalInstance', 'fileId', 'fileTitle', 'brandingColour', '$sce', 'constants', 'documentsService', '$q', 'ENVIRONMENTAL', '$window',
        function ($scope, $modalInstance, fileId, fileTitle, brandingColour, $sce, constants, documentsService, $q, ENVIRONMENTAL, $window) {

            $scope.title = fileTitle;
            $scope.close = function() {
                $modalInstance.dismiss('cancel');
            }

            $scope.documentLoading = true;
            $scope.messageIntegrity = false;
            $scope.error = false;
            $scope.errorMessage = "Error loading document please try again.";
            
            function onMessageHandler(e) {
                if (e.originalEvent.data === "loadingComplete") {
                    if (!$scope.messageIntegrity) {
                        $scope.$apply(function() {
                            $scope.documentLoading = false;
                            $scope.messageIntegrity = true;
                        });
                        $("#frame")[0].style.visibility = "visible";
                    }
                }
                if (e.originalEvent.data === "error") {
                    $scope.$apply(function() {
                        $scope.error = true;
                    });
                }
            };

            $($window).on("message", onMessageHandler);

            $scope.$on('$destroy', function () {
                $($window).off("message", onMessageHandler);
            });

            if (brandingColour == null || brandingColour.length !== 6) {
                brandingColour = "00ACD4";
            }
            documentsService.getDownloadForPreview(fileId)
            .then(function (data) {
                $scope.url = $sce.trustAsResourceUrl(ENVIRONMENTAL.BASEURLS.PREVIEW + constants.DOCUMENTS.URLS.PREVIEWURL + encodeURIComponent(data.fileUrl) + constants.DOCUMENTS.URLS.PREVIEWTITLE + fileTitle + constants.DOCUMENTS.URLS.PREVIEWCOLOR + brandingColour);
            })
            .catch(function(err) {
                $scope.errorMessage = "Error while finding the requested document for previewing. Please contact support.";
                $scope.error = true;
                $scope.$apply();
            });
        }
    ]);
})();