// Vendor scripts - these should be coming from NPM unless there's a good reason why they can't.

require("../common-vendor-imports.js");

require("ng-tags-input/build/ng-tags-input.js");
require("ng-tags-input/build/ng-tags-input.css");
require("ng-tags-input/build/ng-tags-input.bootstrap.css");

// End of vendor scripts

require("./Site/config.environmental.js");

require("./Site/Services/commonConfig.js");
require("./Site/Services/constants.js");
require("./Site/Services/dataStoreService.js");
require("./Site/Services/dataLayerService.js");
require("./Site/Services/auditService.js");
require("./Site/Services/dashboardService.js");
require("./Site/Services/documentsService.js");
require("./Site/Services/documentFiltersService.js");
require("./Site/Services/downloadService.js");
require("./Site/Services/accountsService.js");
require("./Site/Services/employeesService.js");
require("./Site/Services/clientsService.js");
require("./Site/Services/firmsService.js");
require("./Site/Services/notificationsReplyAddressService.js");
require("./Site/Services/notificationsService.js");
require("./Site/Services/systemnotificationsService.js");
require("./Site/Services/filtersService.js");
require("./Site/Services/brandingService.js");
require("./Site/Services/notificationMergeFieldsService.js");
require("./Site/Services/passwordService.js");
require("./Site/Services/systemSettingsService.js");
require("./Site/Services/signatureService.js");
require("./Site/Services/logService.js");
require("./Site/Services/clipboardService.js");
require("./Announcements/Upload/announcementsUploadProgress.js");

// site Components
require("./Site/Components/bfGrid.js");
require("./Site/Components/clientActionsList.js");
require("./Site/Components/firmActionsList.js");
require("./Site/Components/fileActionsList.js");
require("./Site/Components/uploadListDirective.js");
require("./Site/Components/dashboardActivity.js");
require("./Site/Components/bfDatePicker.js");
require("./Site/Components/bfDateRangePicker.js");
require("./Site/Components/bfFeatureToggle.js");

// site
require("./Scripts/app.js");
require("./Scripts/services.js");
require("./Scripts/directives.js");

require("./Site/Services/adobeSignService.js");
require("./Site/Services/featuresService.js");

require("./Site/Services/reportsService.js");
require("./Reports/ReportUtilities.js");
require("./Reports/ReportForms/UnsignedDocumentsByClient.js");
require("./Reports/ReportForms/UnsignedDocumentsByAccount.js");
require("./Reports/ReportForms/UnsignedDocumentsByEmployee.js");
require("./Reports/ReportForms/SignedDocumentsByClient.js");
require("./Reports/ReportForms/SignedDocumentsByAccount.js");
require("./Reports/ReportForms/SignedDocumentsByEmployee.js");
require("./Reports/ReportForms/AccountInvitesAcceptedByClient.js");
require("./Reports/ReportForms/AccountInvitesOutstandingByClient.js");
require("./Reports/ReportForms/UnreadDocumentsByAccount.js");
require("./Reports/ReportForms/UnreadDocumentsByClient.js");
require("./Reports/ReportForms/UnreadDocumentsByEmployee.js");

// site Controllers
require("./Start/StartCtrl.js");
require("./Site/LoginCtrl.js");
require("./Site/CallbackCtrl.js");
require("../Shared/LogoutCtrl.ts");
require("./Settings/BrandingCtrl.js");
require("./Settings/AuthenticationCtrl.js");
require("./Settings/FiltersCtrl.js");
require("./Settings/FiltersDetailsCtrl.js");
require("./Settings/NotificationsListCtrl.js");
require("./Settings/NotificationsDetailsCtrl.js");
require("./Settings/SettingsCtrl.js");
require("./Settings/EmployeesCtrl.js");
require("./Settings/SetNotificationsReplyAddressCtrl.js");
require("./AdobeSign/oAuth.js");
require("./Accounts/AccountsCtrl.js");
require("./Accounts/AccountDetailsCtrl.js");
require("./Accounts/AccountClientDetailsCtrl.js");
require("./Clients/ClientsCtrl.js");
require("./Clients/ClientsInviteCtrl.js");
require("./Clients/ClientsDetailsCtrl.js");
require("./Clients/ClientSubscriptionsCtrl.js");
require("./Documents/DocumentAuditTrailCtrl.js");
require("./Documents/DocumentsCtrl.js");
require("./Documents/DocumentsDeleteCtrl.js");
require("./Documents/DocumentsFilterDetailsCtrl.js");
require("./Documents/DocumentsPreviewCtrl.js");
require("./Documents/MultipleSignatures/RequestMultipleSignaturesCtrl.js");
require("./Documents/MultipleSignatures/VerifyPinCtrl.js");
require("./Documents/MultipleSignatures/ConfirmMultipleSignatureRequestCtrl.js");
require("./Documents/MultipleSignatures/MultipleSignaturePrepareSignCtrl.js");
require("./Documents/MultipleSignatures/MultipleSignatureSignCtrl.js");
require("./Reports/Reports.js");
require("./Reports/Report.js");
require("./Firms/FirmsCtrl.js");
require("./Firms/FeatureAccessManagementCtrl.js");
require("./Firms/EditFirmCtrl.js");
require("./Firms/RegisterFirmCtrl.js");
require("./Firms/DeactivateFirmCtrl.js");
require("./Firms/EmployeesManagementCtrl.js");
require("./Notifications/NotificationsCtrl.js");
require("./Notifications/NotificationsDetails.js");
require("./Site/ProfileCtrl.js");
require("./Settings/EmployeeUpdateCtrl.js");
require("./Site/AcceptInviteCtrl.js");
require("./Site/ForgotPassword/RequestPasswordCtrl.js");
require("./Site/ForgotPassword/UpdatePasswordCtrl.js");
require("./Documents/ClickToSign/RequestClickToSignCtrl.js");
require("./Documents/ClickToSign/ConfirmClickToSignRequestCtrl.js");
require("./Documents/AdobeSign/AdobeSignPrechecksCtrl.js");
require("./Documents/AdobeSign/RequestAdobeSignCtrl.js");
require("./Documents/AdobeSign/CompleteAdobeSecureSignCtrl.js");
require("./Documents/AdobeSign/AdobeSignTaskSignatoriesModalCtrl.js");
require("./Documents/ClickToSign/ClickToSignModalCtrl.js");
require("./Documents/SigningPrechecks/SigningPrechecks.js");
require("./Announcements/AnnouncementsCtrl.js");
require("./Announcements/AnnouncementDetailsCtrl.js");
require("./Site/Services/announcementsService.js");
require("./Settings/SigningPreferencesCtrl.js");
require("./Site/Components/confirmationModal.js");

require("./Content/app.less");