(function() {
    "use strict";

    var module = angular.module("bf.services.notifications", []);

    module.service("notificationsService", [
        "$q", "_", "dataStoreService", "ENVIRONMENTAL", function($q, _, dataStoreService, ENVIRONMENTAL) {
        var apiSection = "/notifications/";

            function localGet() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection);
            }

            function localGetById(id) {
                var deferred = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
                }
                deferred.reject();
                return deferred.promise;
            }

            function updateNotification(id, updatedNotification) {
                var deffered = $q.defer();
                if (!_.isUndefined(updatedNotification)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id, updatedNotification);
                }
                deffered.reject();
                return deffered.promise;
            }

            function enableNotification(id) {
                return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id + "/enable");
            }

            function disableNotification(id) {
                return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id + "/disable");
            }

            return {
                get: localGet,
                getById: localGetById,
                update: updateNotification,
                enable: enableNotification,
                disable: disableNotification
            };
        }
    ]);
})();