(function(angular, localStorage) {
    "use strict";

    var comingSoonDismissedLocalStorageKey = "ReportsCtrl.ComingSoonAlertDimissed";

    angular
        .module("app")
        .controller("ReportsCtrl", ["$scope", "reportsService", 'ENVIRONMENTAL', 'store',
            function ($scope, reportsService, ENVIRONMENTAL, store) {

                $scope.progress.reset();
                $scope.reportGroups = [];
                $scope.showComingSoon = !localStorage.getItem(comingSoonDismissedLocalStorageKey);
                $scope.closeComingSoon = closeComingSoon;

                if (store.get('profile').mfa_enabled === undefined) {
                    if (store.get('tokenAPI')) {
                        store.remove('tokenAPI');
                    }
                    if (store.get('tokenFiles')) {
                        store.remove('tokenFiles');
                    }
                    if (store.get('token')) {
                        store.remove('token');
                    }
                    if (store.get('profile')) {
                        store.remove('profile');
                    }
                    window.location.replace("https://" + ENVIRONMENTAL.AUTH0.DOMAIN + "/Manage/EnableAuthenticator?logoutOnComplete=true");
                }

            showReportsList();

            function showReportsList() {
                $scope.progress.start();
                $scope.isReading = true;
                reportsService.getReports()
                    .then(function(reportGroups) {
                        $scope.reportGroups = reportGroups;
                        $scope.isReadError = false;
                    })
                    .catch(function() {
                        $scope.isReadError = true;
                        $scope.showNotification("Reports", "An error has occurred while retrieving the available reports.", "error");
                    })
                    .finally(function() {
                        $scope.progress.complete();
                        $scope.isReading = false;
                    });
            }

            function closeComingSoon() {
                localStorage.setItem(comingSoonDismissedLocalStorageKey, true);
                $("#comingSoon").fadeTo(200, 0, function() {
                    $("#comingSoon").alert("close");
                });
            }
        }]);
})(window.angular, window.localStorage);
