(function (angular) {
    "use strict";

    angular
        .module("app")
        .controller("deactivateFirmCtrl", [
            "$modalInstance", "selectedFirm", "firmsService", function ($modalInstance, selectedFirm, firmsService) {

                var vm = this;
                vm.firmName = selectedFirm.Name;
                vm.error = false;
                vm.errorMessage = "";
                vm.confirmation = "";

                vm.close = function () {
                    $modalInstance.dismiss("cancel");
                }

                vm.deactivate = function () {
                    if (vm.confirmation === vm.firmName) {
                        vm.error = false;

                        firmsService.deactivate(selectedFirm.Id)
                            .then(function(result) {
                                if (result.Message) {
                                    vm.errorMessage = result.Message;
                                    vm.error = true;
                                } else {
                                    $modalInstance.close(true);
                                }
                            })
                            .catch(function(error) {
                                vm.errorMessage =
                                    "There has been an issue deactivating the firm, please contact support.";
                                vm.error = true;
                            });
                    } else {
                        vm.errorMessage = "Firm name does not match, please ensure it is typed exactly the same";
                        vm.error = true;
                    }
                }
            }
        ]);

})(window.angular);