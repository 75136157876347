'use strict';

angular.module('app.controllers', [])

    .controller('AppCtrl', ['$scope', '$location', '$window', 'auth', '$state', 'authHelper', '$stateParams', '$interpolate', 'ngProgress', 'ENVIRONMENTAL', '$rootScope', 'store', 'brandingService', 
        function ($scope, $location, $window, auth, $state, authHelper, $stateParams, $interpolate, ngProgress, ENVIRONMENTAL, $rootScope, store, brandingService) {
        $scope.isSelected = function (viewLocation) {
            return ($location.path().indexOf(viewLocation) > -1 && viewLocation !== '/') || $location.path() === viewLocation;
        };
        
        $scope.progress = ngProgress;
        $scope.auth = auth;
        $scope.authHelper = authHelper;
        $scope.isLoading = true;
        $scope.navOpen = false;

        $rootScope.$watch('isLoading', function (value) {
            if (value == true) {
                $scope.isLoading = true;
            } else {
                $scope.isLoading = false;
            }
        });

        $scope.logout = function () {
            $scope.principal.logout();
            $scope.user = null;
            $state.go('login');
        };

        $scope.showNotification = function (title, message, type, autohide) {
            $scope.appNotification.setOptions({
                stacking: "down",
                show: onShow,
                button: true,
                autoHideAfter:autohide || 5000,             // restore default of 5 seconds if not provided
                templates: [{
                    type: "error",
                    template: $("#errorTemplate").html()
                },
                    {
                        type: "success",
                        template: $("#successTemplate").html()
                    }]
            });

            $scope.appNotification.show({
                title: title,
                message: message
            }, type);
        };
        
        $scope.hideNotification = function () {
            $scope.appNotification.hide();
        }        
                
        function onShow(e) {
            if (!$("." + e.sender._guid)[1]) {
                var element = e.element.parent(),
                    eWidth = element.width(),
                    eHeight = element.height(),
                    wWidth = $(window).width(),
                    wHeight = $(window).height(),
                    newTop, newLeft;

                newLeft = Math.floor(wWidth / 2 - eWidth / 2);
                newTop = 0;//Math.floor(wHeight / 2 - eHeight / 2);

                e.element.parent().css({ top: newTop, left: newLeft });
            }
        }

        $scope.logOutThenGoToLoginPage = function () {
            var urlParams = new URLSearchParams(window.location.href);
            var token = urlParams.get('access_token');
            var url = "https://" + ENVIRONMENTAL.AUTH0.DOMAIN +
                "/connect/endsession" + "?id_token_hint=" + token + "&post_logout_redirect_uri=" +
                ENVIRONMENTAL.AUTH0.CALLBACKURL;

            window.location.replace(url);
        };

        $scope.toggleMenu = function () {
            $scope.navOpen = !$scope.navOpen;
            $rootScope.navOpen = $scope.navOpen;
        };

        $rootScope.$watch('navOpen', function (newVal, oldVal) {
            $scope.navOpen = newVal;
        });

        $scope.showAppLoader = true;

        var brand = store.get("branding");

        if (brand != null && brand != "null" && brand != "" ) {
            brand = brand.replace(/"/g, "");
            brandingService.applyBrandCssToPage(brand);
            setTimeout(function () {
                    $scope.showAppLoader = false;
                },
                750);
        } else {
            if ($location.search()["theme"]) {
                var themeId = $location.search()["theme"];
                store.set('branding', themeId);
                brandingService.applyBrandCssToPage(ENVIRONMENTAL.BASEURLS.BRANDING + themeId + "/Admin/app.css");
                setTimeout(function () {
                        $scope.showAppLoader = false;
                    },
                    750);
            } else {
                setTimeout(function () {
                        $scope.showAppLoader = false;
                    },
                    500);
            }
        }
    }])

    // Path: /error/404
    .controller('Error404Ctrl', ['$scope', '$location', '$window', function ($scope, $location, $window) {
        $scope.$root.title = 'Error 404: Page Not Found';
        $scope.$on('$viewContentLoaded', function (event) {
            $scope.isLoading = false;
            $scope.progress.complete();
        });
    }])

    .controller('DullCtrl', ['$scope', '$location', '$window', function ($scope, $location, $window) {
        $scope.$on('$viewContentLoaded', function (event) {
            $scope.isLoading = false;
            $scope.progress.complete();
        });
    }]);