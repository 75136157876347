(function(angular) {
    "use strict";

    var appModule = angular.module("app");
    appModule.controller("AdobeSignTaskSignatoriesModalCtrl", ["$scope", "loading", "documentsService", "getModalInstance", "documentId", "documentTitle", function($scope, loading, documentsService, getModalInstance, documentId, documentTitle) {
        $scope.documentTitle = documentTitle;
        $scope.tracker = loading.createTracker(false);
        $scope.cancel = function() {
            getModalInstance().dismiss();
        };


        var promise = documentsService.getById(documentId).then(function(documentDto) {
            var outstanding = [];
            var completed = [];

            var mapSignatory = function(adobeSignatory) {
                var userDto = adobeSignatory.Employee || adobeSignatory.Account;

                return {
                    type: !!adobeSignatory.Employee ? "Employee" : "Account",
                    name: (userDto.GivenName || "") + " " + (userDto.Surname || ""),
                    email: userDto.Email
                };
            }

            for (var i=0; i<documentDto.DocumentAdobeSigningUsers.length; i++) {
                var adobeSignatory = documentDto.DocumentAdobeSigningUsers[i];

                if (adobeSignatory.SigningTime) {
                    completed.push(mapSignatory(adobeSignatory));
                } else {
                    outstanding.push(mapSignatory(adobeSignatory));
                }
            }

            $scope.outstanding = outstanding;
            $scope.completed = completed;
        });
        $scope.tracker.track(promise);     
    }]);
})(window.angular);