(function () {
    "use strict";

    var module = angular.module("bf.services.notifications.mergefields", []);

    module.service("notificationMergeFieldsService", [
        "$q", function ($q) {
            
            function localGet(notificationType) {
                var returnVal = [];
                var deferred = $q.defer();
                
                var constants = {
                    "inviteLink": "Invite Link",
                    "firmName": "Firm Name",
                    "accGivenName": "Account Given Name",
                    "accSurname": "Account Surname",
                    "accEmail": "Account Email",
                    "clientName": "Client Name",
                    "docDetails": "Document Details",
                    "docDetailsWithName": "Document Details with Name",
                    "portalUrl": "Portal Url",
                    "pinCode": "Pin Code",
                    "empGivenName": "Employee Given Name",
                    "empSurname": "Employee Surname",
                    "declinedBy": "Declined By",
                    "declineComment": "Decline Comment"
                };

                switch (notificationType) {
                    case 1: // FirmSignUp
                        break;
                    case 2: // EmployeeSignUp
                        returnVal = [{ name: constants.inviteLink, value: "%%invitelink%%" }, { name: constants.firmName, value: "%%firmName%%" }, { name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }];
                        break;
                    case 4: // AccountInvite
                        returnVal = [{ name: constants.inviteLink, value: "%%invitelink%%" }, { name: constants.firmName, value: "%%firmName%%" }, { name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }];
                        break;
                    case 8: // AccountSignUp
                        returnVal = [{ name: constants.empGivenName, value: "%%employeeGivenName%%" }, { name: constants.empSurname, value: "%%employeeSurname%%" }, { name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.accEmail, value: "%%accountEmail%%" }];
                        break;
                    case 16: // AccountFileDownloaded
                        returnVal = [{ name: constants.empGivenName, value: "%%employeeGivenName%%" }, { name: constants.empSurname, value: "%%employeeSurname%%" }, { name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.accEmail, value: "%%accountEmail%%" }, { name: constants.clientName, value: "%%clientName%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }];
                        break;
                    case 32: // AccountResetPasswordOccured
                        returnVal = [{ name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.accEmail, value: "%%accountEmail%%" }];
                        break;
                    case 64: // AccountFileUploaded
                        returnVal = [{ name: constants.empGivenName, value: "%%employeeGivenName%%" }, { name: constants.empSurname, value: "%%employeeSurname%%" }, { name: constants.clientName, value: "%%clientName%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }, { name: constants.docDetailsWithName, value: "%%documentDetailsWithName%%" }, { name: constants.portalUrl, value: "%%portalUrl%%" }];
                        break;
                    case 128: // AccountFileNotDownloaded
                        break;
                    case 256: // FirmFileUploaded
                        returnVal = [{ name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.firmName, value: "%%firmName%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }, { name: constants.portalUrl, value: "%%portalUrl%%" }];
                        break;
                    case 512: // AccountResetPassword
                        returnVal = [{ name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.accEmail, value: "%%accountEmail%%" }, { name: "Reset Link", value: "%%passwordResetLink%%" }];
                        break;
                    case 1024: // DocumentRequiresSignatures
                        returnVal = [{ name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.firmName, value: "%%firmName%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }, { name: constants.pinCode, value: "%%pinCode%%" }, { name: constants.portalUrl, value: "%%portalUrl%%" }];
                        break;
                    case 2048: // DocumentSigningComplete
                        returnVal = [{ name: constants.empGivenName, value: "%%employeeGivenName%%" }, { name: constants.empSurname, value: "%%employeeSurname%%" }, { name: constants.clientName, value: "%%clientName%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }, { name: constants.portalUrl, value: "%%portalUrl%%" }];
                        break;
                    case 4096: // DocumentTaskRemoved
                        returnVal = [{ name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.firmName, value: "%%firmName%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }, { name: constants.portalUrl, value: "%%portalUrl%%" }];;
                        break;
                    case 8192: // EmployeeAcceptedInvite
                        returnVal = [{ name: constants.empGivenName, value: "%%employeeGivenName%%" }, { name: constants.empSurname, value: "%%employeeSurname%%" }, { name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.accEmail, value: "%%accountEmail%%" }];
                        break;
                    case 16384: // DocumentRequiresEmployeeSignature
                        returnVal = [{ name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }, { name: constants.pinCode, value: "%%pinCode%%" }, { name: constants.portalUrl, value: "%%portalUrl%%" }];
                        break;
                    case 32768: // DocumentSigningDeclined
                        returnVal = [{ name: constants.accGivenName, value: "%%employeeGivenName%%" }, { name: constants.accSurname, value: "%%employeeSurname%%" }, { name: constants.clientName, value: "%%clientName%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }, { name: constants.declinedBy, value: "%%declinedBy%%" }, { name: constants.declineComment, value: "%%declineComment%%" }, { name: constants.portalUrl, value: "%%portalUrl%%" }];
                        break;
                    case 65536: // AccountDocumentSigningComplete
                        returnVal = [{ name: constants.accGivenName, value: "%%accountGivenName%%" }, { name: constants.accSurname, value: "%%accountSurname%%" }, { name: constants.docDetails, value: "%%documentDetails%%" }, { name: constants.portalUrl, value: "%%portalUrl%%" }];
                        break;
                    default:
                        returnVal = [];
                }
                deferred.resolve(returnVal);
                return deferred.promise;
            }

            return {
                get: localGet
            }
        }
    ]);
})();
