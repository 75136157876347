angular.module('app')

// Path: /account/login
.controller('LoginCtrl', 
    ['auth', '$scope', '$state', 'events', 'constants', 'store', 'ENVIRONMENTAL', '$location', '$rootScope', '$stateParams', 'brandingService', '$window', 
    function (auth, $scope, $state, events, constants, store, ENVIRONMENTAL, $location, $rootScope, $stateParams, brandingService, $window) {
        $scope.$parent.hideNotification();
        $scope.progress.start();
        $scope.loading = true;
        auth.signin({
            identityDomain: ENVIRONMENTAL.AUTH0.DOMAIN,
            scope: 'openid profile cp-api'
        }, onLoginSuccess, onLoginFailed);

        function onLoginFailed(errorMessage) { }
        function onLoginSuccess(errorMessage) { }
}]);
