(function() {
    'use strict';

    angular.module('app')
        .controller('AnnouncementsCtrl', ['$scope', 'constants', '$timeout', 'events', 'announcementsService', '$state', '$q', '$modal', '_', 'ENVIRONMENTAL', 'store',
            function ($scope, constants, $timeout, events, announcementsService, $state, $q, $modal, _, ENVIRONMENTAL, store) {
            $scope.publishBtnText = 'Publish';
            $scope.isDisabled = true;

                if (store.get('profile').mfa_enabled === undefined) {
                    if (store.get('tokenAPI')) {
                        store.remove('tokenAPI');
                    }
                    if (store.get('tokenFiles')) {
                        store.remove('tokenFiles');
                    }
                    if (store.get('token')) {
                        store.remove('token');
                    }
                    if (store.get('profile')) {
                        store.remove('profile');
                    }
                    window.location.replace("https://" + ENVIRONMENTAL.AUTH0.DOMAIN + "/Manage/EnableAuthenticator?logoutOnComplete=true");
            }

            var selectedItem = null;

            $scope.newAnnouncement = function () {
                createAnnouncementDetailsModal(constants.ANNOUNCEMENTS.MODALMODES.ADD, null).result
                    .then(function (result) {
                        return result === 'cancel' ? $q.reject() : $q.when(result);
                    })
                    .then(function (result) {
                        return announcementsService.add(result);
                    })
                    .then(refreshGrid);
            };

            $scope.editAnnouncement = function () {
                createAnnouncementDetailsModal(constants.ANNOUNCEMENTS.MODALMODES.EDIT, selectedItem).result
                    .then(function (result) {
                        return result === 'cancel' ? $q.reject() : $q.when(result);
                    })
                    .then(function (result) {
                        return announcementsService.update(result);
                    })
                    .then(refreshGrid);
            }

            function createAnnouncementDetailsModal(mode, dataToPass) {
                return $modal.open({
                    template: require('announcements\\AnnouncementDetails.html'),
                    controller: 'AnnouncementDetailsCtrl',
                    resolve: {
                        actionMode: function () {
                            return mode;
                        },
                        passedData: function () {
                            return dataToPass;
                        }
                    },
                    backdrop: 'static',
                    size: 'lg'
                });
            }

            $scope.toggleAnnouncementVisibility = function () {
                if (selectedItem) {
                    announcementsService.toggleAnnouncementVisibility(selectedItem).then(refreshGrid);
                }
            }

            function refreshGrid() {
                $scope.isDisabled = true;
                return $scope.bfGridTriggers.triggerRead();
            }

            $scope.bfGridTriggers = {};

            $scope.bfGridOptions = {
                dataSource: function (options) {
                    return announcementsService.get(null, options.data.page, options.data.pageSize, options.data.sort);
                },
                dataBound: function () { $scope.progress.complete(); },
                multiple: false,
                fullScreen: true,
                columns: [
                    { field: 'Title', title: 'Title' },
                    { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "ModifiedDate", title: "Last Modified", type: "date", template: kendo.template($('#dateModifiedToLocal').html()) },
                    { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "ExpiryDate", title: "Expiry Date", type: "date", template: kendo.template($('#dateExpiryToLocal').html()) },
                    { field: 'IsVisible', title: 'Visible', width: '100px', headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, template: '# if(IsVisible && !Expired){# <span class="glyphicon glyphicon-ok"></span> #} else if(IsVisible && Expired){# <span title="Set to visible, but has expired and won\'t display to users." class="fa fa-eye-slash"></span> #}#' },
                    { field: 'Expired', title: 'Expired', width: '100px', headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, template: '# if(Expired){# <span class="glyphicon glyphicon-ok"></span> #}#' }
                ],
                schema: {
                    data: "Data",
                    total: "Total"
                }
            };

            $scope.onSelectedRowChange = function (rowItem) {
                selectedItem = rowItem;
                $scope.isDisabled = false;
                $scope.publishBtnText = rowItem.IsVisible ? 'Hide' : 'Publish';
            };
        }]);
})();
