(function(angular) {
    'use strict';

    angular
        .module('app')
        .service('adobeSignService', ['$http', '$q', 'ENVIRONMENTAL', "logService", function($http, $q, env, logService) {

            function getCurrentUser() {
                return $http.get(env.BASEURLS.API + '/api/adobesign/users/me')
                    .then(applyRequestFailureFix)
                    .then(function(resp) {
                        return resp.data;
                    })
                    .catch(function(error) {
                        logService.error(error, "adobeSignService.getCurrentUser failed");
                        return $q.reject(error);
                    });
            }

            function createUser(employeeId) {
                return $http.post(env.BASEURLS.API + '/api/adobesign/users', { employeeId: employeeId })
                    .then(applyRequestFailureFix)
                    .catch(function(error) {
                        logService.error(error, "adobeSignService.createUser failed");
                        return $q.reject(error);
                    });
            }

            function createAgreement(docId, accountIds, employeeIds, advancedSetup) {
                return $http.post(env.BASEURLS.API + '/api/adobesign/agreements/create', { documentId: docId, accountIds: accountIds, employeeIds: employeeIds, advancedSetup: advancedSetup })
                    .then(applyRequestFailureFix)
                    .then(function(resp) {
                        return resp.data;
                    })
                    .catch(function(error) {
                        logService.error(error, "adobeSignService.createAgreement failed");
                        return $q.reject(error);
                    });
            }

            function verifyDocumentForSigning(documentId) {
                return $http.get(env.BASEURLS.API + '/api/adobesign/agreements/verifyDocumentForSigning/' + documentId)
                    .then(applyRequestFailureFix)
                    .then(function (resp) {
                        return resp.data;
                    })
                    .catch(function (error) {
                        logService.error(error, "adobeSignService.verifyDocumentForSigning failed");
                        if (!_.isUndefined(error.data) && !_.isUndefined(error.data.Message)) {
                            return $q.reject(error.data.Message);
                        }
                        return $q.reject('Unknown reply from service');
                    });
            }

            function applyRequestFailureFix(resp) {
                // Workaround to turn failed calls back into failed promises
                return !resp || !resp.status || resp.status >= 400 ? $q.reject(resp) : resp;
            }

            return {
                getCurrentUser: getCurrentUser,
                createUser: createUser,
                createAgreement: createAgreement,
                verifyDocumentForSigning: verifyDocumentForSigning
            };
        }]);

})(window.angular);