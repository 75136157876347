(function (angular) {

    angular.module('app')
        .factory('confirmationModalService', ['$modal', '$q', confirmationModalServiceCtrl]);

    function confirmationModalServiceCtrl($modal, $q) {

        return {
            displayMessage: displayMessageModal
        }

        function displayMessageModal(message, title, name) {
            var deferred = $q.defer();

            var modalInstance = $modal.open({
                backdrop: 'static',
                keyboard: false,
                template: require('site\\components\\views\\confirmationMessageModal.html'),
                controller: confirmationModalController,
                resolve: {
                    messageToDisplay: function () {
                        return message;
                    },
                    titleToDisplay: function () {
                        return title;
                    },
                    nameOfConfirmation: function () {
                        return name;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                if (result === true) {
                    deferred.resolve();
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }

    var confirmationModalController = function (_, $scope, $modalInstance, messageToDisplay, titleToDisplay, nameOfConfirmation) {
        'use strict';


        var multiLineMesageToDisplay = [];

        if (_.isArray(messageToDisplay)) {
            multiLineMesageToDisplay = messageToDisplay;
        } else {
            multiLineMesageToDisplay = [messageToDisplay]; // Create a one item array for the view to display
        }

        $scope.viewModel = {
            title: titleToDisplay,
            multiLineMesageToDisplay: multiLineMesageToDisplay,
            modalEnabled: false,
            loadingMessage: 'Just loading...',
            nameOfConfirmation: nameOfConfirmation
        };

        init();

        function init() {
            enableModal();
        }

        function disableModal() {
            $scope.viewModel.modalEnabled = false;
        }

        function enableModal() {
            $scope.viewModel.modalEnabled = true;
        }

        $scope.no = function () {
            $modalInstance.dismiss(false);
        };

        $scope.yes = function () {
            $modalInstance.close(true);
        };
    };

})(window.angular);