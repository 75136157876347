(function () {
    'use strict';

    angular.module('bf.constants', [])
        .constant('constants', {
            APP: {
                NAME: 'Portal Admin'
            },
            SYSTEM: {
                EVENTS: {
                    GRIDRESIZE: 'GridResize'
                },
                SETTINGS: {
                    PREVIEWABLEFILEEXTENSIONS: 'PreviewableFileExtensions'
                }
            },
            DOCUMENTS: {
                URLS: {
                    PREVIEWURL: "?url=",
                    PREVIEWTITLE: "&title=",
                    PREVIEWCOLOR: "&color=",
                    SIGNURL: "?url=",
                    SIGNFINISHEDURL: "&finishedurl=",
                    SIGNTITLE: "&title="
                },
                EVENTS: {
                    SELECTED: 'Selected',
                    SEARCH: 'Search',
                    REFRESHREQUIRED: 'DocumentsRefreshRequired'
                },
                ACTIONTYPES: {
                    NONE: 1,
                    SIGN: 2,
                    SECURESIGN: 3,
                    CLICKTOSIGN: 5,
                    SIGNED: 4,
                    ADOBESIGN: 6,
                    ADOBESECURESIGN: 7,
                    DECLINED: 8
                }
            },
            SECURITY: {
                EVENTS: {
                    LOGGEDIN: 'UserLoggedIn',
                    LOGGEDOUT: 'UserLoggedOut'
                }
            },
            EMPLOYEES: {
                MODALMODES: {
                    CREATE: 'Create',
                    EDIT: 'Edit',
                    DELETE: 'Delete',
                    ACTIVATE: 'Activate'
                },
                CREATETYPE: {
                    EXISTING: 'Existing',
                    NEW: 'New'
                }
            },
            ACCOUNTS: {
                MODALMODES: {
                    EDIT: 'Edit',
                    DELETE: 'Delete',
                    VIEWCLIENTS: 'ViewClients',
                    EDITCLIENTS: 'EditClients'
                },
                EVENTS: {
                    REFRESHREQUIRED: 'UsersChanged',
                    USERADDED: 'UserAdded',
                    USERDEACTIVATED: 'UserDeactivated',
                    USERACTIVATED: 'UserActivated',
                    SEARCH: 'Search'
                }
            },
            ANNOUNCEMENTS: {
                MODALMODES: {
                    ADD: 'Add',
                    EDIT: 'Edit',
                    DELETE: 'Delete'
                },
                EVENTS: {
                    START: 'AnnouncementUploadStart',
                    COMPLETE: 'AnnouncementUploadComplete',
                    ABORTED: 'AnnouncementUploadAborted',
                    FILEADDED: 'AnnouncementUploadFileAdded',
                    ERRORED: 'AnnouncementUploadFileErrored',
                    RETRYING: 'AnnouncementUploadFileRetrying',
                    PROGRESSCHANGE: 'AnnouncementUploadFileProgressChange',
                    GETTINGFILEIDENTIFIER: 'AnnouncementGettingFileIdentifier',
                    FILESTATECHANGED: 'AnnouncementUploadFileStateChanged'
                }
            },
            FOLDERS: {
                MODALMODES: {
                    CREATE: 'Create',
                    EDIT: 'Edit',
                    DELETE: 'Delete',
                    ASSIGN: 'Assign',
                    MULTIASSIGN: 'MultiAssign',
                    DELETEMULTI: 'DeleteMultiple'
                },
                EVENTS: {
                    REFRESHREQUIRED: 'FoldersChanged'
                }
            },
            UPLOADING: {
                EVENTS: {
                    START: 'UploadStart',
                    COMPLETE: 'UploadComplete',
                    ABORTED: 'UploadAborted',
                    FILEADDED: 'UploadFileAdded',
                    ERRORED: 'UploadFileErrored',
                    RETRYING: 'UploadFileRetrying',
                    PROGRESSCHANGE: 'UploadFileProgressChange',
                    GETTINGFILEIDENTIFIER: 'GettingFileIdentifier',
                    FILESTATECHANGED: 'UploadFileStateChanged'
                }
            },
            SETTINGS: {
                COMPONENTS: {
                    MODALS: '/Settings/SettingsModals/'
                }
            },
            CLIENTS: {
                MODALMODES: {
                    INVITE: 'Invite',
                    INVITEEXISTING: 'InviteExisting'
                },
                EVENTS: {
                    SEARCH: 'Search'
                }
            },
            REGEX: {
                EMAIL: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
                MOBILE: /^(?:\+?61|0)4(?:[01]\d{3}|(?:2[1-9]|3[0-57-9]|4[7-9]|5[0-35-9]|6[679]|7[078]|8[178]|9[7-9])\d{2}|(?:20[2-9]|444|68[3-9]|79[01]|820|901)\d|(?:200[01]|2010|8984))\d{4}$/
            }
        });
})();
