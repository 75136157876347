(function() {
    'use strict';

    angular.module('app')
        .controller('AccountsCtrl', [
            '$scope', '$q', 'accountsService', '$modal', 'constants', '_', 'confirmationModalService', 'authHelper', 'ENVIRONMENTAL', 'store',
            function ($scope, $q, accountsService, $modal, constants, _, confirmationModalService, authHelper, ENVIRONMENTAL, store) {

                var inactivateBtnLabel = 'Inactivate';
                var activateBtnLabel = 'Activate';
                $scope.title = 'Accounts';
                $scope.isDisabled = true;
                $scope.inviteDisabled = true;
                $scope.statusBtnText = inactivateBtnLabel;
                $scope.usersToDisplay = true;
                $scope.searchTerm = "";
                $scope.searchForm = {};
                $scope.previousSelection = null;
                var selectedAccount = null;

                if (store.get('profile').mfa_enabled === undefined) {
                    if (store.get('tokenAPI')) {
                        store.remove('tokenAPI');
                    }
                    if (store.get('tokenFiles')) {
                        store.remove('tokenFiles');
                    }
                    if (store.get('token')) {
                        store.remove('token');
                    }
                    if (store.get('profile')) {
                        store.remove('profile');
                    }
                    window.location.replace("https://" + ENVIRONMENTAL.AUTH0.DOMAIN + "/Manage/EnableAuthenticator?logoutOnComplete=true");
                }

                $scope.resendInvite = function() {
                    accountsService.resendInvite(selectedAccount.Id).then(function(result) {
                        $scope.showNotification("Success", "Invitation has been sent", "success");
                        refreshGrid();
                    }, function(error) {
                        $scope.showNotification("Unable to send invitation", "Failed to resend notification, please try again", "error");
                    });
                }

                $scope.resendInviteToAllUnaccepted = function() {
                    confirmationModalService.displayMessage(
                            [
                                { text: "WARNING: This action can not be undone and will affect multiple accounts on your portal.", style: "" },
                                { text: "If you continue, all Accounts on your Firm's Portal - that have not accepted their invitations - will be sent new invitations." },
                                { text: "This action will invalidate any invitations that have been sent previously." },
                                { text: "Do you wish to continue?" }
                            ],
                            "Are you sure?")
                        .then(function(result) {
                            accountsService.resendInviteToAllUnaccepted()
                                .then(function() {
                                    $scope.showNotification("Success", "Invitations have been sent", "success");
                                })
                                .catch(function(error) {
                                    $scope.showNotification("Unable to send invitations",
                                        "Failed to resend invite notifications to all unaccepted accounts, please try again",
                                        "error");
                                });
                        });
                }

                $scope.changeAccountStatus = function() {
                    accountsService.changeStatus(selectedAccount.Id, selectedAccount.IsActive).then(function(result) {
                        refreshGrid();
                    }, function(error) {
                        $scope.showNotification("Unable to change account status", "Failed to update account, please try again", "error");
                    });
                };

                $scope.deleteAccount = function() {
                    var modalInstance = createUserDetailsModal(constants.ACCOUNTS.MODALMODES.DELETE, selectedAccount);

                    modalInstance.result.then(function(result) {
                        if (result.success) {
                            accountsService.remove(result.userId).then(function(result) {
                                refreshGrid();
                            }, function (error) {
                                var errorMessage = "Failed to delete account, please try again";

                                if (error && error.data && error.data.Message) {
                                    errorMessage = error.data.Message;
                                }

                                $scope.showNotification("Unable to delete account", errorMessage, "error");
                            });
                        }
                    });
                }

                var createUserDetailsModal = function(mode, dataToPass) {
                    return $modal.open({
                        template: require('accounts\\accountDetails.html'),
                        controller: 'accountDetailsCtrl',
                        resolve: {
                            actionMode: function() {
                                return mode;
                            },
                            passedData: function() {
                                return dataToPass;
                            }
                        },
                        backdrop: true
                    });
                };

                $scope.editAccount = function() {
                    var modalInstance = createUserDetailsModal(constants.ACCOUNTS.MODALMODES.EDIT, selectedAccount);

                    modalInstance.result.then(function(result) {
                        if (result.success) {
                            $scope.showNotification("Unable to edit user", "Only the actual user can update these details in Business Fitness Identity.", "error");
                        }
                    });
                };

                $scope.viewClients = function() {

                    accountsService.getById(selectedAccount.Id).then(function(result) {
                        var modalInstance = createAccountClientDetailsModal(constants.ACCOUNTS.MODALMODES.VIEWCLIENTS, result);
                        modalInstance.result.then(function(result) {

                        });
                    });

                }

                $scope.editClients = function() {
                    accountsService.getById(selectedAccount.Id).then(function(result) {
                        var modalInstance = createAccountClientDetailsModal(constants.ACCOUNTS.MODALMODES.EDITCLIENTS, result);
                        modalInstance.result.then(function(result) {
                            accountsService.unAssignClients(result).then(function(result) {
                                if (result.Message != "An error has occurred.") {
                                    $scope.$parent.showNotification("Success!", "Operation Successful", "success");
                                    refreshGrid();
                                } else {
                                    $scope.$parent.showNotification("", "Failed to complete the operation, please try again.", "error");
                                }

                            });
                        });
                    });
                }

                var createAccountClientDetailsModal = function(mode, dataToPass) {
                    return $modal.open({
                        template: require('accounts\\AccountClientDetails.html'),
                        controller: 'AccountClientDetailsCtrl',
                        resolve: {
                            actionMode: function() {
                                return mode;
                            },
                            selection: function() {
                                return dataToPass;
                            }
                        },
                        backdrop: true
                    });
                };

                $scope.clearSearch = function () {
                    $scope.searchForm.term = "";
                    $scope.searchTerm = "";
                    refreshGrid();
                }

                $scope.searchForm.handleSearch = function () {
                    $scope.searchTerm = $scope.searchForm.term;
                    refreshGrid();
                };

                function refreshGrid() {
                    selectedAccount = null;
                    $scope.isDisabled = true;
                    return $scope.bfGridTriggers.triggerRead();
                }

                $scope.bfGridTriggers = {};

                $scope.bfGridOptions = {
                    dataSource: function (options) {
                        $scope.isLoading = true;
                        return accountsService.get($scope.searchTerm, options.data.page, options.data.pageSize, options.data.sort)
                            .then(function (dataItem) {
                                // HACK: Failed calls can still arrive in a success promise (see CP-848) so reject them
                                return dataItem.Data ? dataItem : $q.reject(dataItem);
                            })
                            .then(function (dataItem) {
                                $scope.usersToDisplay = $scope.searchTerm || (dataItem.Data && dataItem.Data.length > 0);
                                return dataItem;
                            })
                            .finally(function () {
                                $scope.progress.complete();
                                $scope.isLoading = false;
                            });
                    },
                    multiple: false,
                    fullScreen: true,
                    columns: [
                        { field: 'GivenName', title: 'Given Names' },
                        { field: 'Surname', title: 'Surname', },
                        { field: 'Email', title: 'Email', template: '<a href="mailto:#: data.Email #">#: data.Email #</a>' },
                /*        { field: 'Mobile', title: 'Mobile', width: '150px' },*/
                        { field: 'IsActive', title: 'Active', width: '100px', headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, template: '# if(IsActive){# <span class="glyphicon glyphicon-ok"></span> #}#' },
                        { field: 'InviteStatusDescription', title: 'Invitation', width: '100px', headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, template: '#= InviteToken != null ? "" : "Accepted" #' }
                    ],
                    schema: {
                        data: "Data",
                        total: "Total"
                    }
                }

                $scope.onSelectedRowChange = function (rowItem) {
                    $scope.isDisabled = false;
                    $scope.inviteDisabled = !rowItem || !rowItem.InviteToken || !rowItem.IsActive;
                    $scope.statusBtnText = rowItem && rowItem.IsActive ? inactivateBtnLabel : activateBtnLabel;
                    selectedAccount = rowItem;
                }
            }
        ]);
})();
