(function(angular) {
    'use strict';

    angular
        .module('app')
        .service('featuresService', [
            'ENVIRONMENTAL', '$http', '$q', 'auth', "logService", function(env, $http, $q, auth, logService) {
                var apiSection = '/api/features';
                var cachedFeatures = null;
                var cachedFeaturesUserId = null;

                function get() {
                    // Use the cache if it's set, and the user hasn't changed (via logout/login)
                    if (cachedFeatures && auth.profile && cachedFeaturesUserId === auth.profile.userId) {
                        return cachedFeatures;
                    }

                    cachedFeaturesUserId = auth.profile && auth.profile.userId;
                    cachedFeatures = $http.get(env.BASEURLS.API + apiSection + '/')
                        .then(applyRequestFailureFix)
                        .then(function (response) {
                            return response.data;
                        })
                        .catch(function(error) {
                            logService.error(error, "features.get failed");
                            return $q.reject(error);
                        });
                    return cachedFeatures;
                }

                function isEnabled(featureName) {
                    return get()
                        .then(function (features) {
                            if (featureName === '') {
                                return false;
                            }

                            var length = features.length >>> 0;
                            for (var i = 0; i < length; i++) {
                                if (features[i].Name === featureName && features[i].IsEnabled) {
                                    return true;
                                }
                            }
                            return false;
                        })
                        .catch(function (error) {
                            return $q.reject(error);
                        });
                }

                function applyRequestFailureFix(resp) {
                    // Workaround to turn failed calls back into failed promises
                    return !resp || !resp.status || resp.status >= 400 ? $q.reject(resp) : resp;
                }

                function clearFeaturesCache() {
                    cachedFeatures = null;
                }

                function getAvailable() {
                    return $http.get(env.BASEURLS.API + apiSection + '/available')
                        .then(applyRequestFailureFix)
                        .then(function(response) {
                            return response.data;
                        })
                        .catch(function(error) {
                            logService.error(error, "features.getAvailable failed");
                            return $q.reject(error);
                        });
                }

                function getForFirmId(firmId) {
                    return $http.get(env.BASEURLS.API + apiSection + '/forfirmid/' + firmId)
                        .then(applyRequestFailureFix)
                        .then(function(response) {
                            return response.data;
                        })
                        .catch(function(error) {
                            logService.error(error, "features.getForFirmId failed");
                            return $q.reject(error);
                        });
                }

                function assignToFirmId(firmId, featureIds) {
                    return $http.post(env.BASEURLS.API + apiSection + '/assigntofirmid/' + firmId, featureIds)
                        .then(applyRequestFailureFix)
                        .then(clearFeaturesCache)
                        .catch(function(error) {
                            logService.error(error, "features.assignToFirmId failed");
                            return $q.reject(error);
                        });
                }

                return {
                    get: get,
                    isEnabled: isEnabled,
                    clearFeaturesCache: clearFeaturesCache,
                    getAvailable: getAvailable,
                    getForFirmId: getForFirmId,
                    assignToFirmId: assignToFirmId
                }
            }
        ])
        .run([
            'featuresService', 'events', 'constants', function(featureService, events, constants) {
                events.on(constants.SECURITY.EVENTS.LOGGEDOUT, function(evt, data) {
                    featureService.clearFeaturesCache();
                });
            }
        ]);

})(window.angular);