(function() {
    "use strict";

    var module = angular.module("bf.services.employees", []);

    module.service("employeesService", [
        "$q", "_", "constants", "dataStoreService", "ENVIRONMENTAL", function($q, _, constants, dataStoreService, ENVIRONMENTAL) {
            var apiSection = "/employees/";

            function localGet(searchTerm, pageNumber, itemsPerPage, sortOptions) {
                var url = "";
                var pre = "?";
                if (searchTerm) {
                    url = pre + "searchTerm=" + encodeURIComponent(searchTerm);
                    pre = "&";
                }
                if (!_.isUndefined(pageNumber) && !_.isUndefined(itemsPerPage) && _.isNumber(pageNumber) && _.isNumber(itemsPerPage)) {
                    url += pre + "pageNumber=" + pageNumber + "&itemsPerPage=" + itemsPerPage;
                    pre = "&";
                }
                if (!_.isUndefined(sortOptions) && !_.isNull(sortOptions)) {
                    var sortDir = null;
                    var sortField = null;
                    if (_.isArray(sortOptions) && sortOptions.length > 0) {
                        sortDir = sortOptions[0].dir;
                        sortField = sortOptions[0].field;
                        url += pre + "sortDirection=" + sortDir + "&sortField=" + sortField;
                        pre = "&";
                    }
                }
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + url);
            }

            function localGetAll() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "all");
            }

            function localGetAllForFirm(firmId) {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + firmId);
            }

            function localGetActive() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "active");
            }

            function localAddNew(newEmployee) {
                var deffered = $q.defer();
                if (!_.isUndefined(newEmployee)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "newEmployee", newEmployee);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localAddExisting(existingEmployee) {
                var deffered = $q.defer();
                if (!_.isUndefined(existingEmployee)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "existingEmployee", existingEmployee);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localUpdate(id, employeeDetails) {
                var deffered = $q.defer();
                if (!_.isUndefined(employeeDetails)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id, employeeDetails);
                }
                deffered.reject();
                return deferred.promise;
            }

            function localRemove(id, subscriptionsList) {
                var deffered = $q.defer();
                if (!_.isUndefined(id) && !_.isUndefined(subscriptionsList)) {
                    var data = sortSubscriptionsList(subscriptionsList);
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "delete/" + id, data);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localGetById(id) {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
            }

            function localCheckExists(email) {
                var deffered = $q.defer();
                if (!_.isUndefined(email)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "checkExists", { email: email });
                }
                deffered.reject();
                return deffered.promise;
            }

            function localActivate(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "activate/" + id);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localInactivate(id, subscriptionsList) {
                var deffered = $q.defer();
                if (!_.isUndefined(id) && !_.isUndefined(subscriptionsList)) {
                    var data = sortSubscriptionsList(subscriptionsList);
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "inactivate/" + id, data);
                }
                deffered.reject();
                return deffered.promise;
            }

            function sortSubscriptionsList(subscriptionsList) {
                var returnVal;
                returnVal = _.map(_.difference(subscriptionsList, _.where(subscriptionsList, { client: undefined })), function (obj) { return { employeeId: obj.employee.Id, clientId: obj.client.Id } });
                returnVal = _.difference(returnVal, _.where(returnVal, { employeeId: 0 }));
                return returnVal;
            }

            function localPromote(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "promote/" + id);
                }
                deffered.reject();
                return deffered.promise;
            }
            function localDemote(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "demote/" + id);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localLoadInvite(token) {
                var deffered = $q.defer();
                if (!_.isUndefined(token)) {
                    return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "loadInvite/" + token);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localAcceptInvite(token, password) {
                var dataToSend = { inviteToken: token, password: password };
                return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "acceptInvite", dataToSend);
            }

            function localResendInvite(id) {
                var deferred = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "resendInvite/", { Id: id });
                }
                deferred.reject();
                return deferred.promise;
            }

            function localResendInviteByToken(token) {
                var deferred = $q.defer();
                if (!_.isUndefined(token)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "resendInviteByToken/", { Token: token });
                }
                deferred.reject();
                return deferred.promise;
            }

            function localResendInviteByEmail(email) {
                var deferred = $q.defer();
                if (!_.isUndefined(email)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "resendInviteByEmail/", { Email: email });
                }
                deferred.reject();
                return deferred.promise;
            }

            function localGetListOfApplicableSignatories() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "Signatories?acceptedInviteOnly=true");
            }

            function localSetDefaultRecipient(id) {
                var deferred = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "defaultRecipient/set/" + id);
                }
                deferred.reject();
                return deferred.promise;
            }
            function localRemoveDefaultRecipient(id) {
                var deferred = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "defaultRecipient/remove/" + id);
                }
                deferred.reject();
                return deferred.promise;
            }

            function localMyDetails() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "myDetails");
            }

            return {
                get: localGet,
                getAll: localGetAll,
                getById: localGetById,
                getActive: localGetActive,
                addNew: localAddNew,
                addExisting: localAddExisting,
                update: localUpdate,
                remove: localRemove,
                checkExists: localCheckExists,
                activate: localActivate,
                inactivate: localInactivate,
                promote: localPromote,
                demote: localDemote,
                loadInvite: localLoadInvite,
                acceptInvite: localAcceptInvite,
                getSignatories: localGetListOfApplicableSignatories,
                setDefaultRecipient: localSetDefaultRecipient,
                removeDefaultRecipient: localRemoveDefaultRecipient,
                resendInvite: localResendInvite,
                resendInviteByToken: localResendInviteByToken,
                resendInviteByEmail: localResendInviteByEmail,
                myDetails: localMyDetails,
                getAllForFirm: localGetAllForFirm
            };
        }
    ]);
})();
