(function() {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('FiltersCtrl', [
        '$scope', '$location', '$window', '$modal', 'filtersService', '$q', '_', 'constants', function($scope, $location, $window, $modal, filtersService, $q, _, constants) {
            $scope.selectedItem = null;
            $scope.isDisabled = true;
            $scope.treeViewOptions = {
                dataSource: new kendo.data.HierarchicalDataSource({
                    transport: {
                        read: function(options) {
                            filtersService.get()
                                .then(function(data) {
                                    options.success([{ Text: "Filters", Items: data }]);
                                    $scope.isLoading = false;
                                    $scope.progress.complete();
                                }, function(error) {
                                    $scope.isLoading = false;
                                    $scope.progress.complete();
                                });
                        }
                    },
                    schema: {
                        parse: function(response) {
                            return $.map(response, function(x) {
                                x.expanded = x.Items && x.Items.length > 0;
                                return x;
                            });
                        },
                        model: {
                            id: "Id",
                            children: "Items",
                            hasChildren: function(item) {
                                return item.Items && item.Items.length > 0;
                            }
                        }
                    }
                }),
                dataBound: function (e) {
                    if (e.node) {
                        this.expand(e.node);
                    }
                },
                dataTextField: "Text",
                loadOnDemand: false
            };

            $scope.newFilter = function() {

                var modalInstance;
                if (!$scope.selectedItem) {
                    modalInstance = createFiltersModal("create", "");
                    modalInstance.result.then(function (result) {
                        if (result.success) {
                            $scope.progress.start();
                            filtersService.add(result.text).then(function() {
                                refreshTree();
                            });
                        }
                    });
                } else {
                    var filtersItem = $scope.tree.dataItem($scope.tree.findByText("Filters"));
                    var target = $scope.tree.findByUid($scope.selectedItem.uid);
                    var targetItem = $scope.tree.dataItem(target);
                    var levelCount = 0;
                    while (targetItem != filtersItem) {
                        target = $scope.tree.parent(target);
                        targetItem = $scope.tree.dataItem(target);
                        levelCount++;
                        if (levelCount >= 3) break;
                    }
                    if (levelCount >= 3) {
                        alert("Filters are restricted to 3 levels");
                        return;
                    } else {

                        modalInstance = createFiltersModal("create", $scope.selectedItem);
                        modalInstance.result.then(function (result) {
                            if (result.success) {
                                $scope.progress.start();
                                filtersService.add(result.text, result.parentId).then(function() {
                                    refreshTree();
                                });
                            }
                        });
                    }
                }
            }

            // Function for Deleting
            $scope.deleteFilter = function() {

                if (!$scope.selectedItem) {
                    alert("Please select a filter before clicking delete");
                } else {
                    var modalInstance = createFiltersModal("delete", $scope.selectedItem);

                    modalInstance.result.then(function (result) {
                        if (result.success) {
                            $scope.progress.start();
                            filtersService.remove(result.id).then(function () {
                                refreshTree();
                            });
                        }
                    });
                }
            }

            // Function for Editing
            $scope.editFilter = function() {

                if (!$scope.selectedItem) {
                    alert("Please select a filter before clicking edit");
                } else {

                    var modalInstance = createFiltersModal("edit", $scope.selectedItem);
                    modalInstance.result.then(function (result) {
                        if (result.success) {
                            $scope.progress.start();
                            filtersService.update(result.id, result.text).then(function (data) {
                                refreshTree();
                            });
                        }
                    });


                }
            }

            $scope.itemChange = function(dataItem) {
                $scope.selectedItem = dataItem;
                $scope.isDisabled = dataItem.Text === 'Filters';
            }

            var createFiltersModal = function (mode, data) {
                return $modal.open({
                    template: require('Settings\\FiltersDetails.html'),
                    controller: 'FiltersDetailsCtrl',
                    resolve: {
                        actionMode: function () {
                            return mode;
                        },
                        passedData: function () {
                            return data;
                        }
                    }
                });
            }

            var refreshTree = function() {
                $scope.tree.dataSource.read();
                $scope.selectedItem = null;
                $scope.isDisabled = true;
            }
        }
    ]);
})();