const FileSaver = require('file-saver');
const angular = window.angular;

angular
    .module("app")
    .service("reportsService", ["$http", "$timeout", "$q", "ENVIRONMENTAL", "auth", "logService", function($http, $timeout, $q, env, auth, logService) {

        var getReportsCacheLifespan = 300000; // 5 min
        var getReportsCache = null;
        var getReportsCacheUserId = null;

        function getReports() {

            // Use the cache if it's set, and the user hasn't changed (via logout/login)
            if (getReportsCache && auth.profile && getReportsCacheUserId === auth.profile.userId) {
                return getReportsCache;
            }

            getReportsCacheUserId = auth.profile && auth.profile.userId;
            getReportsCache = $http.get(env.BASEURLS.API + "/reports")
                .then(applyRequestFailureFix)
                .then(function(resp) {
                    $timeout(function() { getReportsCache = null; }, getReportsCacheLifespan);
                    return resp.data.ReportGroups;
                })
                .catch(function(error) {
                    logService.error(error, "reportsService.getReports failed");
                    getReportsCache = null; // Don't cache failed calls
                    return $q.reject(error);
                });

            return getReportsCache;
        }

        function getReport(url) {
            return $http.get(env.BASEURLS.API + url)
                .then(applyRequestFailureFix)
                .then(function(resp) {
                    return resp.data;
                })
                .catch(function(error) {
                    logService.error(error, "reportsService.getReport failed");
                    return $q.reject(error);
                });
        }

        function downloadReport(url) {
            return $http({ url: env.BASEURLS.API + url, method: "GET", responseType: "arraybuffer" })
                .then(applyRequestFailureFix)
                .then(function (resp) {
                    var type = resp.headers("Content-Type");
                    var disposition = resp.headers("Content-Disposition");
                    var defaultFileName = "";
                    if (disposition) {
                        var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                        if (match[1])
                            defaultFileName = match[1];
                    }
                    defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, "_");
                    var blob = new Blob([resp.data], { type: "application/vnd.ms-excel;charset=charset=utf-8" });
                    FileSaver.saveAs(blob, defaultFileName);
                    return defaultFileName;
                })
                .catch(function(error) {
                    logService.error(error, "reportsService.downloadReportAsExcel failed");
                    return $q.reject(error);
                });
        }

        function applyRequestFailureFix(resp) {
            // Workaround to turn failed calls back into failed promises
            return !resp || !resp.status || resp.status >= 400 ? $q.reject(resp) : resp;
        }

        return {
            getReports: getReports,
            getReport: getReport,
            downloadReport: downloadReport
        };
    }]);