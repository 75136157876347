(function() {
    'use strict';

    var module = angular.module('bf.services.branding', []);

    module.service('brandingService', [
        '$q', '_', 'dataStoreService', '$http', '$filter', 'ENVIRONMENTAL', function ($q, _, dataStoreService, $http, $filter, ENVIRONMENTAL) {

            var apiSection = "/branding/";

            function put(details) {
                var deferred = $q.defer();

                var dataToSend = {};
                dataToSend.colour = details.colour;
                dataToSend.splashTitle = details.splashTitle;
                dataToSend.splashMsg = details.splashMsg;
                dataToSend.logo = details.logo;
                dataToSend.embed = details.embed;

                dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection, dataToSend, "PUT").then(function() {
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            function forceRefresh() {
                var deferred = $q.defer();
                dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "forceRefresh").then(function (data) {
                    deferred.resolve(data);
                }).catch(function(data) {
                    deferred.reject();
                });

                return deferred.promise;
            }

            function get() {
                var deferred = $q.defer();

                dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "details").then(function (data) {
                    var dataToReturn = {};
                    dataToReturn.splashTitle = data.SplashTitle;
                    dataToReturn.splashMsg = data.SplashMsg;
                    dataToReturn.logo = data.Logo;
                    dataToReturn.colour = data.Colour;
                    dataToReturn.embed = data.Embed;
                    dataToReturn.firm = data.Firm;
                    deferred.resolve(dataToReturn);
                });
                

                return deferred.promise;
            }

            function getPath() {
                var deferred = $q.defer();
                dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "path/" + "none").then(function (data) {
                        deferred.resolve(data);
                })
                    .catch(function(data) {
                        deferred.reject();
                    });
                return deferred.promise;
            }

            function applyBrand(storagePath) {
                // Theme
                var head = document.getElementsByTagName("head")[0];
                var link = document.createElement("link");

                link.setAttribute("href", storagePath);
                link.setAttribute("rel", "stylesheet");
                link.setAttribute("type", "text/css");
                head.appendChild(link);
            }

            return {
                apply: put,
                get: get,
                getPath: getPath,
                applyBrandCssToPage: applyBrand,
                forceRefresh: forceRefresh
            }
        }
    ]);

})();
                