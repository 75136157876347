(function () {
    "use strict";

    var appModule = angular.module("app");

    appModule.controller('DocumentsCtrl', [
        '$scope',
        '$state',
        '$stateParams',
        'documentsService',
        '_',
        'documentFiltersService',
        'events',
        'constants',
        '$q',
        '$modal',
        '$timeout',
        'momentDateTime',
        'uploadService',
        'downloadService',
        'authHelper',
        'clientsService',
        'systemSettingsService',
        'store',
        'jwtHelper',
        '$interval',
        'ENVIRONMENTAL',
        'clipboardService',
        'logService',
        function (
            $scope,
            $state,
            $stateParams,
            documentsService,
            _,
            documentFiltersService,
            events,
            constants,
            $q,
            $modal,
            $timeout,
            momentDateTime,
            uploadService,
            downloadService,
            authHelper,
            clientsService,
            systemSettingsService,
            store,
            jwtHelper,
            $interval,
            env,
            clipboardService,
            logService) {

            var clientId = $state.params.workingClientId;
            var documentId = $state.params.documentId;
            var shouldShowSigningWindow = !!$state.current.data.sign;
            var shouldShowPreviewWindow = !!$state.current.data.preview;
            var initialPopupShown = false;
            var selectedFilterId = null;
            var selectedFilter = null;
            var fileApiToken = store.get("tokenFiles");

            $scope.viewTitle = "Documents";
            $scope.searchTerm = "";
            $scope.searchForm = {};
            $scope.targetZoneVisible = false;
            $scope.noTask = true;
            $scope.disableFilterActions = true;
            $scope.isEmployeeEligibleToSign = false;
            $scope.isMultiSelect = false;
            $scope.isAdobeSignableTask = false;
            $scope.confirmInProgress = false;
            $scope.disableTasksButton = true;
            $scope.disableResendTask = false;

            $scope.selectedDocuments = [];
            $scope.selectedDocument = function () { return $scope.selectedDocuments[0]; };

            $scope.getUploadConfig = {
                headers: function () {
                    return {
                        'Authorization': "Bearer " + fileApiToken
                    };
                }
            };

            clientsService.getById(clientId)
                .then(function (client) {
                    if (client && client.Name) {
                        $scope.viewTitle += " for " + client.Name;
                    }
                });

            if (!fileApiToken || jwtHelper.isTokenExpired(fileApiToken)) {
                authHelper.getDelegatedToken(authHelper.getFileApiDelegationOptions())
                    .then(function (token) {
                        fileApiToken = token;
                        store.set("tokenFiles", token);
                    });
            }

            function gridDataBoundHandler(e) {
                if (documentId && !initialPopupShown) {
                    initialPopupShown = true;
                    var doc = e.sender.dataSource.data()[0];

                    documentsService.getById(doc.Id)
                        .then(function (result) {
                            doc = result;
                            if (doc) {
                                if (shouldShowSigningWindow) {
                                    showSigningWindow(doc);
                                }
                                if (shouldShowPreviewWindow) {
                                    showPreviewWindow(doc);
                                }
                            } else {
                                $scope.showNotification("Error", "Requested document not found. Please check the link you used is correct.", "error");
                            }
                        })
                        .catch(function (error) {
                        });
                }
            }

            function setCopyLink() {
                $scope.selectedFileDataLink = {
                    TextDocumentLink: "",
                    HtmlDocumentLink: ""
                };
                if (_.isArray($scope.selectedDocuments)) {
                    $scope.selectedDocuments.forEach(function (item, index) {
                        var documentFile = item;
                        documentsService.getDocumentLink(documentFile.Id)
                            .then(function (data) {
                                if (data.TextDocumentLink && data.TextDocumentLink.length > 0) {
                                    if ($scope.selectedFileDataLink.TextDocumentLink === "") {
                                        $scope.selectedFileDataLink = data;
                                    } else {
                                        $scope.selectedFileDataLink.HtmlDocumentLink += "\r\n" + data.HtmlDocumentLink;
                                        $scope.selectedFileDataLink.TextDocumentLink += "\r\n" + data.TextDocumentLink;
                                    }
                                } else {
                                    $scope.$parent.showNotification("Error", "Error while setting link value for the selected document. Please contact support.", "error");
                                }
                            })
                            .catch(function (err) {
                                logService.error(err, "Set link error for Document Id " + documentFile.Id);
                            });
                    });
                } else {
                    var document = $scope.selectedDocuments;
                    documentsService.getDocumentLink(document.Id)
                        .then(function (data) {
                            if (data.TextDocumentLink && data.TextDocumentLink.length > 0) {
                                $scope.selectedFileDataLink = data;
                            } else {
                                $scope.$parent.showNotification("Error", "Error while setting link value for the selected document. Please contact support.", "error");
                            }
                        })
                        .catch(function (err) {
                            logService.error(err, "Set link error for Document Id " + document.Id);
                        });
                }
            }

            function showSigningWindow(doc) {
                if (doc.EmployeeToSign) {
                    if (!doc.UsersToSign || !doc.UsersToSign.length) {
                        $scope.selectedDocuments = [doc];
                        setCopyLink();
                        $scope.startSigning();
                    } else {
                        $scope.showNotification("Error", "Not all require accounts have signed this document yet.", "error");
                    }
                }
                else {
                    if (doc.DocumentAdobeSigningUsers.length > 0) {
                        for (var i = 0; i < doc.DocumentAdobeSigningUsers.length; i++) {

                            if (!doc.DocumentAdobeSigningUsers) {
                                continue;
                            }

                            if (!doc.DocumentAdobeSigningUsers[i].Employee) {
                                continue;
                            }

                            var documentIsForCurrentUser = doc.DocumentAdobeSigningUsers[i].Employee.Id === $scope.auth.profile.portalUserID;
                            var currentUserHasAlreadySigned = !!doc.DocumentAdobeSigningUsers[i].SigningTime;
                            
                            if (documentIsForCurrentUser && !currentUserHasAlreadySigned) {
                                    $scope.selectedDocuments = [doc];
                                    setCopyLink();
                                    $scope.startSigning();
                            }
                        }
                    }
                }
            }

            function showPreviewWindow(doc) {
                isDocPreviewable(doc)
                    .then(function (result) {
                        if (result) {
                            $scope.selectedDocuments = [doc];
                            setCopyLink();
                            $scope.previewDocument();
                        } else {
                            $scope.showNotification("Error", "The document you have requested to preview is not a supported file type.", "error");
                        }
                    });
            }

            $scope.showTargetZone = function () {
                $scope.targetZoneVisible = true;
            };

            $scope.hideTargetZone = function () {
                $scope.targetZoneVisible = false;
            };

            $scope.viewAuditTrail = function () {
                var selectedDocs = $scope.selectedDocuments;
                $modal.open({
                    template: require('Documents\\DocumentAuditTrail.html'),
                    controller: "DocumentAuditTrailCtrl as vm",
                    resolve: {
                        documentId: function() {
                            return $scope.selectedDocument().Id;
                        }
                    }
                });
            };

            var clearDeepLinkedDocument = function () {
                documentId = null;
            };

            $scope.deleteDocument = function () {
                var selectedDocs = $scope.selectedDocuments;
                $modal.open({
                    template: require('Documents\\DocumentsDelete.html'),
                    controller: "DocumentsDeleteCtrl",
                    resolve: {
                        file: function () {
                            return selectedDocs.length > 1 ? selectedDocs : selectedDocs[0];
                        },
                        actionMode: function () {
                            return selectedDocs.length > 1 ? constants.FOLDERS.MODALMODES.DELETEMULTI : constants.FOLDERS.MODALMODES.DELETE;
                        },
                        selectedCount: function () {
                            return selectedDocs.length;
                        },
                        notOwnedByCount: function () {
                            return 0;
                        }
                    }
                }).result
                    .then(function (result) {
                        if (result) {
                            $scope.progress.start();
                            
                            var itemIds = selectedDocs.map(function(item) {
                                return item.Id;
                            });

                            var getDocument = function(id) {
                                for (var i=0; i<selectedDocs.length; i++) {
                                    if (selectedDocs[i].Id === id) {
                                        return selectedDocs[i];
                                    }
                                }

                                return undefined;
                            }

                            documentsService.removeMultiple(itemIds).then(function(deletionResults) {
                                var documentsWithOutstandingTasks = [];
                                var documentsWithOtherFailures = [];

                                for (var i=0; i <deletionResults.length; i++) {
                                    var deletionResult = deletionResults[i];

                                    if (deletionResult.Result === "outstandingsigntasks") {
                                        documentsWithOutstandingTasks.push(getDocument(deletionResult.DocumentId));
                                    } else if (deletionResult.Result === "notfound") {
                                        continue; // Another user has since successfully deleted the document
                                    } else if (deletionResult.Result) {
                                        documentsWithOtherFailures.push(getDocument(deletionResult.DocumentId));
                                    }
                                }

                                if (documentsWithOtherFailures.length > 0 || documentsWithOutstandingTasks.length > 0) {
                                    var modalInstance = $modal.open({
                                        template: require('documents\\DocumentsFailedToDelete.html'),
                                        controller: function ($scope, $modalInstance, documentsWithOutstandingTasks, documentsWithOtherFailures) {
                                            $scope.confirm = function() {
                                                $modalInstance.close();
                                            };

                                            $scope.documentsWithOutstandingTasks = documentsWithOutstandingTasks;
                                            $scope.documentsWithOtherFailures = documentsWithOtherFailures;
                                        },
                                        resolve: {
                                            documentsWithOutstandingTasks: function() {
                                                return documentsWithOutstandingTasks
                                            },
                                            documentsWithOtherFailures: function() {
                                                return documentsWithOtherFailures;
                                            }
                                        },
                                        backdrop: true
                                    });

                                    return modalInstance.result;
                                }
                            })
                            .catch(function (rejection) {
                                // 500 error, can't contact server etc
                                $scope.showNotification("Error", "Document deletion failed, please try again.", "error");
                                logService.error(rejection.status, "Document delete error");
                                return $q.reject(rejection.status);
                            })
                            .finally(function () {
                                clearDeepLinkedDocument();
                                refreshGrid();
                                $scope.progress.complete();
                            });
                        }
                    });
            };

            $scope.downloadDocument = function() {
                $scope.progress.start();
                $q.all($scope.selectedDocuments.map(function (item) {
                    return downloadService.downloadFile(item.Id, item.Title);
                }))
                    .catch(function (e) {
                        $scope.showNotification("Download Error", "Failed to initialize download, please try again.", "error");
                        logService.error(e, "Download error");
                    })
                    .finally(function () {
                        $scope.progress.complete();
                    });
            };

            $scope.copyDocumentLink = function () {
                clipboardService.setClipboardData($scope.selectedFileDataLink.TextDocumentLink, $scope.selectedFileDataLink.HtmlDocumentLink)
                    .then(function() {
                        $scope.showNotification("Success!", "Link copied to clipboard", "success");
                    })
                    .catch(function () {
                        $scope.$parent.showNotification("Copy Link Error", "Failed to copy link to clipboard for the document selection, please try again.", "error");
                    })
                    ;
            }

            function isDocPreviewable(doc) {
                return systemSettingsService.getPreviewableFileExtensions()
                    .then(function (extensions) {
                        return _.indexOf(extensions, doc.Extension.toUpperCase()) !== -1;
                    })
                    .catch(function (e) {
                        return false;
                    });
            }

            function toHex(n) {
                n = parseInt(n, 10);
                if (isNaN(n)) return "00";
                n = Math.max(0, Math.min(n, 255));
                return "0123456789ABCDEF".charAt((n - n % 16) / 16)
                     + "0123456789ABCDEF".charAt(n % 16);
            }

            $scope.previewDocument = function () {
                var doc = $scope.selectedDocument();
                var branding = $("header.row").css("background-color");
                if (branding.length > 6 && branding.substr(0, 3) === "rgb") {
                    var rgb = branding.replace(/[^\d,.]/g, "").split(",");
                    branding = toHex(rgb[0]) + toHex(rgb[1]) + toHex(rgb[2]);
                }
                $modal.open({
                    template: require('Documents\\DocumentsPreview.html'),
                    controller: "DocumentsPreviewCtrl",
                    resolve: {
                        fileId: function () {
                            return doc.Id;
                        },
                        fileTitle: function () {
                            return doc.Title;
                        },
                        brandingColour: function () {
                            return branding;
                        }
                    },
                    size: "lg"
                });
            };

            $scope.removeTask = function() {
                var doc = $scope.selectedDocument();
                switch (doc.ActionTypeE) {
                case constants.DOCUMENTS.ACTIONTYPES.NONE:
                    $scope.showNotification("No Task!", "This document has no tasks to be removed", "error");
                    break;
                case constants.DOCUMENTS.ACTIONTYPES.SIGNED:
                    $scope.showNotification("Already Signed!", "A Signed document cannot have its status removed", "error");
                    break;
                default:
                    documentsService.removeTask(doc.Id)
                        .then(function (result) {
                            if (result.Message === "An error has occurred.") {
                                $scope.showNotification("Error!", "Something went wrong on the server please try again", "error");
                            } else {
                                $scope.showNotification("Success!", "Task was removed from " + doc.Title, "success");
                                refreshGrid();
                            }
                        });
                }
            };

            $scope.resendPin = function () {
                var doc = $scope.selectedDocument();
                switch (doc.ActionTypeE) {
                case constants.DOCUMENTS.ACTIONTYPES.NONE:
                    $scope.showNotification("No Task Assign", "This document has no tasks assigned to it, unable to resend pin notification", "error");
                    break;
                case constants.DOCUMENTS.ACTIONTYPES.SIGNED:
                    $scope.showNotification("Signing Complete", "A Signed document cannot have its pin notifications resent", "error");
                    break;
                default:
                    documentsService.resendPinNotificationsToOutstandingSignatories(doc.Id)
                        .then(function (theResult) {
                            if (theResult === true) {
                                $scope.showNotification("Success!", "Pin notifications resent to outstanding signatories for " + doc.Title, "success");
                            } else {
                                $scope.showNotification("Error!", "Failed to resend pin notifications to outstanding signatories for " + doc.Title + ", please try again", "error");
                            }
                        })
                        .catch(function (theError) {
                            if (theError.data.Message === "Notification are not enabled for this document") {
                                $scope.showNotification("Notifications disabled", "Notifications are turned off for '" + doc.Title + "', please use the Copy Link button and send an email manually", "error");
                            } else {
                                $scope.showNotification("Error!", "Failed to resend pin notifications to outstanding signatories for " + doc.Title + ", please try again", "error");
                                logService.error(theError, "Resending Pin(s) Error for Document Id " + doc.Id);
                            }
                        });
                }
            };

            $scope.resendAdobeSign = function () {
                var doc = $scope.selectedDocument();
                switch (doc.ActionTypeE) {
                    case constants.DOCUMENTS.ACTIONTYPES.NONE:
                        $scope.showNotification("No Task Assign", "This document has no tasks assigned to it, unable to resend pin notification", "error");
                        break;
                    case constants.DOCUMENTS.ACTIONTYPES.SIGNED:
                        $scope.showNotification("Signing Complete", "A Signed document cannot have its pin notifications resent", "error");
                        break;
                    case constants.DOCUMENTS.ACTIONTYPES.ADOBESIGN:
                    case constants.DOCUMENTS.ACTIONTYPES.ADOBESECURESIGN:
                        documentsService.resendAdobeSignNotificationsToOutstandingSignatories(doc.Id)
                            .then(function (result) {
                                if (result.Message === "An error has occurred.") {
                                    $scope.showNotification("Error!", "Failed to resend Adobe Sign notifications to outstanding signatories for " + doc.Title + ", please try again", "error");
                                } else {
                                    $scope.showNotification("Success!", "Adobe Sign notifications resent to outstanding signatories for " + doc.Title, "success");
                                }
                            })
                            .catch(function (theError) {
                                if (theError.data.Message === "Notification are not enabled for this document") {
                                    $scope.showNotification("Notifications disabled", "Notifications are turned off for '" + doc.Title + "', please use the Copy Link button and send an email manually", "error");
                                } else {
                                    $scope.showNotification("Error!", "Failed to resend Adobe Sign notifications to outstanding signatories for " + doc.Title + ", please try again", "error");
                                    logService.error(theError, "Resending Adobe Sign notifications Error for Document Id " + doc.Id);
                                }
                            });
                        break;
                    default:
                        $scope.showNotification("No Adobe Sign Task", "This document has no Adobe Sign task assigned to it, unable to resend Adobe Sign notification", "error");
                        break;
                }
            };

            var checkCanShowSignModal = function (doc) {
                switch (doc.ActionTypeE) {
                case constants.DOCUMENTS.ACTIONTYPES.NONE:
                    return true;
                case constants.DOCUMENTS.ACTIONTYPES.SIGNED:
                    $scope.showNotification("Operation already complete!", "This document has already been signed", "error");
                    return false;
                default:
                    $scope.showNotification("Operation already in progress!", "This document has already been flagged for signing", "error");
                    return false;
                }
            };

            function showSignModal(type) {
                var doc = $scope.selectedDocument();
                if (!preShowSignModal(doc)) return;
                $modal.open({
                    template: require('Documents\\SigningPrechecks\\SigningPrechecks.html'),
                    controller: "SigningPrechecksCtrl",
                    keyboard: false,
                    backdrop: "static",
                    resolve: {
                        selectedDoc: function() {
                            return doc;
                        },
                        signingProcessType: function() {
                            return type;
                        }
                    }
                }).result.then(refreshGrid);
            }

            function preShowSignModal(doc) {
                if (!doc) {
                    $scope.showNotification("Oops!", "Please select a document first.", "error");
                    return false;
                }
                if (!checkCanShowSignModal(doc)) {
                    return false;
                }
                if (doc.Extension.toLowerCase() !== ".pdf") {
                    $scope.showNotification("Invalid document format", "Only pdf format documents are supported by the signing process.", "error");
                    return false;
                }
                return true;
            }

            $scope.prepareSignDocument = function () {
                showSignModal("clicktosign");
            };

            $scope.requestMultipleSignatures = function () {
                showSignModal("securesign");
            };

            $scope.requestAdobeSign = function () {
               
                var doc = $scope.selectedDocument();
                if (!preShowSignModal(doc)) return;

                $modal.open({
                    template: require('Documents\\AdobeSign\\AdobeSignPrechecks.html'),
                    controller: "AdobeSignPrechecksCtrl",
                    keyboard: false,
                    backdrop: "static",
                    resolve: {
                        selectedDoc: function () {
                            return doc;
                        }
                    }
                }).result
                .then(function (result) {
                    if (result != null && result.reason === "finished" && result.message != null && result.message.length > 0) {
                        $scope.showNotification("Success!", result.message + " for document " + doc.Title, "success");
                    }
                    refreshGrid();
                })
                    .catch(function (err) {
                    if (err != null && err.reason !== "cancel") {
                        $scope.showNotification("Error", "The created agreement could not be opened for sending. Please contact your system administrator.", "error");
                    }
                });
            };

            function sleep(time) {
                return new Promise((resolve) => setTimeout(resolve, time));
            }

            var gridIsRefreshed = false;

            function refreshSignatureGrid(docId) {
                if (gridIsRefreshed) {
                    return;
                }

                var doc = documentsService.getById(docId).then(function (doc) { return { Data: [doc], Total: 1 }; });

                doc.then(function (response) {
                    var document = response.Data[0];

                    for (var i = 0; i < document.DocumentAdobeSigningUsers.length; i++) {

                        if (!doc.DocumentAdobeSigningUsers) {
                            continue;
                        }

                        if (!doc.DocumentAdobeSigningUsers[i].Employee) {
                            continue;
                        }

                        var documentIsForCurrentUser = doc.DocumentAdobeSigningUsers[i].Employee.Id === $scope.auth.profile.portalUserID;

                        if (!documentIsForCurrentUser) {
                            continue;
                        }

                        var currentUserHasAlreadySigned = !!document.DocumentAdobeSigningUsers[i].SigningTime;

                        if (!currentUserHasAlreadySigned) {
                            return;
                        }
                    }
                });

                $scope.bfGridOptions = {
                    dataSource: function (options) {
                        return doc;
                    },
                    dataBound: gridDataBoundHandler,
                    multiple: true,
                    fullScreen: true,
                    columns: [
                        { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "Extension", title: " ", width: "50px", template: kendo.template($("#iconTemplate").html()) },
                        { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "Task", title: "Task", width: "100px", template: "<documents-list-task task='${Task}' title='${Title}' document-id='${Id}' />" },
                        { headerAttributes: { "class": "grid-center" }, field: "Title", title: "Title", width: "200px", template: "<span title='${Title}'>${Title}</span>" },
                        { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "CreatedBy", title: "Uploaded By", width: "150px", template: fieldTemplate("CreatedBy") },
                        { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "EnabledNotifications", title: "Notifications", width: "65px", template: kendo.template($("#notificationTemplate").html()) },
                        { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "ModifiedDate", title: "Date Modified", width: "85px", type: "date", template: kendo.template($("#dateModifiedToLocal").html()) }
                    ],
                    schema: {
                        data: "Data",
                        total: "Total"
                    }
                };
                refreshGrid();
                gridIsRefreshed = true;
            }

            function captureCurrentLocationAndNavigateToAdobeSign(docId) {
                $scope.progress.reset();
                $scope.progress.start();
                
                documentsService.getAdobeSignSigningUrl(docId)
                    .then(function (adobeSignUrl) {
                        if (adobeSignUrl === '') {
                            gridIsRefreshed = false;
                            sleep(10000).then(() => {
                                refreshSignatureGrid(docId);
                            });
                            sleep(20000).then(() => {
                                refreshSignatureGrid(docId);
                            });
                            sleep(30000).then(() => {
                                refreshSignatureGrid(docId);
                            });
                            sleep(40000).then(() => {
                                refreshSignatureGrid(docId);
                            });
                        } else {
                            // Capture our current location so that we come back here after signing is complete
                            var locCaptureUrl = env.BASEURLS.API + "/api/adobesign/agreements/setreturn?url=" + encodeURIComponent(window.location);
                            var i = document.createElement("iframe");
                            i.style.display = "none";
                            i.onload = function () {
                                window.location = adobeSignUrl;
                            };
                            i.src = locCaptureUrl;
                            document.body.appendChild(i);
                        }
                    })
                    .catch(function (err) {
                        $scope.showNotification("Error", "The requested document could not be opened for signing. Please contact your system administrator.", "error");
                    })
                    .finally(function() {
                        $scope.progress.complete();
                    });
            }

            $scope.startSigning = function () {
               
                var doc = $scope.selectedDocument();
                if (doc && (doc.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.ADOBESIGN || doc.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.ADOBESECURESIGN)) {
                    captureCurrentLocationAndNavigateToAdobeSign(doc.Id);
                    return;
                }

                if (doc && doc.ActionType === constants.DOCUMENTS.ACTIONTYPES.SIGNED) {
                    return;
                }

                $modal.open({
                    template: require('Documents\\MultipleSignatures\\VerifyPinBeforeSigningModal.html'),
                    controller: "VerifyPinCtrl",
                    resolve: {
                        selectedDoc: function () {
                            return doc;
                        },
                        renew: function () {
                            return false;
                        }
                    }
                });
            };

            $scope.treeViewOptions = {
                dataSource: new kendo.data.HierarchicalDataSource({
                    transport: {
                        read: function (options) {
                            documentFiltersService.get(clientId)
                                .then(function (data) {
                                    options.success([{ Text: "Filters", Items: data }]);
                                })
                                .finally(function () {
                                    $scope.progress.complete();
                                });
                        }
                    },
                    schema: {
                        parse: function (response) {
                            return $.map(response, function (x) {
                                x.expanded = x.Items && x.Items.length > 0;
                                return x;
                            });
                        },
                        model: {
                            id: "Id",
                            children: "Items",
                            hasChildren: function (item) {
                                return item.Items && item.Items.length > 0;
                            }
                        }
                    }
                }),
                dataBound: function (e) {
                    if (e.node) {
                        this.expand(e.node);
                    }
                },
                dataTextField: "Text",
                loadOnDemand: false
            };

            var createFilterModal = function (mode, dataToPass) {
                return $modal.open({
                    template: require('Documents\\DocumentsFilterDetails.html'),
                    controller: "DocumentsFilterDetailsCtrl",
                    resolve: {
                        actionMode: function () {
                            return mode;
                        },
                        passedData: function () {
                            return dataToPass;
                        }
                    }
                });
            };

            $scope.createNewFolder = function () {
                if (selectedFilter) {
                    var filtersItem = $scope.tree.dataItem($scope.tree.findByText("Filters"));
                    var target = $scope.tree.findByUid(selectedFilter.uid);
                    var levelCount = 0;
                    while ($scope.tree.dataItem(target) !== filtersItem) {
                        target = $scope.tree.parent(target);
                        levelCount++;
                        if (levelCount >= 3) {
                            alert("Filters are restricted to 3 levels");
                            return;
                        };
                    }
                }
                createFilterModal(constants.FOLDERS.MODALMODES.CREATE, selectedFilter || null).result
                    .then(function (result) {
                        if (result.success) {
                            $scope.progress.start();
                            documentFiltersService.add(clientId, result.text, result.parentId).then(refreshTree);
                        }
                    });
            };

            $scope.renameFolder = function () {
                if (!selectedFilter) {
                    alert("Please select a filter before clicking rename");
                    return;
                }
                if (selectedFilter.Filter) {
                    $scope.showNotification("Permission Denied", "Firm filters may only be updated globally from the settings, filters menu.", "error");
                    return;
                }
                createFilterModal(constants.FOLDERS.MODALMODES.EDIT, selectedFilter).result
                    .then(function (result) {
                        if (result.success) {
                            documentFiltersService.update(result.id, result.text).then(refreshTree);
                        }
                    });
            };

            $scope.deleteFolder = function () {
                if (!selectedFilter) {
                    alert("Please select a filter before clicking edit");
                    return;
                }
                if (selectedFilter.Filter) {
                    $scope.showNotification("Permission Denied", "Firm filters may only be removed globally from the settings, filters menu.", "error");
                    return;
                }
                createFilterModal(constants.FOLDERS.MODALMODES.DELETE, selectedFilter).result
                    .then(function (result) {
                        if (result.success) {
                            documentFiltersService.remove(result.id).then(refreshTree);
                        }
                    });
            };

            $scope.assignFolder = function() {
                if (!$scope.selectedDocument()) {
                    alert("Please select a document before trying to assign filters.");
                    return;
                }
                $scope.progress.start();
                documentFiltersService.get(clientId)
                    .then(function (result) {
                        var isMulti = $scope.selectedDocuments.length > 1;
                        var mode = isMulti ? constants.FOLDERS.MODALMODES.MULTIASSIGN : constants.FOLDERS.MODALMODES.ASSIGN;
                        var modalData = isMulti ? result : { doc: $scope.selectedDocument(), filters: result };
                        return createFilterModal(mode, modalData).result;
                    })
                    .then(function (result) {
                        return result.success ? $q.when(result.filter) : $q.reject();
                    })
                    .then(function (filter) {
                        return $q.all($scope.selectedDocuments
                                      .filter(function (doc) {
                                          return !doc.DocumentFilter || doc.DocumentFilter.Id !== filter.Id;
                                      })
                                      .map(function (doc) {
                                          return documentsService.assignFilter(doc.Id, filter.Id);
                                      }));
                    })
                    .then(function () {
                        refreshTree();
                        return refreshGrid();
                    })
                    .finally(function () {
                        $scope.progress.complete();
                    });
            };

            $scope.fileUploadProgress = function (flowFile) {
                uploadService.updateProgress(flowFile.Id, Math.floor(flowFile.progress() * 100));
            };

            $scope.fileUploadSuccess = function (flowFile) {
                uploadService.commitFile(flowFile.Id)
                    .then(function () {
                        var fileMetaData = {
                            name: flowFile.file.name,
                            size: flowFile.file.size,
                            uploadIdentifier: flowFile.Identifier,
                            folderId: flowFile.folderId
                        };
                        return documentsService.add(clientId, fileMetaData);
                    })
                    .then(function (result) {
                        if (result.Message == "bad move" || result.Message == "no client" || result.Message == "An error has occurred.") {
                            uploadService.errored(flowFile.Id);
                        } else {
                            uploadService.completed(flowFile.Id);
                            refreshGrid();
                        }
                    })
                    .catch(function () {
                        uploadService.errored(flowFile.Id);
                    });
            };

            $scope.fileUploadError = function(flowFile) {
                uploadService.errored(flowFile.Id);
            };

            $scope.fileUploadRetrying = function (flowFile) {
                uploadService.retrying(flowFile.Id);
            };

            $scope.onFileSelect = function (files) {
                if (files.length > 0) {
                    _.each(files, function(file) {
                        file.folderId = selectedFilterId;
                    });
                    uploadService.addFiles(files)
                        .then(function (fileIds) {
                            uploadService.startAllUploads();
                        })
                        .catch(function (reason) {
                            console.log(reason);
                        });
                }
            }

            function refreshTree() {
                $scope.tree.dataSource.read();
                selectedFilterId = null;
                selectedFilter = null;
            }

            $scope.treeitemChange = function (dataItem) {
                clearDeepLinkedDocument();
                selectedFilter = dataItem;
                selectedFilterId = dataItem.Id;
                $scope.disableFilterActions = dataItem.Text === "Filters";
                refreshGrid();
            };

            $scope.clearSearch = function () {
                clearDeepLinkedDocument();
                $scope.searchForm.term = "";
                $scope.searchTerm = "";
                refreshGrid();
            };

            $scope.searchForm.handleSearch = function () {
                clearDeepLinkedDocument();
                $scope.searchTerm = $scope.searchForm.term;
                refreshGrid();
            };

            function refreshGrid() {
                $scope.noTask = true;
                $scope.isAdobeSignTask = false;
                $scope.isEmployeeEligibleToSign = false;
                $scope.disableTasksButton = true;
                $scope.canPreviewSelectedDocument = false;
                $scope.isAdobeSignableTask = false;
                $scope.isMultiSelect = false;
                $scope.selectedDocuments = [];
                return $scope.bfGridTriggers.triggerRead();
            }

            $scope.bfGridTriggers = {};

            var fieldTemplate = function(dtoFieldName) {
                return function(dto) {
                    var fieldValue = dto[dtoFieldName];
                    if (fieldValue === null || fieldValue === undefined) {
                        fieldValue = "";
                    }

                    var elem = document.createElement("span");
                    elem.setAttribute("title", fieldValue);
                    elem.textContent = fieldValue;
                    return elem.outerHTML;
                }
            }

            $scope.bfGridOptions = {
                dataSource: function (options) {
                    return (!!documentId
                            ? documentsService.getById(documentId).then(function (doc) { return { Data: [doc], Total: 1 }; })
                            : documentsService.get(clientId, selectedFilterId, $scope.searchTerm, options.data.page, options.data.pageSize, options.data.sort));
                },
                dataBound: gridDataBoundHandler,
                multiple: true,
                fullScreen: true,
                columns: [
                    { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "Extension", title: " ", width: "50px", template: kendo.template($("#iconTemplate").html()) },
                    { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "Task", title: "Task", width: "100px", template: "<documents-list-task task='${Task}' title='${Title}' document-id='${Id}' />" },
                    { headerAttributes: { "class": "grid-center" }, field: "Title", title: "Title", width: "200px", template: "<span title='${Title}'>${Title}</span>" },
                    { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "CreatedBy", title: "Uploaded By", width: "150px", template: fieldTemplate("CreatedBy") },
                    { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "EnabledNotifications", title: "Notifications", width: "65px", template: kendo.template($("#notificationTemplate").html()) },
                    { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "ModifiedDate", title: "Date Modified", width: "85px", type: "date", template: kendo.template($("#dateModifiedToLocal").html()) }
                ],
                schema: {
                    data: "Data",
                    total: "Total"
                }
            };

            $scope.onSelectedRowChange = function (rowItems) {
                $scope.selectedDocuments = rowItems;
                $scope.canPreviewSelectedDocument = false;
                $scope.isEmployeeEligibleToSign = false;
                $scope.disableTasksButton = true;
                $scope.noTask = !rowItems.length;
                $scope.isAdobeSignTask = false;
                $scope.isAdobeSignableTask = false;
                $scope.isMultiSelect = rowItems.length > 1;
                $scope.disableResendTask = false;

                if (rowItems.length === 1) {
                    var doc = $scope.selectedDocument();
                    $scope.disableTasksButton = doc.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.SIGNED;
                    $scope.noTask = (doc.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.NONE);
                    $scope.isAdobeSignTask = (doc.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.ADOBESIGN || doc.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.ADOBESECURESIGN);
                    $scope.isEmployeeEligibleToSign = (!doc.UsersToSign || !doc.UsersToSign.length) && doc.EmployeeToSign != null;
                    $scope.disableResendTask = (doc.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.DECLINED);
                    isDocPreviewable(doc)
                        .then(function (result) {
                            $scope.canPreviewSelectedDocument = result;
                        });
                    setIsEligibleToSignAdobeSign(doc);
                }
                setCopyLink();
            };

            function setIsEligibleToSignAdobeSign(doc) {
                if (doc.ActionTypeE !== constants.DOCUMENTS.ACTIONTYPES.ADOBESIGN && doc.ActionTypeE !== constants.DOCUMENTS.ACTIONTYPES.ADOBESECURESIGN) return;
                $scope.confirmInProgress = true;
                documentsService.getIsEligibleForAdobeSign(doc.Id)
                    .then(function (isSignable) {
                        $scope.isAdobeSignableTask = isSignable;
                    })
                    .finally(function () {
                        $scope.confirmInProgress = false;
                    });
            }

            // This is the way some of the modals tell the grid to refresh. Ideally they should
            // be using promise chaining so we could just refresh when the promise completes
            // successfully, but that's a refactor for another time.
            var refreshRequired = events.on(constants.DOCUMENTS.EVENTS.REFRESHREQUIRED, function () {
                refreshGrid();
            });

            $scope.$on("$destroy", function () {
                refreshRequired();
            });
        }
    ]);
    appModule.directive("documentsListTask", ["$modal", function($modal) {
        return {
            restrict: "E",
            scope: {
                title: "@",
                documentId: "@",
                task: "@"
            },
            link: function(scope) {
                var getModalInstance = function() {
                    return scope.modalInstance;
                };

                scope.isAdobeSignTask = scope.task && scope.task.indexOf("Adobe") === 0;

                scope.showAdobeSignTaskSignatoriesModal = function() {
                    scope.modalInstance = $modal.open({
                        template: require('Documents\\AdobeSign\\AdobeSignTaskSignatoriesModal.html'),
                        controller: "AdobeSignTaskSignatoriesModalCtrl",
                        resolve: {
                            documentId: function() {
                                return scope.documentId
                            },
                            documentTitle: function() {
                                return scope.title
                            },
                            getModalInstance: function() {
                                return getModalInstance;
                            }
                        }
                    });
                };
            },
            template: "<span ng-if='!isAdobeSignTask' title='{{task}}'>{{task}}</span><a ng-if='isAdobeSignTask' ng-click='showAdobeSignTaskSignatoriesModal(documentId, title)' title='{{task}}' style='cursor: pointer;'>{{task}} <i class='fa fa-external-link' aria-hidden='true'></i></a>"
        }
    }])
})();
