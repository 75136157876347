(function(angular) {
    "use strict";
    var module = angular.module("bf.services.documents.user", []);

    module.service("documentsService", [
        "_", "$q", "$timeout", "momentDateTime", "events", "constants", "dataStoreService", "$http", "ENVIRONMENTAL", "logService", function (_, $q, $timeout, momentDateTime, events, constants, dataStoreService, $http, ENVIRONMENTAL, logService) {
            var apiSection = "/documents/";

            function localGet(id, filterId, searchTerm, pageNumber, itemsPerPage, sortOptions) {
                var url = ENVIRONMENTAL.BASEURLS.API + apiSection + "clients/" + id;
                var pre = "?";
                if (!_.isUndefined(filterId) && filterId != null) {
                    url += pre + "filterId=" + filterId;
                    pre = "&";
                }
                if (!_.isUndefined(searchTerm) && searchTerm != "") {
                    url += pre + "searchTerm=" + encodeURIComponent(searchTerm);
                    pre = "&";
                }
                if (!_.isUndefined(pageNumber) && !_.isUndefined(itemsPerPage) && _.isNumber(pageNumber) && _.isNumber(itemsPerPage)) {
                    url += pre + "pageNumber=" + pageNumber + "&itemsPerPage=" + itemsPerPage;
                    pre = "&";
                }

                if (!_.isUndefined(sortOptions) && !_.isNull(sortOptions)) {
                    var sortDir = null;
                    var sortField = null;
                    if (_.isArray(sortOptions) && sortOptions.length > 0) {
                        sortDir = sortOptions[0].dir;
                        sortField = sortOptions[0].field;
                        url += pre + "sortDirection=" + sortDir + "&sortField=" + sortField;
                        pre = "&";
                    }
                }
                return dataStoreService.getResource(url);
            }

            function localGetById(id) {
                var url = ENVIRONMENTAL.BASEURLS.API + apiSection + id;
                return dataStoreService.getResource(url);
            }

            function localRemove(documentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    return dataStoreService.deleteResource(ENVIRONMENTAL.BASEURLS.API + apiSection + documentId);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localRemoveMultiple(documentIds) {
                return $http.post(ENVIRONMENTAL.BASEURLS.API + apiSection + "/removeMultiple", {
                    documentIds: documentIds
                }).then(function(response) {
                    return response.data;
                });
            }

            function localGetDownload(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.FILEAPI.BASEURL + "/api/download/get", { Id: id });
                }
                deffered.reject();
                return deffered.promise;
            }

            function localGetDownloadForPreview(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.FILEAPI.BASEURL + "/api/download/getForPreview", { Id: id });
                }
                deffered.reject();
                return deffered.promise;
            }

            function localAdd(clientId, fileMetaData) {
                var deffered = $q.defer();
                if (!_.isUndefined(fileMetaData)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "clients/" + clientId, fileMetaData);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localSign(documentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + documentId + "/sign");
                }
                deffered.reject();
                return deffered.promise;
            }

            function localAssignFilter(documentId, filterId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "assignFilter", { documentId: documentId, filterId: filterId});
                }
                deffered.reject();
                return deffered.promise;
            }

            function localRequestMultipleSignatures(documentId, theDataToSend) {
                var deffered = $q.defer();
                if (!_.isUndefined(theDataToSend)) {
                    dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "sign/multiple/" + documentId, theDataToSend).then(function (data) {
                        if (data != null && _.isUndefined(data.ExceptionMessage)) {
                            deffered.resolve(data);
                            return deffered.promise;
                        } else {
                            deffered.resolve(false);
                            return deffered.promise;
                        }
                    });
                } else {
                    deffered.reject();
                    return deffered.promise;
                }
                return deffered.promise;
            }

            function localRemoveTask(documentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "removeTask/" + documentId);
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            }

            function localResendPinNotificationsToOutstandingSignatories(documentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "resendPins/" + documentId).then(function (theResult) {
                        if (theResult === true) {
                            deffered.resolve(true);
                        } else {
                            deffered.resolve(false);
                        }
                    }, function(theError) {
                        if (error.data.Message !== "Notification are not enabled for this document") {
                            logService.error(error, "documentsService.resendPinNotifications failed");
                        }
                        deffered.reject(theError);
                    });
                } else {
                    deffered.reject("Document can not be undefined");
                }
                return deffered.promise;
            }

            function getAdobeSignSigningUrl(docId) {
                return $http.get(ENVIRONMENTAL.BASEURLS.API + "/documents/" + docId + "/adobesignsigningurl")
                    .then(applyRequestFailureFix)
                    .then(function(resp) {
                        return resp.data ? resp.data.SigningUrl : $q.reject();
                    })
                    .catch(function(error) {
                        logService.error(error, "documentsService.createUser failed");
                        return $q.reject(error);
                    });
            }

            function getIsEligibleForAdobeSign(docId) {
                return $http.get(ENVIRONMENTAL.BASEURLS.API + "/documents/" + docId + "/adobesignsignable")
                    .then(applyRequestFailureFix)
                    .then(function(resp) {
                        return resp.data ? resp.data.IsSignable : $q.reject();
                    })
                    .catch(function(error) {
                        logService.error(error, "documentsService.setIsEligibleForAdobeSign failed");
                        return $q.reject(error);
                    });
            }

            function localResendAdobeSignNotificationsToOutstandingSignatories(docId) {
                return $http.post(ENVIRONMENTAL.BASEURLS.API + "/documents/" + docId + "/adobesignresendnotifications")
                    .then(applyRequestFailureFix)
                    .catch(function (error) {
                        if (error.data.Message !== "Notification are not enabled for this document"){
                            logService.error(error, "documentsService.resendAdobeSignNotifications failed");
                        }
                        return $q.reject(error);
                    });
            }

            function getDocumentLink(docId) {
                return $http.get(ENVIRONMENTAL.BASEURLS.API + "/documents/" + docId + "/getlink")
                    .then(applyRequestFailureFix)
                    .then(function (resp) {
                        if (!resp.data || !resp.data.TextDocumentLink) {
                            $q.reject();
                        }
                        return resp.data;
                    })
                    .catch(function (error) {
                        logService.error(error, "documentsService.setIsEligibleForAdobeSign failed");
                        return $q.reject(error);
                    });
            }

            function applyRequestFailureFix(resp) {
                // Workaround to turn failed calls back into failed promises
                return !resp || !resp.status || resp.status >= 400 ? $q.reject(resp) : resp;
            }

            return {
                get: localGet,
                getById: localGetById,
                getDownload: localGetDownload,
                getDownloadForPreview: localGetDownloadForPreview,
                add: localAdd,
                remove: localRemove,
                removeMultiple: localRemoveMultiple,
                assignFilter: localAssignFilter,
                sign: localSign,
                requestMultipleSignature: localRequestMultipleSignatures,
                removeTask: localRemoveTask,
                resendPinNotificationsToOutstandingSignatories: localResendPinNotificationsToOutstandingSignatories,
                getAdobeSignSigningUrl: getAdobeSignSigningUrl,
                getIsEligibleForAdobeSign: getIsEligibleForAdobeSign,
                resendAdobeSignNotificationsToOutstandingSignatories: localResendAdobeSignNotificationsToOutstandingSignatories,
                getDocumentLink: getDocumentLink
            };
        }
    ]);
})(window.angular);
