(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('DocumentsDeleteCtrl', [
        '$scope', '$modalInstance', 'constants', 'file', 'actionMode', 'selectedCount', 'notOwnedByCount', function ($scope, $modalInstance, constants, file, actionMode, selectedCount, notOwnedByCount) {

            $scope.vm = {};
            $scope.vm.warning = "";
            $scope.theFile = file;
            $scope.title = "";

            $scope.yes = function () {
                $modalInstance.close(true);
            }

            $scope.no = function () {
                $modalInstance.dismiss('cancel');
            }

            switch (actionMode) {
                case constants.FOLDERS.MODALMODES.DELETE:
                    $scope.vm.warning = "Warning! This document will be permanently removed from the database.";
                    $scope.title = "Delete selected document";
                    $scope.confirmation = "Please confirm you wish to delete the document with the following details:";
                    break;
                case constants.FOLDERS.MODALMODES.DELETEMULTI:
                    var countAllowedToDel = selectedCount - notOwnedByCount;
                    var moreThanOneDel = (countAllowedToDel > 1) ? "s" : "";
                    $scope.vm.warning = "Warning! " + countAllowedToDel + " selected document" + moreThanOneDel + " will be permanently removed from the database";
                    if (notOwnedByCount > 0) {
                        var moreThanOneOwned = (notOwnedByCount > 1) ? "s" : "";
                        $scope.vm.warning = $scope.vm.warning + " and " + notOwnedByCount + " selected document" + moreThanOneOwned + " are not owned by you and will not be removed";
                    }
                    $scope.vm.warning = $scope.vm.warning + ".";
                    $scope.confirmation = "Please confirm you wish to delete " + countAllowedToDel + " document" + moreThanOneDel + ".";
                    $scope.title = "Delete multiple documents";
                    break;
                default:
                    $scope.title = "An error occurred";
                    $scope.vm.warning = "Please try again";
                    break;
            }

            $scope.isMultiSelected = function () {
                return (selectedCount > 1);
            }
        }
    ]);
})();