(function() {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('SetNotificationsReplyAddressCtrl', [
        '$scope', '$modalInstance', 'constants', 'notificationsReplyAddressService', function ($scope, $modalInstance, constants, notificationsReplyAddressService) {
            $scope.title = 'Set Notification Reply Address';
            $scope.message = 'The notification reply address is used to send the resulting email to should someone reply to a notification';
            $scope.confirmBtnText = 'Save';
            $scope.cancelBtnText = 'Cancel';
            $scope.isLoading = true;
            $scope.loadingMessage = 'Retrieving your data';
            $scope.replyAddressError = false;
            $scope.replyAddressErrMsg = 'Please provide a valid reply address';

            $scope.replyAddressObj = {
                id: null,
                address: 'noreply@hownowhq.com'
            }

            notificationsReplyAddressService.get().then(function (result) {
                if (!_.isNull(result)) {
                    $scope.replyAddressObj = {
                        id: result.Id,
                        address: result.Address
                    }
                }
                $scope.isLoading = false;
            });

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            }

            $scope.confirm = function () {
                $scope.loadingMessage = 'Saving your changes';
                $scope.isLoading = true;
                $scope.replyAddressError = false;

                if ($scope.replyAddressObj.address === '' || !constants.REGEX.EMAIL.test($scope.replyAddressObj.address)) {
                    $scope.replyAddressError = true;
                    $scope.isLoading = false;
                    return;
                }
                if ($scope.replyAddressObj.id !== null) {
                    notificationsReplyAddressService.update($scope.replyAddressObj.id, $scope.replyAddressObj.address).then(function(result) {
                        $modalInstance.close(true);
                    }, function(err) {
                        $modalInstance.close(false);
                    });
                } else {
                    notificationsReplyAddressService.set($scope.replyAddressObj.address).then(function (result) {
                        $modalInstance.close(true);
                    }, function(err) {
                        $modalInstance.close(false);
                    });
                }
            }
        }
    ]);
})();
