(function(angular) {
    "use strict";

    angular
        .module("app")
        .controller("registerFirmCtrl",
            [
                "$modalInstance", "firmsService", "featuresService", "constants", "_", function ($modalInstance, firmsService, featuresService, constants, _) {
                    var vm = this;
                    vm.firmName = "";
                    vm.firstName = "";
                    vm.surname = "";
                    vm.email = "";
                    vm.error = false;
                    vm.errorMessage = "";
                    vm.selectedFeatures = [];
                    vm.availableFeatures = [];

                    var isNameValid = function(name) {
                        return name !== "" && name !== null && !_.isUndefined(name);
                    };

                    var isEmailAddressValid = function(emailAddress) {
                        return constants.REGEX.EMAIL.test(emailAddress);
                    };

                    vm.close = function() {
                        $modalInstance.dismiss("cancel");
                    };

                    vm.register = function() {
                        vm.error = false;

                        var featuresToAssign = vm.availableFeatures.reduce(function(result, feature) {
                                if (!vm.selectedFeatures[feature.Name]) {
                                    return result;
                                }
                                result.push(feature);
                                return result;
                            },
                            []);

                        if (!isNameValid(vm.firmName)) {
                            vm.errorMessage = "Invalid firm name";
                            vm.error = true;

                            return;
                        }

                        if (!isNameValid(vm.firstName)) {
                            vm.errorMessage = "Invalid first name";
                            vm.error = true;

                            return;
                        }

                        if (!isNameValid(vm.surname)) {
                            vm.errorMessage = "Invalid surname";
                            vm.error = true;

                            return;
                        }

                        if (!isEmailAddressValid(vm.email)) {
                            vm.errorMessage = "Invalid email address";
                            vm.error = true;

                            return;
                        }

                        var registerFirmModel = {
                            firmName: vm.firmName,
                            firstName: vm.firstName,
                            surname: vm.surname,
                            email: vm.email,
                            featuresEnabled: featuresToAssign
                        };

                        firmsService.register(registerFirmModel)
                            .then(function(result) {
                                if (result.Message) {
                                    vm.errorMessage = result.Message;
                                    vm.error = true;
                                } else {
                                    $modalInstance.close(true);
                                }
                            })
                            .catch(function(error) {
                                vm.errorMessage = "These has been an issue registering the firm";
                                vm.error = true;
                            });
                    };

                    function init() {
                        featuresService.getAvailable()
                            .then(function(features) {
                                vm.availableFeatures = features;
                            });
                    }

                    init();
                }
            ]);

})(window.angular);