(function () {
    'use strict';

    var module = angular.module('bf.services.notificationsreplyaddress', []);

    module.service('notificationsReplyAddressService', [
        '$q', '_', 'dataStoreService', 'ENVIRONMENTAL', function ($q, _, dataStoreService, ENVIRONMENTAL) {
            var apiSection = '/notifications/replyaddress/';

            function localGet() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection);
            }

            function localUpdate(id, address) {
                var deffered = $q.defer();
                if (!_.isUndefined(id) && !_.isUndefined(address)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + '?id=' + id + '&address=' + address);
                }
                deffered.reject();
                return deffered.promise;
            }

            function localSet(address) {
                var deffered = $q.defer();
                if (!_.isUndefined(address)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + '?address=' + address);
                }
                deffered.reject();
                return deffered.promise;
            }

            return {
                get: localGet,
                set: localSet,
                update: localUpdate
            }



        }
    ]);
})();
