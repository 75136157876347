(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('MultipleSignaturePrepareSignCtrl', [
        '$scope', '$modalInstance', 'document', '$sce', 'constants', 'documentsService', '$q', 'ENVIRONMENTAL', 'userList', '$window', '$modal', 'employee', 'signatureService', function ($scope, $modalInstance, document, $sce, constants, documentsService, $q, ENVIRONMENTAL, userList, $window, $modal, employee, signatureService) {

            $scope.title = document.Title;
            $scope.userList = userList;
            $scope.signatureLocations = [];
            $scope.locationsSet = false;
            $scope.messageReceived = false;
            $scope.modalOpen = false;
            $scope.documentLoading = true;
            $scope.messageIntegrity = false;
            $scope.userSigning = 0;
            $scope.headerMessage = "";
            $scope.error = false;

            var updateHeaderMessage = function (isEmployee) {
                if (isEmployee) {
                    $scope.headerMessage = $sce.trustAsHtml("Finally, Place the signatures for <strong>employee</strong> <span class=\"general-warning\">" + employee.GivenName + " " + employee.Surname + " (" + employee.Email + ")</span>.");
                    $scope.$apply();
                } else {
                    $scope.headerMessage = $sce.trustAsHtml("Place the signatures for <span class=\"general-warning\">" + $scope.userList[$scope.userSigning].GivenName + " " + $scope.userList[$scope.userSigning].Surname + " (" + $scope.userList[$scope.userSigning].Email + ")</span>.");
                    $scope.$apply();
                }
            }

            $scope.close = function () {
                $modalInstance.dismiss('cancel');
            }

            var messageHandler = function(e) {
                if (e.originalEvent.data == "loadingComplete") {
                    if (!$scope.messageIntegrity) {
                        $scope.documentLoading = false;
                        $scope.messageIntegrity = true;
                        updateHeaderMessage();
                        $("#frame")[0].style.visibility = "visible";
                    }
                } else if (/nextUserSigning: */.test(e.originalEvent.data)) {
                    if ($scope.userSigning < e.originalEvent.data.substr(e.originalEvent.data.length - 1, e.originalEvent.data.length)) {
                        $scope.userSigning = e.originalEvent.data.substr(e.originalEvent.data.length - 1, e.originalEvent.data.length);
                        if ($scope.userSigning < userList.length) {
                            updateHeaderMessage();
                        }
                    }
                } else if (/employeeSigning/.test(e.originalEvent.data)) {
                    updateHeaderMessage(true);
                } else if (e.originalEvent.data == "error") {
                    $scope.error = true;
                    $scope.$apply();
                } else {
                    try {
                        if (JSON.parse(e.originalEvent.data)) {
                            $scope.signatureLocations = e.originalEvent.data;
                            $scope.locationsSet = true;
                            $scope.$apply();
                        }
                    } catch (e) {
                        // suppress
                    }
                }
            };

            $($window).on("message", messageHandler);

            $scope.$on('$destroy', function () {
                $($window).off('message', messageHandler);
            });

            $scope.$watch('locationsSet', function(newVal, oldVal) {
                if (!oldVal && newVal && !_.isUndefined($scope.signatureLocations)) {
                    openModalOnce();
                }
            });

            var openModalOnce = function () {
                    $modal.open({
                        template: require('Documents\\MultipleSignatures\\ConfirmMultipleSignatureRequest.html'),
                        controller: 'ConfirmMultipleSignatureRequestCtrl',
                        resolve: {
                            userList: function() {
                                return $scope.userList;
                            },
                            signatureLocations: function() {
                                return $scope.signatureLocations;
                            },
                            document: function() {
                                return document;
                            },
                            employee: function () {
                                return employee;
                            }
                        },
                        size: 'lg'
                    }).result
                        .then(function () {
                            $modalInstance.close('finished');
                        })
                        .catch(function () {
                            $modalInstance.dismiss('cancel');
                        });;
            }

            setFrameUrl();

            $scope.reloadSignatureDocument = function () {
                $scope.locationsSet = false;
                $scope.messageReceived = false;
                $scope.modalOpen = false;
                $scope.documentLoading = true;
                $scope.messageIntegrity = false;
                $scope.error = false;
                $("#frame")[0].style.visibility = "hidden";

                setFrameUrl();
            };

            function setFrameUrl() {
                documentsService.getDownloadForPreview(document.Id)
                    .then(function(data) {
                        return signatureService.identifyUser()
                            .then(function() {
                                return data;
                            });
                    })
                    .then(function (data) {
                        var dataObject = userList;
                        $scope.url = $sce.trustAsResourceUrl(ENVIRONMENTAL.BASEURLS.SIGN + "PrepareMultiple/Index" + constants.DOCUMENTS.URLS.SIGNURL + encodeURIComponent(data.fileUrl) + constants.DOCUMENTS.URLS.SIGNTITLE + "Prepare: " + encodeURIComponent(document.Title) + "&userDetailsList=" + encodeURIComponent(JSON.stringify(dataObject)) + "&employeeDetails=" + encodeURIComponent(JSON.stringify(employee)));
                });
            }
        }
    ]);
})();
