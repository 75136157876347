require("./controllers.js");

var versionNumber = { name: 'HappyAdminMonkey' };
angular.module('app', [
    'angular-jwt',
    'angular-storage',
    'angularMoment',
    'app.controllers',
    'app.directives',
    'app.services',
    'auth0',
    'bf.components.announcementsUpload',
    'bf.components.bfFeatureToggle',
    'bf.components.bfGrid',
    'bf.components.clients',
    'bf.components.controls',
    'bf.components.dashboard.admin.activity',
    'bf.components.files',
    'bf.components.firms',
    'bf.components.upload',
    'bf.config.environmental',
    'bf.constants',
    'bf.services.accounts',
    'bf.services.audit',
    'bf.services.branding',
    'bf.services.clients',
    'bf.services.clipboard',
    'bf.services.dashboard.admin',
    'bf.services.data',
    'bf.services.dataLayerService',
    'bf.services.documents.user',
    'bf.services.employees',
    'bf.services.fileDownload',
    'bf.services.filters',
    'bf.services.firms',
    'bf.services.log',
    'bf.services.notifications',
    'bf.services.notifications.mergefields',
    'bf.services.notificationsreplyaddress',
    'bf.services.password',
    'bf.services.signature',
    'bf.services.systemnotifications',
    'bf.services.systemsettings',
    'common',
    'flow',
    'kendo.directives',
    'loadingPane',
    'ngProgress',
    'ngTagsInput',
    'ui.bootstrap',
    'ui.router']);

angular.module('app')
    .value('version', versionNumber)
    .constant('appName', 'HowNow Portal Admin')
    .constant('URL_CONSTANTS', {
        BaseAPI: 'no-address',
    })
    .config(['commonConfigProvider', '$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider', 'authProvider', 
        function (cfg, $stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, authProvider) {

            $urlRouterProvider.otherwise('/error');

            //generic states
            $stateProvider
                .state('logout', {
                    url: '/logout',
                    controller: 'LogoutCtrl',
                    template: require('Site\\logout.html'),
                    layout: 'login'
                })
                .state('login', {
                    url: '/login/:partial:theme',
                    controller: 'LoginCtrl',
                    template: require('Site\\login.html'),
                    layout: 'login'
                })
                .state('callback', {
                    url: '/callback',
                    controller: 'CallbackCtrl',
                    template: require('Site\\Callback.html'),
                    layout: 'callback'
                })
                .state('accessdenied', {
                    url: '/accessdenied',
                    controller: 'DullCtrl',
                    template: require('accessdenied.html')
                })
                .state('error', {
                    url: '/error',
                    controller: 'DullCtrl',
                    layout: 'site',
                    template: require('404.html')
                })
                .state('adobeSignAuthenticate', {
                    url: '/adobesign/authenticate',
                    controller: 'oAuthCtrl',
                    data: { requiresLogin: true },
                    template: require('AdobeSign\\oAuth.html')
                })
                .state('clients', {
                    url: '/clients/list',
                    data: { requiresLogin: true },
                    template: require('Clients\\Clients.html'),
                    controller: 'ClientsCtrl'
                })
                .state('settings', {
                    url: '/settings',
                    controller: 'SettingsCtrl',
                    data: { requiresLogin: true },
                    template: require('Settings\\settings.html')
                })
                .state('settings.notifications', {
                    url: '/notification',
                    data: { requiresLogin: true },
                    template: require('Settings\\NotificationsList.html'),
                    controller: 'NotificationsListCtrl'
                })
                .state('settings.filters', {
                    url: '/filters',
                    data: { requiresLogin: true },
                    template: require('Settings\\Filters.html'),
                    controller: 'FiltersCtrl'
                })
                .state('settings.employees', {
                    url: '/employees',
                    data: { requiresLogin: true },
                    template: require('Settings\\Employees.html'),
                    controller: 'EmployeesCtrl'
                })
                .state('settings.branding', {
                    url: '/branding',
                    data: { requiresLogin: true },
                    template: require('Settings\\Branding.html'),
                    controller: 'BrandingCtrl'
                })
                .state('settings.authentication', {
                    url: '/authentication',
                    data: { requiresLogin: true },
                    template: require('Settings\\Authentication.html'),
                    controller: 'AuthenticationCtrl'
                })
                .state('settings.signingpreferences', {
                    url: '/signingpreferences',
                    data: { requiresLogin: true },
                    template: require('Settings\\SigningPreferences.html'),
                    controller: 'SigningPreferencesCtrl'
                })
                .state('documents', {
                    url: '/documents/:workingClientId/:documentId',
                    data: { requiresLogin: true },
                    template: require('Documents\\Documents.html'),
                    controller: 'DocumentsCtrl'
                })
                .state('documents-sign', {
                    url: '/documents/sign/:workingClientId/:documentId',
                    data: { requiresLogin: true, sign: true },
                    template: require('Documents\\Documents.html'),
                    controller: 'DocumentsCtrl'
                })
                .state('documents-preview', {
                    url: '/documents/preview/:workingClientId/:documentId',
                    data: { requiresLogin: true, preview: true },
                    template: require('Documents\\Documents.html'),
                    controller: 'DocumentsCtrl'
                })
                .state('dashboard', {
                    url: '/',
                    controller: 'StartCtrl',
                    data: { requiresLogin: true },
                    template: require('Start\\start.html')
                })
                .state('accounts', {
                    url: '/accounts/list',
                    controller: 'AccountsCtrl',
                    data: { requiresLogin: true },
                    template: require('Accounts\\Accounts.html')
                })
                .state('firms', {
                    url: '/owner/firms/list',
                    controller: 'FirmsCtrl',
                    data: { requiresLogin: true },
                    template: require('Firms\\Firms.html')
                })
                .state('notifications', {
                    url: '/owner/notifications/list',
                    controller: 'NotificationsCtrl',
                    data: { requiresLogin: true },
                    template: require('Notifications\\Notifications.html')
                })
                .state('reports', {
                    url: '/reports/',
                    controller: 'ReportsCtrl',
                    data: { requiresLogin: true },
                    template: require('Reports\\reports.html')
                })
                .state('report', {
                    url: '/reports/:reportTag',
                    controller: 'ReportCtrl',
                    data: { requiresLogin: true },
                    template: require('Reports\\report.html')
                })
                .state('profile', {
                    url: '/profile',
                    data: { requiresLogin: true },
                    template: require('Site\\Profile.html'),
                    controller: 'ProfileCtrl'
                })
                .state('accept', {
                    url: '/accept?inviteToken',
                    controller: 'AcceptInviteCtrl',
                    template: require('Site\\AcceptInvite.html')
                })
                .state('requestPassword', {
                    url: '/requestPassword',
                    controller: 'RequestPasswordCtrl',
                    template: require('Site\\ForgotPassword\\RequestPassword.html')
                })
                .state('updatePassword', {
                    url: '/updatePassword?token',
                    controller: 'UpdatePasswordCtrl',
                    template: require('Site\\ForgotPassword\\UpdatePassword.html')
                })
                .state('announcements', {
                    url: '/announcementsList',
                    controller: 'AnnouncementsCtrl',
                    template: require('Announcements\\Announcements.html'),
                    data: { requiresLogin: true }
                });

            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false
            });

            versionNumber.value = cfg.environmental.VERSION;
            var url = window.location.href;
            if (url.includes("documents/sign")) {
                localStorage.setItem("signingUrl", url);
            }

            authProvider.init({
            domain: cfg.environmental.AUTH0.DOMAIN,
            clientID: cfg.environmental.AUTH0.CLIENTID,
            callbackURL: cfg.environmental.AUTH0.CALLBACKURL
                //loginState: 'login'
            });

            $httpProvider.interceptors.push('customInterceptor');

        }
    ]).config(['flowFactoryProvider', 'ENVIRONMENTAL', function (flowFactoryProvider, ENVIRONMENTAL) {
        flowFactoryProvider.defaults = {
            target: ENVIRONMENTAL.FILEAPI.BASEURL + '/api/upload/uploadchunks',
            permanentErrors: [500, 501],
            maxChunkRetries: 1,
            chunkRetryInterval: 5000,
            simultaneousUploads: 3,
            testChunks: false,
            withCredentials: true//,
            //headers: {'x-ms-blob-type': 'BlockBlob'}
        };
        flowFactoryProvider.on('catchAll', function (event) {
            console.log('catchAll', arguments);
        });
        // Can be used with different implementations of Flow.jsd
        //flowFactoryProvider.factory = fustyFlowFactory;
    }])
    .run([
        'events', 'constants', '$templateCache', '$rootScope', '$state', '$stateParams', 'auth', 'authHelper', '$timeout', 'ngProgress', 'store', 'jwtHelper', 'ENVIRONMENTAL', '$window', '$q', '$location', 'brandingService', 
        function (events, constants, $templateCache, $rootScope, $state, $stateParams, auth, authHelper, $timeout, ngProgress, store, jwtHelper, environmental, $window, $q, $location, brandingService) {


        $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams, fromState, fromStateParams) {
            ngProgress.reset(); 
            ngProgress.start();
            $rootScope.isLoading = true;

            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;

            if (window.self !== window.top) {
                if (toState.name !== 'login') {
                    var partial;
                    var theme;
                    if ($location.$$search.partial != null) {
                        partial = $location.$$search.partial;
                    }

                    if ($location.$$search.partial != null) {
                        theme = $location.$$search.theme;
                    }

                    event.preventDefault();
                    $state.go('login', { partial: partial, theme: theme }); // Keep the iFrame on the login view
                    return;
                }
            } else {
                if (!auth.isAuthenticated && !($location.url().indexOf("/accept") >= 0 ||
                    $location.url().indexOf("/requestPassword") >= 0 ||
                    $location.url().indexOf("/updatePassword") >= 0)) {
                    var token = store.get('token');
                    if (token && !jwtHelper.isTokenExpired(token)) {
                        
                        var profile = store.get('profile');
                        auth.authenticate(profile, token);
                    }
                }
            }

            if (toState.data && toState.data.requiresLogin === true && (!auth.isAuthenticated || jwtHelper.isTokenExpired(auth.idToken))) {
                if ($state.current.name !== toState.name) {
                    ngProgress.reset();
                }
                event.preventDefault();
                if (toState && toState.name !== 'login') {
                    $rootScope.returnToState = toState;
                    $rootScope.returnToStateParams = toStateParams;
                }

                $state.go('login');
                return;
            }

            // check the roles
            if ($rootScope.toState.data && $rootScope.toState.data.roles && $rootScope.toState.data.roles.length > 0 && !authHelper.isInAnyRole($rootScope.toState.data.roles)) {
                if (auth.isAuthenticated) {
                    $state.go('accessdenied');
                    return;
                } else {
                    if (toState && toState.name !== 'login') {
                        $rootScope.returnToState = $rootScope.toState;
                        $rootScope.returnToStateParams = $rootScope.toStateParams;
                    }
                    // now, send them to the signin state so they can log in
                    $state.go('login');
                    return;
                }
            }
            // Close the nav bar
            $rootScope.navOpen = false;
        });

        $rootScope.$on('$stateChangeSuccess', function(event, toState) {
            $rootScope.layout = toState.layout;
        });

        $rootScope.$on('$stateChangeError', function() {
            $timeout(function() {
                ngProgress.reset();
                $state.go('login');
            });
        });


        var customData = {};
        var profile = store.get("profile");
        if (!_.isUndefined(profile) && !_.isNull(profile)) {
            if (!_.isUndefined(profile.userId)) {
                customData.userId = profile.userId;
            }
        }

        auth.hookEvents();

            // Allows to retrieve UI Router state information from inside templates

        $rootScope.title = constants.APP.NAME;
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;

        ngProgress.color("#fff");
        kendo.culture("en-AU");
    }
]);
