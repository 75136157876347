(function(angular) {
    'use strict';

    angular
        .module('app')
        .controller('featureAccessManagementCtrl', [
            'featuresService', '$modalInstance', 'selectedFirm', function(featuresService, $modalInstance, selectedFirm) {

                var vm = this;
                vm.availableFeatures = [];
                vm.accessiableFeatures = [];
                vm.firmName = selectedFirm.Name;
                vm.selectedFeatures = [];
                vm.error = false;
                
                vm.close = function() {
                    $modalInstance.dismiss('cancel');
                }

                vm.save = function () {
                    vm.error = false;

                    // If there are no features selected we need to remove all currently assigned

                    var featuresToAssign = vm.availableFeatures.reduce(function(result, feature) {
                        if (!vm.selectedFeatures[feature.Name])
                            return result;
                        result.push(feature);
                        return result;
                    }, []);

                    featuresService.assignToFirmId(selectedFirm.Id, featuresToAssign)
                    .then(function() {
                            $modalInstance.close(true);
                        })
                    .catch(function(error) {
                        vm.error = true;
                    });
                }

                featuresService.getAvailable()
                    .then(function(features) {
                        vm.availableFeatures = features;
                    })
                    .then(function() { return featuresService.getForFirmId(selectedFirm.Id) })
                    .then(function(accessibleFeatures) {
                        accessibleFeatures.map(function(feature) {
                            vm.selectedFeatures[feature.Name] = true;
                        });
                    });
            }
        ]);

})(window.angular);