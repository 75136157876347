(function() {
    'use strict';

    angular.module('app')
        .controller('DocumentAuditTrailCtrl', [
            '_', '$scope', '$modalInstance', 'constants', 'auditService', 'documentId', function(_, $scope, $modalInstance, constants, auditService, documentId) {

                var vm = this;

                vm.title = 'Document Audit Trail';
                vm.audits = null;

                vm.cancel = cancel;

                function getAudits() {
                    auditService.getDocumentAuditTrail(documentId).then(function(response) {
                        vm.audits = response.map(function(item) {
                            item.localTime = moment(item.DateTime).format();
                            return item;
                        });
                    });

                }

                function dateOf(utcDateStr) {
                    return new Date(utcDateStr);
                }

                function cancel() {
                    $modalInstance.dismiss('cancel');
                }

                function init() {
                    getAudits();
                }

                init();

            }
        ]);
})();