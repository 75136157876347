(function ()  {
    "use strict";

    angular.module("app")
        .controller("ClientsDetailsCtrl",
            [
                "_", "$scope", "$modalInstance", "actionMode", "passedData",
                function(_, $scope, $modalInstance, actionMode, passedData) {

                    $scope.confirmBtnText = "Confirm";
                    $scope.cancelBtnText = "Cancel";
                    $scope.title = "";
                    $scope.message = "";
                    $scope.canEdit = false;
                    $scope.name = "";
                    $scope.contact = "";

                    $scope.warn = false;
                    $scope.warning = "All fields are required.";
                    $scope.invalid = false;
                    $scope.invalidMsg = "Fields may only contain letters (A-Z), numbers (0-9) and symbols (, . ' &)";
                    $scope.showConfirmDeleteBtn = false;
                    $scope.confirmDeleteBtnText = "Yes I am sure";

                    switch (actionMode) {
                    case "create":
                        $scope.title = "Create New Client";
                        $scope.message = "Please enter the details of the new client";
                        $scope.confirmBtnText = "Create";
                        $scope.canEdit = true;
                        break;
                    case "delete":
                        $scope.title = "Delete Client";
                        $scope.warn = true;
                        $scope.warning =
                            "WARNING: Deleting a client will permanently delete all of the contained documents!";
                        $scope.message = "Please confirm you wish to delete the client with the follow details";
                        $scope.confirmBtnText = "Delete";
                        $scope.canEdit = false;
                        break;
                    case "edit":
                        $scope.title = "Update Client";
                        $scope.message = "Please change the settings below";
                        $scope.confirmBtnText = "Update";
                        $scope.canEdit = true;
                        break;
                    default:
                        $scope.title = "Opps";
                        $scope.message = "Something didn't work out";
                        break;
                    }

                    if (!_.isUndefined(passedData) && !_.isNull(passedData)) {
                        $scope.name = passedData.Name;
                        $scope.contact = passedData.ContactName;
                    }

                    var validateFields = function () {
                        if (_.isUndefined($scope.name) || $scope.name === "" ||
                            _.isUndefined($scope.contact) || $scope.contact === "") {
                            return false;
                        }

                        return true;
                    };

                    var resetErrors = function () {
                        switch (actionMode) {
                        case "delete":
                            $scope.invalid = false;
                            break;
                        default:
                            $scope.invalid = false;
                            $scope.warn = false;
                            break;
                        }
                    };

                    $scope.confirm = function() {
                        resetErrors();
                        var returnVal = { success: false };
                        switch (actionMode) {
                        case "create":
                            if (!validateFields()) {
                                $scope.warn = true;
                                return;
                            }

                            returnVal.success = true;
                            returnVal.newClient = getClientFromScope();
                            break;
                        case "delete":
                            $scope.showConfirmDeleteBtn = true;
                            return;
                        case "edit":
                            if (!validateFields()) {
                                $scope.warn = true;
                                return;
                            }

                            returnVal.success = true;
                            returnVal.updatedClient = getClientFromScope();
                            returnVal.clientId = passedData.Id;
                            break;
                        default:

                            break;
                        }
                        $modalInstance.close(returnVal);
                    };

                    $scope.confirmDelete = function() {
                        var returnVal = {};
                        returnVal.success = true;
                        returnVal.clientId = passedData.Id;
                        $modalInstance.close(returnVal);
                    };

                    $scope.cancel = function() {
                        $modalInstance.dismiss("cancel");
                    };

                    function getClientFromScope() {
                        return {
                            name: $scope.name,
                            contact: $scope.contact,
                        };
                    }

                    $scope.setFocus = function(form, event) {
                        var s = "";
                    };
                }
            ]);
})();
