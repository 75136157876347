(function () {
    'use strict';

    angular.module('app')
        .controller('ClientsInviteCtrl', [
            '_', '$scope', '$modalInstance', 'actionMode', 'selection', 'constants', 'accountsService', function(_, $scope, $modalInstance, actionMode, selection, constants, accountsService) {

                $scope.cancelBtnText = 'Cancel';
                $scope.canEdit = true;
                $scope.toDisable = false;
                $scope.clients = selection || [];

                // validations
                $scope.mobileError1 = false;
                $scope.mobileError1Msg = "Mobile number entered is not a valid format";
                $scope.mobileError2 = false;
                $scope.mobileError2Msg = "Mobile number must not contain letters or special characters";
                $scope.warn = false;
                $scope.emailError = false;
                $scope.emailErrorMsg = "Email address entered is not a valid format";
                $scope.existing = (actionMode === constants.CLIENTS.MODALMODES.INVITEEXISTING); // flag for disabling assign button on invite existing screen
                $scope.emailRegex = constants.REGEX.EMAIL;
                $scope.mobileRegex = constants.REGEX.MOBILE;

                var resetAllErrors = function () {
                    $scope.warn = false;
                    $scope.mobileError1 = false;
                    $scope.mobileError2 = false;
                    $scope.emailError = false;
                }

                var multipleClients = $scope.clients.length > 1;

                switch (actionMode) {
                    case constants.CLIENTS.MODALMODES.INVITE:
                        $scope.isLoading = false;
                        $scope.title = "Invite to Portal";
                        $scope.confirmBtnText = 'Invite';
                        $scope.inviteNew = true;
                        $scope.givenName = '';
                        $scope.surname = '';
                        $scope.email = '';
                        $scope.mobile = '';
                        $scope.assignMsg = "Clients they will have access to:";
                        break;
                    case constants.CLIENTS.MODALMODES.INVITEEXISTING:
                        $scope.isLoading = true;
                        $scope.title = multipleClients ? 'Assign Clients to Account' : 'Assign Client to Account';
                        $scope.confirmBtnText = 'Assign';
                        accountsService.getWithClients().then(function (data) {
                            $scope.accounts = data;
                            $scope.accountToInvite = $scope.accounts[0];
                            $scope.isLoading = false;
                        });
                        $scope.assignMsg = multipleClients ? 'Clients:' : 'Client:';
                        break;
                    default:
                        $scope.title = 'Opps';
                        $scope.message = 'Something didn\'t work out';
                        break;
                }

                $scope.confirm = function () {
                    resetAllErrors();

                    switch (actionMode) {
                        case constants.CLIENTS.MODALMODES.INVITE:
                            var returnVal = {};

                            // make sure it is atleast a number
                            if ($scope.mobile !== "") {
                                if (isNaN($scope.mobile)) {
                                    $scope.mobileError2 = true;
                                    return;
                                }
                            }
                            if (_.isUndefined($scope.email)) {
                                $scope.emailError = true;
                                return;
                            }
                            if ($scope.email === "" || $scope.givenName === "" || $scope.surname === "") {
                                $scope.warn = true;
                                $scope.warning = "Given Name, Surname and Email are required!";
                                return;
                            }

                            returnVal.success = true;
                            returnVal.givenName = $scope.givenName;
                            returnVal.surname = $scope.surname;
                            returnVal.email = $scope.email;
                            returnVal.mobile = $scope.mobile;
                            returnVal.clients = _.map($scope.clients, function (x) { return x.Id; });
                            break;
                        case constants.CLIENTS.MODALMODES.INVITEEXISTING:
                            var returnVal = {};
                            returnVal.success = true;
                            if ($scope.invalidAssigning) {
                                $scope.warn = true;
                                return;
                            }
                            returnVal.accountId = $scope.accountToInvite.Id;
                            returnVal.clients = _.map($scope.clients, function (x) { return x.Id; });
                            break;
                    }
                    if (!$scope.warn) {
                        $modalInstance.close(returnVal);
                    }
                }

                $scope.cancel = function () {
                    $modalInstance.dismiss('cancel');
                }

                $scope.$watch('accountToInvite', function (newVal, oldVal) {
                    if (!_.isUndefined(newVal)) {
                        $scope.validateSelection();
                    }
                });

                $scope.validateSelection = function () {
                    var failed = false;
                    $scope.accountToInvite.Clients.forEach(function (assignedClient) {
                        $scope.clients.forEach(function (client) {
                            if (client.Id == assignedClient.Id) {
                                failed = true;
                                $scope.warning = "One or more of the selected clients are already assigned to this account.";
                            }
                        });
                    });

                    if (failed) {
                        $scope.invalidAssigning = true;
                        $scope.warn = true;
                    } else {
                        $scope.warn = false;
                        $scope.invalidAssigning = false;
                    }
                }

            }
        ]);
})();
