(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('CompleteAdobeSecureSignCtrl', [
            '$scope', '$q', 'selectedDoc', 'url', '$modalInstance', '$sce', 'ENVIRONMENTAL', '$window',
            function ($scope, $q, selectedDoc, url, $modalInstance, $sce, ENVIRONMENTAL, $window) {

                $scope.title = selectedDoc.Title;
                $scope.url = $sce.trustAsResourceUrl(url);
                $scope.documentLoading = true;
                $scope.error = false;

                $scope.cancel = function() {
                    $modalInstance.dismiss({ reason: 'cancel', agreementId: null });
                }

                var messageHandler = function(e) {
                    var isOriginAllowed = function(origin) {
                        return origin.search(/^http[s]+:\/\/secure[.\w]+\.echosign\.com$/i) > -1
                            || origin.search(/^http[s]+:\/\/businessfitness.au1.echosign.com$/i) > -1
                            || origin.search(/^http[s]+:\/\/secure[.\w]+\.adobesign\.com$/i) > -1;
                    }

                    if (e.originalEvent.origin === ENVIRONMENTAL.BASEURLS.USERSITE && e.originalEvent.data === 'loadingComplete') {
                        $modalInstance.close({ reason: 'finished', agreementId: null });
                    }


                    if (isOriginAllowed(e.originalEvent.origin)) {
                        var eventData = JSON.parse(e.originalEvent.data);
                        console.log(eventData);

                        if (eventData.type === "PAGE_LOAD") {
                            if (eventData.data.pageName === "AUTHORING") {
                                $scope.documentLoading = false;
                                $scope.$apply();
                            } else if (eventData.data.pageName === "POST_SEND") {
                                $modalInstance.close({ reason: 'finished', agreementId: eventData.data.apiAgreementId });
                            }
                        } else if (eventData.type === "ERROR") {
                            $scope.error = true;
                            $scope.$apply();
                        } else if (eventData.type === "SESSION_TIMEOUT") {
                            $scope.cancel();
                        }
                    }
                };

                $($window).on("message", messageHandler);

                $scope.$on('$destroy',
                    function() {
                        $($window).off('message', messageHandler);
                    });

                $scope.reloadSignatureDocument = function() {
                    $scope.documentLoading = true;
                    $scope.error = false;
                };
            }
        ]);
})(window.angular);
