(function() {
    'use strict';

    var module = angular.module('bf.services.clients', []);

    module.service('clientsService', [
        '$q', '_', 'dataStoreService', '$filter', 'ENVIRONMENTAL', function($q, _, dataStoreService, $filter, ENVIRONMENTAL) {

            var apiSection = '/clients/';

            function localGet(searchTerm, pageNumber, itemsPerPage, sortOptions) {
                var search = '';
                var pre = '?';
                if (!_.isUndefined(searchTerm) && searchTerm != '') {
                    search = pre + 'searchTerm=' + encodeURIComponent(searchTerm);
                    pre = '&';
                }
                if (!_.isUndefined(pageNumber) && !_.isUndefined(itemsPerPage) && _.isNumber(pageNumber) && _.isNumber(itemsPerPage)) {
                    search += pre + 'pageNumber=' + encodeURIComponent(pageNumber) + '&itemsPerPage=' + encodeURIComponent(itemsPerPage);
                    pre = '&';
                }

                if (!_.isUndefined(sortOptions) && !_.isNull(sortOptions)) {
                    var sortDir = null;
                    var sortField = null;
                    if (_.isArray(sortOptions) && sortOptions.length > 0) {
                        sortDir = sortOptions[0].dir;
                        sortField = sortOptions[0].field;
                        search += pre + 'sortDirection=' + sortDir + '&sortField=' + sortField;
                        pre = '&';
                    }
                }

                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + search);
            }

            function localgetById(id) {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
            }

            function addClient(newClient) {
                if (!_.isUndefined(newClient)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection, newClient);
                }
                var deffered = $q.defer();
                deffered.reject();
                return deffered.promise;
            }

            function removeClient(id) {
                if (!_.isUndefined(id)) {
                    return dataStoreService.deleteResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
                }
                var deffered = $q.defer();
                deffered.reject();
                return deffered.promise;
            }

            function updateClient(id, newClientDetails) {
                if (!_.isUndefined(newClientDetails)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id, newClientDetails);
                }
                var deffered = $q.defer();
                deffered.reject();
                return deffered.promise;
            }

            function localGetSubscriptions(id) {
                if (!_.isUndefined(id)) {
                    return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "subscriptions/" + id);
                }
                var deferred = $q.defer();
                deferred.reject();
                return deferred.promise;
            }

            function localUpdateSubscriptions(dataToSend) {
                if (!_.isUndefined(dataToSend)) {
                    var body = dataToSend;
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "updateSubscriptions", body);
                }
                var deffered = $q.defer();
                deffered.reject();
                return deffered.promise;
            }
            
            return {
                get: localGet,
                getById: localgetById,
                remove: removeClient,
                add: addClient,
                update: updateClient,
                getSubscriptions: localGetSubscriptions,
                updateSubscriptions: localUpdateSubscriptions
            }
        }
    ]);
})();