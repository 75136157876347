(function() {
    'use strict';
    var module = angular.module('bf.services.password', []);

    module.service('passwordService', [
        '_', '$q', '$timeout', 'events', 'constants', 'dataStoreService', 'ENVIRONMENTAL', 
        function (_, $q, $timeout, events, constants, dataStoreService, ENVIRONMENTAL) {

            var apiSection = "/employees/";
            
            function request(email) {
                var dataToSend = {
                    email: email
                };
                return dataStoreService.post(ENVIRONMENTAL.BASEURLS.API + apiSection + "requestPasswordReset", dataToSend);
            }

            function reset(password, token) {
                var deffered = $q.defer();

                var dataToSend = {};
                dataToSend.password = password;
                dataToSend.token = token;

                dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "performPasswordReset", dataToSend)
                    .then(function (theData) {
                    deffered.resolve(theData);
                });
                return deffered.promise;
            }

            function changePassword(oldPassword, newPassword) {
                var dataToSend = {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                };

                return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "ChangePassword", dataToSend);
            }

            return {
                changePassword: changePassword,
                requestPasswordReset: request,
                performReset: reset
            }
        }
    ]);
})();