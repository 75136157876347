(function () {
    'use strict';

    angular.module('bf.components.files', []);

    angular.module('bf.components.files')
        .directive('fileActions', [
            '_', function (_) {

                return {
                    restrict: 'E',
                    scope: {
                        actionsFor: '@',
                        deleteFn: '&',
                        previewFn: '&',
                        previewEnabledForSelectedDoc: '=',
                        prepareSignFn: '&',
                        requestAdobeSignFn: '&',
                        downloadFn: '&',
                        requestMultipleSignaturesFn: '&',
                        verifyMultipleSignaturesFn: '&',
                        removeTaskFn: '&',
                        resendPinFn: '&',
                        copyLinkFn: '&',
                        selectedFile: '=',
                        noSelectedTask: '=',
                        isAdobeSignTask: '=',
                        isMultiSelect: '=',
                        signFn: '&',
                        auditTrailFn: '&',
                        resendAdobeSignFn: '&',
                        isEmployeeEligibleToSign: '=',
                        isAdobeSignableTask: '=',
                        confirmInProgress: '=',
                        disableTasksButton: '=',
                        disableResendTask: '='
                    },
                    template: require('Site\\Components\\Views\\fileActions.html'),
                    replace: true,
                    link: function ($scope, element, attrs) {

                        if (!$scope.hasOwnProperty('actionsFor') || _.isUndefined($scope.actionsFor)) {
                            $scope.actionsFor = 'documents';
                        }

                        if (!$scope.hasOwnProperty('deleteFn') || _.isUndefined($scope.deleteFn)) {
                            $scope.deleteFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('previewFn') || _.isUndefined($scope.previewFn)) {
                            $scope.previewFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('prepareSignFn') || _.isUndefined($scope.previewFn)) {
                            $scope.prepareSignFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('requestAdobeSignFn') || _.isUndefined($scope.requestAdobeSignFn)) {
                            $scope.requestAdobeSignFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('downloadFn') || _.isUndefined($scope.downloadFn)) {
                            $scope.downloadFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('requestMultipleSignaturesFn') || _.isUndefined($scope.requestMultipleSignaturesFn)) {
                            $scope.requestMultipleSignatures = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('verifyMultipleSignaturesFn') || _.isUndefined($scope.verifyMultipleSignaturesFn)) {
                            $scope.verifyMultipleSignatures = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('removeTaskFn') || _.isUndefined($scope.removeTaskFn)) {
                            $scope.removeTaskFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('resendPinFn') || _.isUndefined($scope.resendPinFn)) {
                            $scope.resendPinFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('copyLinkFn') || _.isUndefined($scope.copyLinkFn)) {
                            $scope.copyLinkFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('signFn') || _.isUndefined($scope.previewFn)) {
                            $scope.signFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('auditTrailFn') || _.isUndefined($scope.deleteFn)) {
                            $scope.auditTrailFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('resendAdobeSignFn') || _.isUndefined($scope.resendAdobeSignFn)) {
                            $scope.resendAdobeSignFn = angular.noop();
                        }
                    }
                };
            }
        ]);
})();
