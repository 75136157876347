angular.module('app')
    .controller('RequestPasswordCtrl', [
        '$scope', '$location', '$window', 'passwordService', '_', '$state', 'employeesService', function ($scope, $location, $window, passwordService, _, $state, employeesService) {
            $scope.$root.title = 'HowNow Portal';

            $scope.$on('$viewContentLoaded', function (event) {

                $scope.isLoading = false;
                $scope.progress.complete();

                $scope.passwordRequest = {
                    email: ""
                };
                $scope.passwordInfoSent = false;
                $scope.passwordInfoNotSent = false;
                $scope.passwordInfoNotSentMessage = "";
                $scope.btnWorkingOnIt = false;
                $scope.inviteNotAccepted = false;

                var setSubmitButtonToWorkingOnIt = function (workingOnIt) {
                    $scope.btnWorkingOnIt = workingOnIt;
                };

                $scope.register = function (form) {
                    if (form.$valid) {
                        setSubmitButtonToWorkingOnIt(true);
                        passwordService.requestPasswordReset($scope.passwordRequest.email).then(function (response) {
                            if (response.data === true) {
                                $scope.passwordInfoSent = true;
                                setSubmitButtonToWorkingOnIt(false);
                            }

                            if (response.data === "invite") {
                                $scope.inviteNotAccepted = true;
                                $scope.passwordInfoNotSent = true;
                                $scope.passwordInfoNotSentMessage = "It appears you have not accepted your invitation and finished setting up your account, please click the resend button to have another invitation sent, then follow the steps to activate your account.";
                                setSubmitButtonToWorkingOnIt(false);
                            }
                        }, function (err) {
                                $scope.passwordInfoNotSent = true;
                                $scope.passwordInfoNotSentMessage = "An error occurred on the server while trying to process your request, please try again. If the error persists please contact support.";
                                setSubmitButtonToWorkingOnIt(false);
                        });
                    } else {
                        alert("Please enter an appropriate email address");
                    }
                }

                $scope.resend = function () {
                    employeesService.resendInviteByEmail($scope.passwordRequest.email).then(function (data) {
                        $scope.inviteSent = true;
                    }, function (err) {
                        $scope.inviteSend = false;
                    });
                }
            });
        }
    ]);