(function(angular) {
    'use strict';

    angular
        .module('app')
        .directive('signedDocumentsByEmployee', ['reportUtilities', 'employeesService', function(rptUtils, employeesService) {

            function link($scope, element, attrs) {

                var reportEndpointName = 'signeddocumentsbyemployee';
                var endpoint = '/reports/';

                $scope.employeeTags = [];
                $scope.dateFrom = null;
                $scope.dateTo = null;

                $scope.onSearchEmployees = function(q) {
                    return employeesService.get(q, undefined, undefined, [{ dir: "asc", field: "Surname" }]).then(function(employees) {
                        if (!employees.map) {
                            // the employeesService.get sometimes returns a failure message - if so, return no matches
                            return [];
                        }
                        return employees.map(function(acct) {
                            return { Id: acct.Id, Name: acct.GivenName + ' ' + acct.Surname};
                        });
                    });
                };

                $scope.buildUrl = function() {
                    var url = endpoint + reportEndpointName
                            + rptUtils.getUtcOffsetQueryParam('?')
                            + rptUtils.buildListAsQueryParams($scope.employeeTags, '&', 'employeeId', function(c) { return c.Id; })
                            + rptUtils.getMomentQueryParam('&', 'dateFrom', $scope.dateFrom)
                            + rptUtils.getMomentQueryParam('&', 'dateTo', $scope.dateTo);
                    return url;
                };
                
                $scope.buildExcelDownloadUrl = function () {
                    var url = endpoint + 'download/asexcel/' + reportEndpointName
                        + rptUtils.getUtcOffsetQueryParam('?')
                        + rptUtils.buildListAsQueryParams($scope.employeeTags, '&', 'employeeId', function (c) { return c.Id; })
                        + rptUtils.getMomentQueryParam('&', 'dateFrom', $scope.dateFrom)
                        + rptUtils.getMomentQueryParam('&', 'dateTo', $scope.dateTo);
                    return url;
                };
            }

            return {
                restrict: 'E',
                template: require('Reports\\ReportForms\\signedDocumentsByEmployee.html'),
                link: link,
            };
        }]);

})(window.angular);
