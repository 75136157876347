(function() {
    "use strict";

    angular.module("app")
        .controller("employeeUpdateCtrl", ["$scope", "$modalInstance", "constants", "_", "actionMode", "passedData", "employeesService", "$timeout",
            function ($scope, $modalInstance, constants, _, actionMode, passedData, employeesService, $timeout) {
                $scope.isLoading = true;
                $scope.loadingMessage = "Just getting your data...";
                $scope.screenStates = { editting: 0, subscriptions: 1 };
                $scope.cancelBtnText = "Cancel";
                $scope.canEdit = false;
                $scope.canEditIdentityField = false;
                $scope.identityInfoTitle = '';
                $scope.showConfirmDeletion = false;
                $scope.confirmDeleteBtnText = "Yes I am sure";
                $timeout(function() { $('[data-toggle="tooltip"]').tooltip(); });

                $scope.subscriptionsWarn = true;
                $scope.subscriptionsWarning = "The following clients are only subscribed to by this employee. You may select another employee to subscribe, if you don't, no notifications will be recieved when events occur for the clients listed below.";
                $scope.updatedSubscriptionsList = [];
                $scope.removeEmployeeWarning = "This employee has notification subcriptions that will be orphaned, you will be prompted to reassign after confirming deletion.";
                $scope.showRemoveEmployeeWarning = false;
                $scope.screenState = $scope.screenStates.editting;

                $scope.showDefaultRecipientWarning = false;
                $scope.defaultRecipientWarning = "This employee is set up as the default email recipient, you should remove them as the default recipient before deleting/inactivating them to avoid the reassigning process.";

                $scope.mobile = "";
                $scope.mobileError1 = false;
                $scope.mobileError1Msg = "Invalid mobile number (format: +61423456789)";
                $scope.mobileRegex = constants.REGEX.MOBILE;

                $scope.warn = false;
                $scope.warning = "Given Name, Surname and Email are required!";
                $scope.error = false; // error control for 2nd form;
                $scope.passwordErrorMsg = "Passwords do not match.";

                $scope.email = "";
                $scope.emailError = false;
                $scope.emailErrorMsg = "Invalid email address";
                $scope.emailRegex = constants.REGEX.EMAIL;

                $scope.originalEmail = "";
                $scope.showEmailWarning = false;
                $scope.emailWarning = "Editing the email address field will update the selected employee's login details to the new email address";

                $scope.$watch("screenState", function(newValue, oldValue) {
                    if (newValue === $scope.screenStates.subscriptions) {
                        $scope.title = "Reassign Subscriptions";
                    }
                });

                $scope.$watch("email", function(newValue, oldValue) {
                    switch (actionMode) {
                    case constants.EMPLOYEES.MODALMODES.EDIT:
                        if (newValue !== $scope.originalEmail) {
                            $scope.showEmailWarning = true;
                        } else {
                            $scope.showEmailWarning = false;
                        }
                        break;
                    }
                });

                var resetErrors = function() {
                    $scope.warn = false;
                    $scope.mobileError1 = false;
                    $scope.error = false;
                    $scope.passwordError = false;
                    $scope.emailError = false;
                };

                var initiliseModal = function() {
                    switch (actionMode) {
                    case constants.EMPLOYEES.MODALMODES.CREATE:
                            $scope.title = "Add Employee";
                            $scope.confirmBtnText = "Create";
                            $scope.identityInfoTitle = '';
                            $scope.canEdit = true;
                            $scope.canEditIdentityField = true;
                    break;
                    case constants.EMPLOYEES.MODALMODES.EDIT:
                            $scope.title = "Edit Employee";
                            $scope.confirmBtnText = "Update";
                            $scope.identityInfoTitle = "Only the actual user can update these details in Business Fitness Identity."
                            $scope.showToolTip = true;
                            $scope.canEdit = true;
                            $scope.canEditIdentityField = false;
                        break;
                    case constants.EMPLOYEES.MODALMODES.DELETE:
                            $scope.title = "Remove Employee";
                            $scope.confirmBtnText = "Remove";
                            $scope.identityInfoTitle = '';
                            $scope.canEdit = false;
                            $scope.canEditIdentityField = false;
                        break;
                    case constants.EMPLOYEES.MODALMODES.ACTIVATE:
                            $scope.title = "Inactivate Employee";
                            $scope.identityInfoTitle = '';
                            $scope.confirmBtnText = "Update";
                            $scope.screenState = $scope.screenStates.subscriptions;
                            $scope.canEdit = false;
                            $scope.canEditIdentityField = false;
                        break;
                    default:
                        $scope.title = "Sorry";
                        $scope.message = "Something didn't work.";
                        break;
                    }

                    if (passedData) {
                        $scope.id = passedData.Id;
                        $scope.username = passedData.Username;
                        $scope.givenName = passedData.GivenName;
                        $scope.surname = passedData.Surname;
                        $scope.email = passedData.Email;
                        $scope.originalEmail = $scope.email;
                        $scope.mobile = passedData.Mobile;
                        $scope.isActive = passedData.IsActive;
                        $scope.subscriptions = passedData.SubscribedClients;
                        $scope.signatory = passedData.Signatory;

                        if (passedData.shouldWePromptSubscriptionsModal) {
                            $scope.promptSubscriptionsModal = passedData.shouldWePromptSubscriptionsModal;
                            switch (actionMode) {
                            case constants.EMPLOYEES.MODALMODES.DELETE:
                                if ($scope.promptSubscriptionsModal) {
                                    $scope.showRemoveEmployeeWarning = true;
                                }
                                break;
                            }
                        }
                        if (passedData.DefaultNotificationRecipient) {
                            $scope.showDefaultRecipientWarning = true;
                        }
                    }

                    if ($scope.promptSubscriptionsModal) {
                        employeesService.getAll().then(function(employeesResult) {
                            if (employeesResult) {
                                $scope.employees =
                                    [{ Id: 0, GivenName: "None", Surname: "", Email: "no emails will be recieved" }]
                                    .concat(_.without(employeesResult,
                                        _.findWhere(employeesResult, { Id: $scope.id })));
                                $scope.selectedEmployee = $scope.employees[0];

                                $scope.subscriptions.forEach(function(sub, $index) {
                                    $scope.updatedSubscriptionsList.push({
                                        selected: true,
                                        employee: $scope.employees[0],
                                        client: $scope.subscriptions[$index]
                                    });
                                });
                            }
                            $scope.isLoading = false;
                        });
                    } else {
                        $timeout(function() {
                                $scope.isLoading = false;
                            },
                            100); // Give the busy directive time to catch up
                    }
                };

                initiliseModal();

                $scope.applySubscriptionsToSelected = function() {
                    $scope.updatedSubscriptionsList.forEach(function(item) {
                        if (item.selected) {
                            item.employee = $scope.selectedEmployee;
                        }
                    });
                    $scope.confirm();
                };

                var isEmailAddressValid = function(emailAddress) {
                    return constants.REGEX.EMAIL.test(emailAddress);
                };

                var displayEmailError = function(message) {
                    $scope.emailError = true;
                    $scope.emailErrorMsg = message;
                };

                var displayEmailInvalidErrorMessage = function() {
                    displayEmailError("Invalid email address");
                };

                var displayMobileNumberError = function(message) {
                    $scope.mobileError1 = true;
                    $scope.mobileError1Msg = message;
                };

                var isMobileNumberAValidFormat = function(mobileNumber) {
                    return constants.REGEX.MOBILE.test(mobileNumber);
                };

                var displayMobileInvalidFormatErrorMessage = function() {
                    displayMobileNumberError("Invalid mobile number (format: +61423456789)");
                };

                $scope.confirm = function() {
                    resetErrors();
                    var returnVal = {
                        success: false
                    };

                    if ($scope.screenState !== $scope.screenStates.subscriptions) {
                        if ($scope.mobile !== "" && $scope.mobile !== null) {
                            if (!isMobileNumberAValidFormat($scope.mobile)) {
                                displayMobileInvalidFormatErrorMessage();
                                return;
                            }
                        }

                        if (!isEmailAddressValid($scope.email)) {
                            displayEmailInvalidErrorMessage();
                            return;
                        }

                        $scope.warn = $scope.email === "" || $scope.givenName === "" || $scope.surname === "";

                        if ($scope.warn) {
                            return;
                        }
                    }

                    switch (actionMode) {
                        case constants.EMPLOYEES.MODALMODES.CREATE:
                            returnVal.success = true;
                            returnVal.newUser = getNewUserFromForm();
                            returnVal.newUser.IsActive = true;
                            break;
                        case constants.EMPLOYEES.MODALMODES.EDIT:
                            returnVal.success = true;
                            returnVal.userId = $scope.id;
                            returnVal.usersNewDetails = getNewUserFromForm();
                            break;
                        case constants.EMPLOYEES.MODALMODES.ACTIVATE:
                            returnVal.success = true;
                            returnVal.userId = $scope.id;
                            returnVal.updatedSubscriptionsList = $scope.updatedSubscriptionsList;
                            break;
                        case constants.EMPLOYEES.MODALMODES.DELETE:
                            if ($scope.screenState === $scope.screenStates.editting) {
                                $scope.showConfirmDeletion = true;
                                return;
                            } else {
                                returnVal = {};
                                returnVal.success = true;
                                returnVal.userId = $scope.id;
                                returnVal.updatedSubscriptionsList = $scope.updatedSubscriptionsList;
                            }

                            break;
                        default:
                            break;
                    }

                    $modalInstance.close(returnVal);
                };

                $scope.confirmDeletion = function() {
                    if ($scope.promptSubscriptionsModal) {
                        $scope.screenState = $scope.screenStates.subscriptions;
                    } else {
                        var returnVal = {};
                        returnVal.success = true;
                        returnVal.userId = $scope.id;
                        returnVal.updatedSubscriptionsList = $scope.updatedSubscriptionsList;
                        $modalInstance.close(returnVal);
                    }
                };

                $scope.cancel = function() {
                    $modalInstance.dismiss("cancel");
                };

                function getNewUserFromForm() {
                    return {
                        username: $scope.email,
                        givenName: $scope.givenName,
                        surname: $scope.surname,
                        email: $scope.email,
                        mobile: $scope.mobile,
                        isActive: $scope.isActive,
                        signatory: $scope.signatory
                    };
                }
        }
    ]);
})();