(function() {
    'use strict';

    angular.module('app')
        .controller('AnnouncementDetailsCtrl', [
            '$scope', 'constants', 'events', '$state', '$q', 'actionMode', '$sce', '$modalInstance', 'dateParser', 'passedData', 'announcementsUploadService', 'store', 'jwtHelper', 'authHelper',
            function ($scope, constants, events, $state, $q, actionMode, $sce, $modalInstance, dateParser, passedData, announcementsUploadService, store, jwtHelper, authHelper) {

                $scope.title = actionMode + " announcement";
                $scope.canEdit = true;
                $scope.announcement = { expiryDate: new Date(), isExpirySet: false, filesReadyToCommit: [], existingDocuments: [] };
                $scope.screenState = 0;
                $scope.showNewFilesRow = false;

                $scope.showPreviewBtn = true;
                $scope.previewBtnText = "Preview";
                $scope.confirmBtnText = "Confirm";
                $scope.confirmPublishBtnText = "Confirm & Publish";
                $scope.cancelBtnText = "Cancel";

                $scope.showPublishWarning = (actionMode === constants.ANNOUNCEMENTS.MODALMODES.ADD);
                $scope.publishWarning = "By default announcements are not visible to clients. If you would you like to publish it now click the 'Confirm & Publish' button, if you would like it to remain hidden click the 'Confirm' button. You can publish or hide it later using the publish option in the announcements page";

                $scope.minDate = new Date('01/01/2000');
                $scope.expiryDateOpened = false;
                $scope.openExpiry = openExpiry;
                $scope.openExpiryTime = openExpiryTime;
                $scope.expiryTimeOpened = false;

                $scope.previewDate = new Date();

                var fileApiToken = store.get('tokenFiles');
                var getAuthObj = function() {
                    return {
                        'Authorization':
                            'Bearer ' + fileApiToken
                    };
                };

                if (_.isUndefined(fileApiToken) || _.isNull(fileApiToken)) {
                authHelper.getDelegatedToken(authHelper.getFileApiDelegationOptions()).then(function(token) {
                    fileApiToken = token;
                    store.set('tokenFiles', token);
                });
            } else {
                if (jwtHelper.isTokenExpired(fileApiToken)) {
                    authHelper.getDelegatedToken(authHelper.getFileApiDelegationOptions()).then(function(token) {
                        fileApiToken = token;
                        store.set('tokenFiles', token);
                    });
                }
            }

                $scope.getUploadConfig = {
                    headers: getAuthObj
                };

                $scope.onFileSelect = function(files) {
                    if (files.length > 0) {
                        $scope.showNewFilesRow = true;
                        announcementsUploadService.addFiles(files).then(function(fileIds) {
                            announcementsUploadService.startAllUploads();
                        }, function(reason) {
                            console.log(reason);
                        });
                    }
                }
                $scope.fileUploadProgress = function(flowFile) {
                    announcementsUploadService.updateProgress(flowFile.Id, Math.floor(flowFile.progress() * 100));
                }
                $scope.fileUploadError = function(flowFile) {
                    announcementsUploadService.errored(flowFile.Id);
                }
                $scope.fileUploadRetrying = function(flowFile) {
                    announcementsUploadService.retrying(flowFile.Id);
                }
                $scope.fileUploadSuccess = function (flowFile) {
                    announcementsUploadService.commitFile(flowFile.Identifier, flowFile.name)
                        .then(function() {
                            $scope.announcement.filesReadyToCommit.push(getFileMetaData(flowFile));
                        }).catch(function () {
                            announcementsUploadService.errored(flowFile.Id);
                        });

                }
                $scope.removeDocument = function(document) {
                    document.Removed = true;
                }

                var getFileMetaData = function(flowFile) {
                    return {
                        name: flowFile.file.name,
                        size: flowFile.file.size,
                        uploadIdentifier: flowFile.Identifier,
                        folderId: flowFile.folderId
                    };
                }

                function openExpiry($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    $scope.expiryDateOpened = true;
                }

                function openExpiryTime($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    $scope.expiryTimeOpened = true;
                }

                $scope.$watch('announcement.expiryDate', function(newVal) {
                    if (!_.isUndefined(newVal) && !_.isNull(newVal)) {
                        $scope.formattedDate = $scope.announcement.expiryDate.getDate() + '/' + ($scope.announcement.expiryDate.getMonth() +1) + '/' + $scope.announcement.expiryDate.getFullYear();
                    }
                });

                $scope.preview = function() {
                    if ($scope.screenState === 0) {
                        if (!areUploadsFinished()) {
                            return;
                        }
                        sortFinalUploads();
                        $scope.showFilesSection = shouldFilesSectionShow();
                        $scope.parsedBody = $sce.trustAsHtml($scope.announcement.body);
                        $scope.screenState = 1;
                    } else {
                        $scope.screenState = 0;
                    }
                    $scope.previewBtnText = ($scope.screenState === 0) ? "Preview" : "Continue Editing";
                }

                $scope.fileLinkClicked = function () {
                    $scope.fileLinkClickedMessage = "These links will be enabled in the published version of this announcement!";
                    $scope.showFileLinkClickedMessage = true;
                }

                $scope.confirm = function (publish) {
                    if (!areUploadsFinished()) {
                        return;
                    }
                    sortFinalUploads();
                    if (publish) {
                        $scope.announcement.IsVisible = true;
                    }
                    switch (actionMode) {
                    case constants.ANNOUNCEMENTS.MODALMODES.ADD:
                        $modalInstance.close($scope.announcement);
                        break;
                    case constants.ANNOUNCEMENTS.MODALMODES.EDIT:
                        $modalInstance.close($scope.announcement);
                        break;
                    default:
                        break;
                    }
                }

                $scope.cancel = function() {
                    $modalInstance.dismiss('cancel');
                }

                var areUploadsFinished = function() {
                    $scope.warning = "";
                    // Check if documents are still uploading
                    var uploads = _.where(announcementsUploadService.getFiles(), { aborted: false, error: false, errored: false });
                    if (uploads.length !== 0 && uploads.length > $scope.announcement.filesReadyToCommit.length) {
                        $scope.warning = "Waiting for uploads to reach 100%";
                        return false;
                    } else {
                        return true;
                    }
                }

                var sortFinalUploads = function() {
                    // Check if documents have been removed after succesfully uploading
                    var uploads = _.where(announcementsUploadService.getFiles(), { aborted: false, error: false, errored: false });
                    if (uploads.length < $scope.announcement.filesReadyToCommit.length) {
                        $scope.announcement.filesReadyToCommit = _.map(uploads, function(x) {
                            return getFileMetaData(x);
                        });
                    }
                }

                var shouldFilesSectionShow = function() {
                    if ($scope.announcement.filesReadyToCommit.length === 0 && ($scope.announcement.existingDocuments.length === 0 || _.where($scope.announcement.existingDocuments, { Removed: false }).length === 0)) {
                        return false;
                    }
                    return true;
                }

                if (passedData) {
                    $scope.announcement.id = passedData.Id;
                    $scope.announcement.title = passedData.Title;
                    $scope.announcement.body = passedData.Body;
                    $scope.announcement.isVisible = passedData.IsVisible;
                    $scope.announcement.isExpirySet = passedData.IsExpirySet;
                    $scope.announcement.expiryDate = new Date(passedData.ExpiryDate);
                    $scope.announcement.existingDocuments = angular.copy(passedData.Documents);
                }

                $scope.$on('$destroy', function() {
                    announcementsUploadService.removeAll();
                });
            }
        ]);
})();