angular.module('app')
.controller('ProfileCtrl', [
    '$scope', '$location', '$window', 'passwordService', '_', 'ENVIRONMENTAL', 'store',
    function ($scope, $location, $window, passwordService, _, ENVIRONMENTAL, store) {
        $scope.$on('$viewContentLoaded', function(event) {
            $scope.isLoading = false;
            $scope.progress.complete();
            $scope.satisfied = false;
            $scope.showPassword = true;
            $scope.showConfirmPassword = true;
            $scope.mfa_enabled = store.get('profile').mfa_enabled === "true";

            $scope.manageProfile = function () {
                var identityManageUrl = 'https://' + ENVIRONMENTAL.AUTH0.DOMAIN + '/Manage/Index';
                window.open(identityManageUrl, '_blank');
            };

            $scope.setup2FA = function () {
                var identityManageUrl = 'https://' + ENVIRONMENTAL.AUTH0.DOMAIN + '/Manage/EnableAuthenticator?logoutOnComplete=true';
                window.location.href = identityManageUrl;
            };

            $scope.submit = function updatePassword () {
                resetErrors();
                if ($scope.newPassword != $scope.confirmPassword) {
                    $scope.confirmInvalid = true;
                    return;
                }
                if ($scope.oldPassword == $scope.newPassword) {
                    $scope.newInvalid = true;
                    return;
                }
                if ($scope.newPassword == "" || _.isUndefined($scope.newPassword)) {
                    $scope.invalid = true;
                    return;
                }

                if (!$scope.satisfied) {
                    $scope.$parent.showNotification("Invalid", "please provide a password that satisfies the requirements", "error");
                    return;
                }

                passwordService.changePassword($scope.oldPassword, $scope.newPassword).then(function(result) {
                        $scope.$parent.showNotification("Success!", "Password Updated", "success");
                        $scope.resetForm();
                })
                .catch(function(err) {
                    if(err === 400) {
                        $scope.$parent.showNotification("Error!", "The Old Password you provided is incorrect", "error");
                    } else {
                        $scope.$parent.showNotification("Error!", "An error occurred while processing your request", "error");
                    }
                });
            }
        });

        var resetErrors = function () {
            $scope.confirmInvalid = false;
            $scope.newInvalid = false;
            $scope.invalid = false;
        }

        $scope.resetForm = function () {
            $scope.oldPassword = "";
            $scope.newPassword = "";
            $scope.confirmPassword = "";
            resetErrors();
        }
    }
]);