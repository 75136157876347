(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('ConfirmClickToSignRequestCtrl', [
        '$scope', '$state', '$stateParams', '_', '$q', 'selectedDoc', 'signatureService', '$modal', '$modalInstance', 'userList', 'events', 'constants', 'employee', function ($scope, $state, $stateParams, _, $q, selectedDoc, signatureService, $modal, $modalInstance, userList, events, constants, employee) {

            $scope.title = "Confirm";
            $scope.message = "Please confirm the document and details for signing:";
            $scope.confirmBtnText = "Confirm";
            $scope.cancelBtnText = "Cancel";

            $scope.document = selectedDoc;
            $scope.users = userList;
            $scope.employee = employee;

            $scope.confirm = function () {
                var userIdList = [];
                $scope.users.forEach(function(u) {
                    userIdList.push(u.Id);
                });
                
                var dataObj = { UserIds: userIdList };

                if (!_.isNull($scope.employee) && !_.isUndefined($scope.employee)) {
                    dataObj.EmployeeId = $scope.employee.Id;
                }

                signatureService.requestClickToSign($scope.document.Id, dataObj).then(function (result) {
                    console.log(result);
                    if (result == true) {
                        events.trigger(constants.DOCUMENTS.EVENTS.REFRESHREQUIRED);
                        $modalInstance.close('finished');
                    } else {
                        $scope.warn = true;
                        $scope.warning = "The request could not be processed, please try again.";
                    }
                });
            }

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            }

        }
    ]);
})();
