(function() {
    'use strict';

    angular.module('app')
        .controller('accountDetailsCtrl', [
            '$scope', '$modalInstance', 'constants', '_', 'actionMode', 'passedData', function($scope, $modalInstance, constants, _, actionMode, passedData) {

                $scope.confirmBtnText = 'Confirm';
                $scope.confirmBtnClass = "btn-primary";
                $scope.cancelBtnText = 'Cancel';
                $scope.title = "";
                $scope.message = "";
                $scope.id = 0;
                $scope.username = '';
                $scope.givenName = '';
                $scope.surname = '';
                $scope.email = '';
                $scope.mobile = '';
                $scope.isActive = false;
                $scope.canEdit = false;
                $scope.canEditIdentityField = false;
                $scope.mobileError1 = false;
                $scope.mobileError1Msg = "Invalid mobile number (format: +61423456789)";
                $scope.emailError = false;
                $scope.emailErrorMsg = "Invalid email address";
                $scope.emailRegex = constants.REGEX.EMAIL;
                $scope.warn = false;
                $scope.warning = "Given Name, Surname and Email are required!";
                $scope.mobileRegex = constants.REGEX.MOBILE;

                switch (actionMode) {
                case constants.ACCOUNTS.MODALMODES.CREATE:
                    $scope.title = 'Create New User Account';
                    $scope.message = 'Please enter the details of the new user account using the form below';
                    $scope.confirmBtnText = 'Create';
                    $scope.confirmBtnClass = 'btn-primary';
                    $scope.canEdit = true;
                    $scope.canEditIdentityField = true;
                    break;
                case constants.ACCOUNTS.MODALMODES.EDIT:
                    $scope.title = 'Update User Account Details';
                    $scope.message = 'Only the actual user can update these details in Business Fitness Identity.\nPlease ensure they have no outstanding signing tasks.';
                    $scope.confirmBtnText = 'Update';
                    $scope.confirmBtnClass = 'btn-primary';
                    $scope.cancelBtnText = 'Close';
                    $scope.canEdit = false;
                    $scope.canEditIdentityField = false;
                    break;
                case constants.ACCOUNTS.MODALMODES.DELETE:
                    $scope.title = 'Delete User Account';
                    $scope.message = 'Are you sure you wish to delete the following user account?';
                    $scope.confirmBtnText = 'Delete';
                    $scope.confirmBtnClass = 'btn-danger';
                    $scope.canEdit = true;
                    $scope.canEditIdentityField = false;
                    break;
                default:
                    viewModel.title = 'Oops';
                    viewModel.message = 'Something didn\'t work out';
                    break;
                }

                if (passedData) {
                    $scope.id = passedData.Id;
                    $scope.username = passedData.Username;
                    $scope.givenName = passedData.GivenName;
                    $scope.surname = passedData.Surname;
                    $scope.email = passedData.Email;
         /*           $scope.mobile = passedData.Mobile;*/
                    $scope.isActive = passedData.IsActive;
                }

                var isEmailAddressValid = function(emailAddress) {
                    return constants.REGEX.EMAIL.test(emailAddress);
                }

                var displayEmailError = function(message) {
                    $scope.emailError = true;
                    $scope.emailErrorMsg = message;
                }

                var displayEmailInvalidErrorMessage = function() {
                    displayEmailError("Invalid email address");
                }

                var resetAllErrors = function() {
                    $scope.mobileError1 = false;
                    $scope.emailError = false;
                    $scope.warn = false;
                }

                var isMobileNumberJustNumbers = function(mobileNumber) {
                    return !isNaN(mobileNumber);
                }

                var displayMobileNumberError = function(message) {
                    $scope.mobileError1 = true;
                    $scope.mobileError1Msg = message;
                }

                var displayOnlyNumbersInMobileNumberErrorMessage = function() {
                    displayMobileNumberError("Mobile number must not contain letters or special characters");
                }
                
                var isMobileNumberAValidFormat = function(mobileNumber) {
                    return constants.REGEX.MOBILE.test(mobileNumber);
                }

                var displayMobileInvalidFormatErrorMessage = function() {
                    displayMobileNumberError("Invalid mobile number (format: +61423456789)");
                }

                $scope.confirm = function () {
                    resetAllErrors();

                    var returnVal = {
                        success: false
                    }

                    if ($scope.mobile !== '' && $scope.mobile !== null) {
                        if (!isMobileNumberJustNumbers($scope.mobile)) {
                            displayOnlyNumbersInMobileNumberErrorMessage();
                            return;
                        }
                        if (!isMobileNumberAValidFormat($scope.mobile)) {
                            displayMobileInvalidFormatErrorMessage();
                            return;
                        }
                    }

                    if (!isEmailAddressValid($scope.email)) {
                        displayEmailInvalidErrorMessage();
                        return;
                    }

                    $scope.warn = $scope.email === "" || $scope.givenName === "" || $scope.surname === "";

                    if ($scope.warn) return;

                    switch (actionMode) {
                    case constants.ACCOUNTS.MODALMODES.CREATE:
                        returnVal.success = true;
                        returnVal.newUser = getNewUserFromForm();
                        break;
                    case constants.ACCOUNTS.MODALMODES.EDIT:
                        returnVal.success = true;
                        returnVal.userId = $scope.id;
                        returnVal.usersNewDetails = getNewUserFromForm();
                        break;
                    case constants.ACCOUNTS.MODALMODES.DELETE:
                        returnVal.success = true;
                        returnVal.userId = $scope.id;
                        break;
                    default:
                        break;
                    }

                    $modalInstance.close(returnVal);
                }

                $scope.cancel = function() {
                    $modalInstance.dismiss('cancel');
                }

                function getNewUserFromForm() {
                    return {
                        username: $scope.email,
                        givenName: $scope.givenName,
                        surname: $scope.surname,
                        email: $scope.email,
             /*           mobile: $scope.mobile,*/
                        isActive: $scope.isActive
                    }
                }

            }
        ]);
})();