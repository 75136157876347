(function (angular) {
    "use strict";

    angular
        .module('app')
        .controller('editFirmCtrl', [
            '$modalInstance', 'selectedFirm', 'firmsService', 'brandingService',
            function ($modalInstance, selectedFirm, firmsService, brandingService) {

                var vm = this;
                vm.firmName = selectedFirm.Name;
                vm.newName = selectedFirm.Name;
                vm.error = false;
                vm.errorMessage = "";

                vm.close = function () {
                    $modalInstance.dismiss("cancel");
                }

                vm.save = function () {
                    vm.error = false;

                    var firmUpdateModel = {
                        name: vm.newName,
                        enforceAccountMFA: selectedFirm.EnforceAccountMFA
                    };

                    firmsService.update(selectedFirm.Id, firmUpdateModel)
                        .then(function (result) {
                            if (result.Message) {
                                vm.errorMessage = result.Message;
                                vm.error = true;
                            } 
                        })
                        .catch(function (error) {
                            vm.errorMessage = "There has been an issue saving your changes, please contact support.";
                            vm.error = true;
                        });

                    brandingService.forceRefresh().then(function(result) {
                            $modalInstance.close(true);
                         }).catch(function (error) {
                        vm.errorMessage = "There has been an issue saving your changes, please contact support.";
                        vm.error = true;
                    });;
                }
            }
        ]);

})(window.angular);