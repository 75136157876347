'use strict';

angular.module('app.directives', [])
    .directive('appVersion',
        [
            'version', function(version) {
                return function(scope, elm, attrs) {
                    elm.text(version.value);
                };
            }
        ])
    .directive('appName',
        [
            'appName', function(appName) {
                return function(scope, elm, attrs) {
                    elm.text(appName);
                };
            }
        ]).directive('signFrame',
        function() {
            return {
                link: function(scope, ele, attrs) {
                    ele.ready(function() {
                        // Makes large modal even bigger for document setup.
                        var test = ele[0].parentElement.parentElement.parentElement;
                        test.style.width = "1175px";
                    });
                }
            };


        })
    .directive('focusMe',
    ['$timeout', '$parse', function($timeout, $parse) {
            return {
                link: function(scope, element, attrs) {
                    var model = $parse(attrs.focusMe);
                    scope.$watch(model,
                        function(value) {
                            if (value === true) {
                                $timeout(function() {
                                    if (element[0][0] != undefined) {
                                        element[0][0].focus();
                                    } else {
                                        // Some elements are different to others
                                        element[0].children[0].children[1].children[0].focus();
                                    }
                                });
                            }
                        });
                    element.bind('blur',
                        function() {
                            scope.$apply(model.assign(scope, false));
                        });
                }
            };
        }])
    .directive('responsiveLoading',
        ['_', function(_) {
            return {
                template: [
                    "<div ng-show=\"!isDataLoaded\" style=\"margin-left:auto;margin-right:auto;text-align:center;vertical-align: middle;\">" +
                    "<h2>Just getting your data...</h2>" +
                    "<br>" +
                    "<i class=\"fa fa-cog fa-spin fa-5x\"></i>" +
                    "</div>"
                ],
                link: function(scope, element, attrs) {
                    scope.isDataLoaded = false;
                    scope.isLoading = true; // force loading

                    if (!scope.isLoading) {
                        scope.isDataLoaded = true;
                    } else {
                        scope.isDataLoaded = false;
                    }

                    scope.$watch('isLoading',
                        function(newValue, oldValue) {
                            if (!newValue) {
                                scope.isDataLoaded = true;
                            } else {
                                scope.isDataLoaded = false;
                            }
                        });
                }
            }
        }])
    .directive('responsiveLoadingModal',
        ['_', function(_) {
            return {
                template: [
                    "<div ng-show=\"isLoading\" style=\"margin-left:auto;margin-right:auto;text-align:left;vertical-align: middle;\">" +
                    "<div class=\"modal-header\">" +
                    "<h3 class=\"modal-title\">{{loadingTitle}}</h3>" +
                    "</div>" +
                    "<div class=\"modal-body\" style=\"text-align:center\">" +
                    "<h2>{{loadingMessage}}</h2>" +
                    " <i class=\"fa fa-cog fa-spin fa-5x\"></i>" +
                    "</div>" +
                    "<div class=\"modal-footer\">" +
                    "</div>" +
                    "</div>"
                ],
                link: function(scope, element, attrs) {
                    scope.isDataLoaded = false;
                    scope.isLoading = true; // force loading
                    scope.loadingMessage = attrs.loadingMessage; //bind loading message
                    scope.loadingTitle = attrs.loadingTitle; // bind loading title

                    if (!scope.isLoading) {
                        scope.isDataLoaded = true;
                    } else {
                        scope.isDataLoaded = false;
                    }

                    scope.$watch('isLoading',
                        function(newValue, oldValue) {
                            if (!newValue) {
                                scope.isDataLoaded = true;
                            } else {
                                scope.isDataLoaded = false;
                            }
                        });
                }
            }
        }])
    .directive('togglePassword',
        [
            function() {
                return {
                    require: 'ngModel',
                    link: function(scope, elem, attrs, ctrl) {
                        var valueToWatch = attrs.togglePassword;

                        scope.$watch(valueToWatch,
                            function(newValue, oldValue) {
                                if (newValue !== oldValue) {
                                    if (newValue) {
                                        elem.attr('type', 'text');
                                    } else {
                                        elem.attr('type', 'password');
                                    }
                                }
                            });
                    }
                };
            }
        ])
    .directive('passwordRequirements',
        function() {
            return {
                require: "ngModel",
                restrict: 'A',
                scope: false,
                link: function(scope, element, attrs) {
                    scope.$watch(attrs.ngModel,
                        function(newPassword, oldVal) {
                            var regex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W\_]).{8,34})/;
                            scope.satisfied = regex.test(newPassword);
                        });
                }
            }
        })
    .directive('disableDoubleClick',
        ['events', '$timeout', function(events, $timeout) {
            return {
                restrict: 'A',
                link: function(scope, element, attrs, controller) {
                    var currentTimeout = null;
                    scope.$watch(function() {
                            return element.data('isLoading');
                        },
                        function(isLoading) {
                            if (isLoading) {
                                disableElement();
                            } else {
                                enableElement();
                            }
                        });
                    element.bind('click',
                        function(e) {
                            scope.$apply(function() {
                                setTimer();
                                if (element.data('isLoading')) {
                                    e.stopImmediatePropagation();
                                    e.preventDefault();
                                    return false;
                                }
                                element.data('isLoading', true);
                                return true;
                            });
                        });
                    scope.$watch('errors',
                        function(value) {
                            // Re-eneable if the form had errors
                            if (value) {
                                element.data('isLoading', false);
                            }
                        });

                    function setTimer() {
                        if (currentTimeout) {
                            $timeout.cancel(currentTimeout);
                        }
                        currentTimeout = $timeout(function() {
                                element.data('isLoading', false);
                            },
                            500);
                    }

                    function enableElement() {
                        if (!attrs.ngDisabled || !scope.$eval(attrs.ngDisabled)) {
                            element.removeClass('disabled').removeAttr('disabled');
                        }
                        element.removeClass('btn-loading');
                    }

                    function disableElement() {
                        element.addClass('disabled').attr('disabled', 'disabled');
                        element.addClass('btn-loading');
                    }
                }
            };
        }])
    .directive('copyrightNotice', function() {
        return {
            link: function ($scope) {
                $scope.year = new Date().getFullYear();
            },
            template: "© {{year}} Business Fitness",
            restrict: "E",
            scope: {}
        };
    });
