(function(angular, moment) {
    "use strict";

    angular
        .module("bf.components.controls")
        .directive("bfDateRangePicker", [function () {
            function link($scope, element, attrs) {
                $scope.showRangeWarning = false;

                if ($scope.maxSelectedDays) {
                    $scope.dateTo = moment();
                    $scope.dateFrom = moment().subtract($scope.maxSelectedDays - 1, "days");
                }

                $scope.$watch("dateFrom",
                    function() {
                        setRangeWarning($scope);
                    });

                $scope.$watch("dateTo",
                    function () {
                        setRangeWarning($scope);
                    });
            }

            function setRangeWarning($scope) {
                var dateFrom = $scope.dateFrom ? moment($scope.dateFrom) : moment(0);
                var dateTo = $scope.dateTo ? moment($scope.dateTo) : moment();

                if ($scope.maxSelectedDays) {
                    var dateFromAdjusted = moment(dateFrom).add($scope.maxSelectedDays, "days");
                    $scope.showRangeWarning = dateFromAdjusted.isSameOrBefore(dateTo);
                }

                $scope.isValid = dateFrom.isSameOrBefore(dateTo) && !$scope.showRangeWarning;
            }

            return {
                restrict: "E",
                replace: true,
                template: require('Site\\Components\\Views\\bfDateRangePicker.html'),
                link: link,
                scope: {
                    dateFrom: "=",
                    dateTo: "=",
                    maxSelectedDays: "=?",
                    isValid: "="
                }
            };
        }]);

})(window.angular, window.moment);
