(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('VerifyPinCtrl', [
        '$scope', '$state', '$stateParams', '_', '$q', 'selectedDoc', '$modal', '$modalInstance', '$sce', 'signatureService', 'constants', 'renew', function ($scope, $state, $stateParams, _, $q, selectedDoc, $modal, $modalInstance, $sce, signatureService, constants, renew) {

            $scope.title = "Verify Request to Sign";
            $scope.message = "Please Enter the PIN code you received (via email):";
            $scope.confirmBtnText = "Confirm";
            $scope.cancelBtnText = "Cancel";
            $scope.document = selectedDoc;
            $scope.pin = "";
            $scope.warn = false;
            $scope.warning = "";
            $scope.renewLock = renew;
            $scope.renewWarning = "The signing time limit on the document has expired, please verify again to complete the signing of your document";

            function showSecureSignModal(locations) {
                return $modal.open({
                    template: require('Documents\\MultipleSignatures\\MultipleSignatureSign.html'),
                    controller: 'MultipleSignatureSignCtrl',
                    resolve: {
                        document: function () {
                            return $scope.document;
                        },
                        signatureLocation: function () {
                            return locations;
                        }
                    },
                    size: 'lg',
                    backdrop: 'static'
                }).result;
            }

            function showClickToSignModal() {
                return $modal.open({
                    template: require('Documents\\ClickToSign\\ClickToSignModal.html'),
                    controller: 'ClickToSignModalCtrl',
                    resolve: {
                        document: function() {
                            return $scope.document;
                        }
                    },
                    size: 'lg',
                    backdrop: 'static'
                }).result;
            }

            function verifyPin(dataObj) {
                switch ($scope.document.ActionTypeE) {
                case constants.DOCUMENTS.ACTIONTYPES.SECURESIGN:
                    return signatureService.verifyMultipleSignaturePin(dataObj)
                        .then(function (result) {
                            if (!result.locations) {
                                return $q.reject();
                            }
                            return {
                                status: result.status,
                                message: result.Message,
                                showModal: function() {
                                    return showSecureSignModal(result.locations);
                                }
                            };
                        });
                case constants.DOCUMENTS.ACTIONTYPES.CLICKTOSIGN:
                    return signatureService.verifyClickToSignPin(dataObj)
                        .then(function (result) {
                            return {
                                status: result === true,
                                message: result.Message,
                                showModal: showClickToSignModal
                            };
                        });
                default:
                    return $q.reject();
                }
            }

            function validateVerifyResult(result) {
                if (!result) {
                    return $q.reject();
                }
                if (result.message === "doc in use") {
                    return $q.reject({ msg: "The document you have requested is currently being signed by another user, please wait 5 minutes and try again."});
                }
                if (result.message === "alreadySigned") {
                    return $q.reject({ msg: "You are not required to sign this document. <br> Possible Reasons: <br>- Already Signed. <br>- Not one of the selected accounts to sign. <br>Please double check you are required to sign the document."});
                }
                if (!result.status) {
                    return $q.reject({ msg: "The PIN code you have entered could not be verified, please try again."});
                }
                return result;
            }

            $scope.confirm = function () {
                $scope.warn = false;
                var dataObj = { DocumentId: $scope.document.Id, Pin: $scope.pin };
                if (!$scope.pin) {
                    $scope.warn = true;
                    $scope.warning = $sce.trustAsHtml("Please type your PIN code in the input box.");
                } else {
                    verifyPin(dataObj)
                        .then(validateVerifyResult)
                        .then(function(result) {
                            // Just show the new modal and immediately close this one. In future we may want to close/cancel
                            // this modal depending on the success/failure of the one we just opened.
                            result.showModal();
                            $modalInstance.close();
                        })
                        .catch(function(err) {
                            var msg = (err && err.msg) || "An error occurred while processing your request please try again.";
                            $scope.warning = $sce.trustAsHtml(msg);
                            $scope.warn = true;
                        });
                }
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

        }
    ]);
})();
