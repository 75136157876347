(function() {
    angular.module('app')
        .controller('StartCtrl',
            ['$scope', '$location', '$window', 'momentDateTime', 'events', 'dashboardService', 'loading', '$q', '$timeout', 'dataLayerService', 'ENVIRONMENTAL', 'store',
            function ($scope, $location, $window, momentDateTime, events, dashboardService, loading, $q, $timeout, dataLayerService, ENVIRONMENTAL, store) {
                $scope.$root.title = 'Portal Admin';
                $scope.minDate = new Date('2000-01-01');
                $scope.maxDate = new Date();
                $scope.fromDateOpened = false;
                $scope.toDateOpened = false;
                $scope.openFrom = openFrom;
                $scope.openTo = openTo;
                $scope.fromDate = momentDateTime().subtract(7, 'days').toDate();
                $scope.toDate = new Date();
                $scope.nowDate = momentDateTime();

                $scope.taskAuditList = [];
                $scope.tasksPageSize = 4;
                $scope.tasksPage = 0;
                $scope.taskSelection = 1;

                $scope.statistics = [];
                $scope.statisticsSelection = 1;
              
                if (store.get('profile').mfa_enabled === undefined) {
                    if (store.get('tokenAPI')) {
                        store.remove('tokenAPI');
                    }
                    if (store.get('tokenFiles')) {
                        store.remove('tokenFiles');
                    }
                    if (store.get('token')) {
                        store.remove('token');
                    }
                    if (store.get('profile')) {
                        store.remove('profile');
                    }
                    window.location.replace("https://" + ENVIRONMENTAL.AUTH0.DOMAIN + "/Manage/EnableAuthenticator?logoutOnComplete=true");
                }

                function openFrom($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    $scope.fromDateOpened = true;
                }

                function openTo($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    $scope.toDateOpened = true;
                }

                $scope.getDaysDiff = function(activity) {
                    return $scope.nowDate.diff(momentDateTime(activity.DateTime), 'days', true);
                };

                var getClearTextStatisticsType = function(functionId) {
                    return functionId === 1 ? "week-click" : "month-click";
                };

                var sendDataLayerStatisticsEvent = function($event) {
                    var dataLayerEvent = {
                        "eventType": "admin-dashboard-statistics",
                        "event": getClearTextStatisticsType(parseInt($event.target.id)),
                        "firmId": $scope.auth.profile.firmId,
                        "userId": $scope.auth.profile.portalUserID
                    };

                    dataLayerService.pushEvent(dataLayerEvent);
                };

                $scope.loadStatistics = function($event, isPageLoad) {
                    if (!isPageLoad) {
                        sendDataLayerStatisticsEvent($event);
                    }

                    $scope.statsTracker = loading.createTracker(false, 1000);
                    var deferred = $q.defer();
                    $scope.statisticsSelection = parseInt($event.target.id);
                    dashboardService.getStatistics($event.target.id).then(function(data) {
                        $scope.statistics = data;
                        deferred.resolve(true);
                    });

                    $scope.statsTracker.track(deferred.promise);
                };

                var getClearTextEventType = function(functionId) {
                    if (functionId === undefined) {
                        return "loadMore-clicked";
                    }

                        return functionId === 1 ? "unsigned-click" : "signed-click";
                };

                var sendDataLayerSigningEvent = function($event) {
                    var dataLayerEvent;

                    if ($event !== undefined) {
                        dataLayerEvent = {
                            "eventType": "admin-dashboard-signing",
                            "event": getClearTextEventType(parseInt($scope.taskSelection)),
                            "firmId": $scope.auth.profile.firmId,
                            "userId": $scope.auth.profile.portalUserID
                        };
                    } else {
                        dataLayerEvent = {
                            "eventType": "admin-dashboard-signing",
                            "event": "loadMore-clicked",
                            "firmId": $scope.auth.profile.firmId,
                            "userId": $scope.auth.profile.portalUserID
                        };
                    }
                    dataLayerService.pushEvent(dataLayerEvent);
                };

                $scope.loadMoreTasks = function($event, isPageLoad) {
                    if (!isPageLoad) {
                        sendDataLayerSigningEvent($event);
                    }
               
                    $scope.tasksTracker = loading.createTracker(false, 1000);
                    var deferred = $q.defer();
                    if (!_.isUndefined($event)) {
                        if ($scope.taskSelection !== parseInt($event.target.id)) {
                            $scope.taskAuditList = [];
                            $scope.tasksPage = 0;
                        }

                        $scope.taskSelection = parseInt($event.target.id);
                    }

                    $scope.noMoreTasks = false;
                    $scope.tasksPage++;
                    dashboardService.getTaskDocuments($scope.taskSelection, $scope.tasksPage, $scope.tasksPageSize)
                        .then(function(data) {
                            if (data.length === 0) {
                                $scope.noMoreTasks = true;
                            } else {
                                _.each(data,
                                    function(item, index) {
                                        item.hasSignatures =
                                            item.UsersToSign.length + item.DocumentAdobeSigningUsers.length > 0;
                                        $scope.taskAuditList.push(item);
                                    });
                            }

                            deferred.resolve(true);
                        });

                    $scope.tasksTracker.track(deferred.promise);
                };

                var activate = function() {
                    var unsignedTasksView = { id: 1 };
                    var weeklyView = { id: 1 };

                    $scope.loadMoreTasks({ target: unsignedTasksView }, true);
                    $scope.loadStatistics({ target: weeklyView }, true);
                };

                activate();

                var sendDataLayerOpenReleaseNoteEvent = function() {
                    var dataLayerEvent = {
                        "eventType": "admin-dashboard-releaseNote",
                        "event": "releaseNote-clicked",
                        "firmId": $scope.auth.profile.firmId,
                        "userId": $scope.auth.profile.portalUserID
                    };

                    dataLayerService.pushEvent(dataLayerEvent);
                };

                $scope.openReleaseNotes = function() {
                    sendDataLayerOpenReleaseNoteEvent();
                    window.open(
                        "http://portalhelp.hownowhq.com/support/solutions/articles/5000660465-software-release-notes",
                        "_blank");
                };

                var sendDataLayerRecentEventsEvent = function(eventTitle) {
                    var dataLayerEvent = {
                        "eventType": "admin-dashboard-recentEvents",
                        "event": "recentEvent-"+ eventTitle +"-clicked",
                        "firmId": $scope.auth.profile.firmId,
                        "userId": $scope.auth.profile.portalUserID
                    };

                    dataLayerService.pushEvent(dataLayerEvent);
                };

                $scope.trackRecentEvent = function(eventTitle) {
                    sendDataLayerRecentEventsEvent(eventTitle);
                };
            }
        ]);
})();