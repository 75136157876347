(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('RequestMultipleSignaturesCtrl', [
        '$scope', '$state', '$stateParams', '_', '$q', 'selectedDoc', 'documentsService', 'accountsService', '$modal', '$modalInstance', 'employeesService', function ($scope, $state, $stateParams, _, $q, selectedDoc, documentsService, accountsService, $modal, $modalInstance, employeesService) {
            $scope.title = "Secure Sign Setup";
            $scope.message = "Please select the accounts that are required to sign:";
            $scope.warn = false;
            $scope.warning = "";
            $scope.error = false;
            $scope.errorMsg = "";
            $scope.confirmBtnText = "Next";
            $scope.previewBtnText = "Preview";
            $scope.cancelBtnText = "Cancel";
            $scope.document = selectedDoc;
            $scope.users = [];
            $scope.options = [{ obj: "" }];
            $scope.employees = [];
            $scope.employeeOptions = [{ obj: "" }];
            $scope.employeeRequired = false;
            $scope.isLoading = true;

            var getUsersList = function () {
                return accountsService.getSignatories(selectedDoc.Client.Id).then(function(result) {
                    $scope.users = result;
                    $scope.usersToDisplay = $scope.users;
                });
            }
            
            var getEmployeeList = function () {
                return employeesService.getSignatories(selectedDoc.Client.Id).then(function (result) {
                    $scope.employees = result;
                    $scope.employeesToDisplay = $scope.users;
                });
            }
            
            var initiliseModal = function () {
                var listPromises = [];
                listPromises.push(getUsersList());
                listPromises.push(getEmployeeList());
                $q.all(listPromises).then(function () {
                    $scope.isLoading = false;
                });
            }

            initiliseModal(); // Do the init thing...

            $scope.addNewField = function() {
                $scope.options.push({ obj: "" });
            }

            $scope.checkSelection = function (index) {
                resetDisplayedErrorMessage();
                resetDisplayedWarningMessage();
                var occurence = 0;
                $scope.options.forEach(function(item) {
                    if (item.Id === $scope.options[index].Id) {
                        occurence++;
                        if (occurence > 1) {
                            $scope.options[index] = { obj: "" }
                            displayAccountCanOnlyBeSelectedOnceWarning();
                        }
                    }
                });
            }

            var displayErrorMessage = function (message) {
                $scope.errorMsg = message;
                $scope.error = true;
            }

            var displaySelectEmployeeError = function () {
                displayErrorMessage('Please select an employee to continue');
            }

            var displayRemoveBlankEntriesError = function () {
                displayErrorMessage('Please remove any blank fields before confirming.');
            }

            var displaySelectAtLeastOneAccountError = function () {
                displayErrorMessage('You must select at least one account.');
            }

            var resetDisplayedErrorMessage = function () {
                $scope.error = false;
            }

            var displayWarningMessage = function (message) {
                $scope.warning = message;
                $scope.warn = true;
            }

            var displayAccountCanOnlyBeSelectedOnceWarning = function () {
                displayWarningMessage('An Account may only be selected once.');
            }

            var resetDisplayedWarningMessage = function () {
                $scope.warn = false;
            }

            var areThereAnyBlankFields = function () {
                var returnVal = false;
                $scope.options.forEach(function (user) {
                    if (_.isUndefined(user.Id)) {
                        returnVal = true;
                    }
                });
                return returnVal;
            }

            var isAtLeastOneAccountSelected = function () {
                return $scope.options.length !== 0;
            }

            var clearEmployeeSelection = function () {
                $scope.employeeOptions[0] = null;
            }

            var isAnEmployeeSelected = function () {
                var returnVal = false;
                if ($scope.employeeOptions.length > 0) {
                    if (!_.isUndefined($scope.employeeOptions[0].Id)) {
                        returnVal = true;
                    }
                }
                return returnVal;
            }

            $scope.confirm = function() {
                // validate - Assume true and error if not
                resetDisplayedErrorMessage();
                resetDisplayedWarningMessage();

                // Ensure that there are no blank entries
                if (areThereAnyBlankFields()) {
                    displayRemoveBlankEntriesError();
                    return;
                }
                // Ensure that at lease one account is selected
                if (!isAtLeastOneAccountSelected()) {
                    displaySelectAtLeastOneAccountError();
                    return;
                }
                // Ensure if an employee should sign one is selected
                if ($scope.employeeRequired) {
                    if (!isAnEmployeeSelected()) {
                        displaySelectEmployeeError();
                        return;
                    }
                } else {
                    clearEmployeeSelection();
                }

                var userIdList = [];

                $scope.options.forEach(function(user) {
                    userIdList.push({ Id: user.Id, GivenName: user.GivenName, Surname: user.Surname, Email: user.Email });
                });
                
                $modal.open({
                    template: require('Documents\\MultipleSignatures\\MultipleSignaturePrepareSign.html'),
                    controller: 'MultipleSignaturePrepareSignCtrl',
                    resolve: {
                        document: function() {
                            return selectedDoc;
                        },
                        userList: function() {
                            return userIdList;
                        },
                        employee: function() {
                            return $scope.employeeOptions[0];
                        }
                    },
                    size: 'lg',
                    backdrop: 'static'
                }).result
                    .then(function () {
                        $modalInstance.close('finished');
                    })
                    .catch(function () {
                        $modalInstance.dismiss('cancel');
                    });
            }

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            }

            $scope.remove = function(index) {
                $scope.options.splice(index, 1);
            }
        }
    ]);
})();
