(function() {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('SigningPreferencesCtrl', [
        '$scope', '$location', '$window', '$q', '_', 'constants', 'signatureService', function ($scope, $location, $window, $q, _, constants, signatureService) {

            $scope.signingPreferences = {};
            $scope.$on('$viewContentLoaded', function (event) {
                signatureService.getSigningPreferences().then(function (data) {
                    if (!_.isUndefined(data)) {
                        $scope.signingPreferences = data;
                    }
                    $scope.isLoading = false;
                });
                
            });

            $scope.confirm = function() {
                signatureService.updateSigningPreferences($scope.signingPreferences).then(function(data) {
                    $scope.$parent.showNotification("Success!", "Updated signing preferences!", "success");
                }, function(err) {
                    $scope.$parent.showNotification("Error!", "Failed to update signing preferences.", "error");
                });
            }

            $scope.showHelp = function() {
                $scope.showHelpMessage = !$scope.showHelpMessage;
            }
        }
    ]);
})();