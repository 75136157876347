// Generic controller to run all reports in a standard way.
// The particular report directive to be displayed is defined in the invoking URL.

const angular = window.angular;
const iFrameResize = require("iframe-resizer").iframeResizer;

angular
    .module('app')
    .controller('ReportCtrl', [
        '$scope', '$http', '$q', '$stateParams', '$compile', 'reportsService', '$timeout', function ($scope, $http, $q, $stateParams, $compile, reportsService, $timeout) {

            var iframe;

            $scope.progress.reset();
            $scope.title = '';
            $scope.reportLoading = false;

            iframe = document.getElementById('report-frame');

            if (iframe) {
                iFrameResize({ checkOrigin: false }, iframe);
                loadReportPage();
            } else {
                // This should not happen, but just in case - make it obvious!
                $scope.isLoadError = true;
                $scope.loadingComplete = true;
            }

            $scope.updateReport = function() {
                $scope.progress.reset();
                $scope.progress.start();
                $scope.reportLoading = true;

                var url = $scope.buildUrl();
                reportsService.getReport(url)
                    .then(function(data) {
                        // http://stackoverflow.com/a/40623473/4289902
                        iframe.contentWindow.contents = data;
                        iframe.src = 'javascript:window["contents"]';
                        $(iframe).show();
                    })
                    .catch(function(error) {
                        if (error.status === 403) {
                            $scope.showNotification('Reports', 'You do not have access to this report.', 'error');
                        } else {
                            $scope.showNotification('Reports', 'An error has occurred while updating the report.', 'error');
                        }
                        $(iframe).hide();
                    })
                    .finally(function() {
                        $scope.progress.complete();
                        $scope.reportLoading = false;
                    });
            };

            $scope.downloadReport = function() {
                $scope.progress.reset();
                $scope.progress.start();
                $scope.reportLoading = true;

                var url = $scope.buildExcelDownloadUrl();
                reportsService.downloadReport(url)
                    .then(function (downloadFilename) {
                        $scope.showNotification('Reports', 'Download of ' + downloadFilename + ' started.', 'success');
                    })
                    .catch(function (error) {
                        if (error.status === 403) {
                            $scope.showNotification('Reports', 'You do not have access to this report.', 'error');
                        } else {
                            $scope.showNotification('Reports', 'An error has occurred while updating the report.', 'error');
                        }
                        $(iframe).hide();
                    })
                    .finally(function () {
                        $scope.progress.complete();
                        $scope.reportLoading = false;
                    });
            };

            function loadReportPage() {
                $scope.progress.start();
                reportsService.getReports()
                    .then(function(reportGroups) {
                        var reportTag = $stateParams.reportTag;
                        var reports = reportGroups.reduce(function(r, g) { return r.concat(g.Reports); }, []);
                        var matches = reports.filter(function(i) { return i.Tag === reportTag; });

                        $scope.reportTag = reportTag;
                        $scope.title = matches.length ? matches[0].Title : '';
                        $scope.isValid = false;
                    })
                    .catch(function() {
                        $scope.isLoadError = true;
                        $scope.showNotification('Reports', 'An error has occurred while loading the report.', 'error');
                    })
                    .finally(function() {
                        $scope.progress.complete();
                        $scope.loadingComplete = true;
                    });
            };

        }])
    .directive('bfDynamicReportParametersControl', ['$compile', '$injector', '$state', function($compile, $injector, $state) {

        function link($scope, element, attrs) {
            $scope.$watch('reportTag', function(newValue) {
                if (!newValue) {
                    return;
                }
                var reportDirective = document.createElement(newValue);
                $compile(angular.element(reportDirective))($scope);

                if (!$injector.has(attrs.$normalize(newValue) + 'Directive')) {
                    // The directive name was not found - the passed reportTag is invalid
                    $state.go('error', null, { location: false }); // set 'location' so the back button works
                    return;
                }

                element.html(reportDirective);
            });
        }

        return {
            restrict: 'A',
            template: '',
            link: link,
        };
        }]);
